import { Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import { MPPhoneInput } from 'presentation/theme/ui-kit/input';
import { isValidPhoneFormat } from 'presentation/utils/phone';
import { useState } from 'react';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPConfirmDialog } from '../../../../../theme/ui-kit/dialog';
import ConfirmButtons from '../../../../../theme/ui-kit/dialog/buttons';
import { ConfirmPhoneNumberDialogInternalProps, ConfirmPhoneNumberDialogProps } from './types';

export const IsPhoneNotExistsDialog = ({
  onClose,
  onSubmit,
  onChangeEditMode,
}: ConfirmPhoneNumberDialogProps & ConfirmPhoneNumberDialogInternalProps) => {
  const [localPhone, setLocalPhone] = useState<string>('');
  const [error, setError] = useState<Nullable<string>>(null);

  const onCancel = () => {
    onChangeEditMode?.(false);
    onClose();
  };

  const onChangeInternal = (value: string) => {
    setError(null);
    setLocalPhone(value);
  };

  const onSubmitInternal = () => {
    const isValidPhone = localPhone && isValidPhoneFormat(localPhone);
    if (isValidPhone) {
      onSubmit(localPhone);
      setError(null);
    } else {
      setError('Введите корректный номер телефона');
    }
  };
  console.log('localPhone', localPhone);
  return (
    <MPConfirmDialog
      title='Телефон'
      open={true}
      disableEscapeKeyDown
      maxWidth={'xs'}
      buttons={
        <ConfirmButtons>
          <MPButton
            fullWidth
            color='brand'
            size='large'
            onClick={onSubmitInternal}
          >
            Отправить
          </MPButton>
          <MPButton
            color='brand'
            variant='text'
            fullWidth
            size='large'
            onClick={onCancel}
          >
            Отмена
          </MPButton>
        </ConfirmButtons>
      }
      onClose={onCancel}
    >
      <Typography
        color='textPrimary'
        variant='body1'
      >
        Введите ваш номер телефона, чтобы менеджер перезвонил для уточнения деталей по заказу
      </Typography>
      <Splitter size={2}></Splitter>
      <MPPhoneInput
        value={localPhone ?? ''}
        helperText={error}
        error={!!error}
        onChange={onChangeInternal}
      />
    </MPConfirmDialog>
  );
};
