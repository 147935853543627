import styled from '@emotion/styled/macro';
import { css, tabClasses, tabsClasses } from '@mui/material';
import { MPTabs } from 'presentation/theme/ui-kit/tab';

export const GenderTabs = styled(MPTabs)(
  ({ theme }) => css`
    &.${tabsClasses.root}, .${tabsClasses.flexContainer} {
      height: 56px;
    }

    .${tabClasses.root} {
      min-width: 62px;

      ${theme.breakpoints.down('sm')} {
        min-width: auto;
      }
    }
  `
);
