import { useGetUserBonusesBalanceQuery } from 'data/api/user';
import { UserAccessMatrix } from 'domain/model/security';
import { User } from 'domain/model/user';
import { getBonusConfig } from 'presentation/utils';
import { useCallback, useEffect, useState } from 'react';
import useErrorTracking from '../../../../hooks/useErrorTracking';
import CurrentUserBalanceContext, { CurrentUserBalanceContextType } from './context';

type CurrentUserBalanceProviderProps = {
  readonly user: User;
  readonly accessMatrix: UserAccessMatrix;
  readonly children: JSX.Element;
};

type CurrentUserBalanceProviderType = (props: CurrentUserBalanceProviderProps) => JSX.Element;

const CurrentUserBalanceProvider: CurrentUserBalanceProviderType = props => {
  const { user, accessMatrix, children } = props;

  const [contextValue, setContextValue] = useState<CurrentUserBalanceContextType>({
    value: null,
    isFetching: false,
    refreshBalance: () => null,
  });

  const { captureException } = useErrorTracking();

  const skipRequest = !accessMatrix?.isCorpUser || !user.pernr || !!getBonusConfig()?.unavailableReason;

  const {
    data: bonusesBalance = null,
    isFetching: bonusesBalanceIsFetching,
    error: bonusesFetchError,
    refetch: refreshBonusesBalanceInternal,
    isUninitialized: bonusesBalanceisUninitialized,
  } = useGetUserBonusesBalanceQuery(
    { userId: user.id },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 0.5 * 60 * 1000,
      skip: skipRequest,
    }
  );

  const refreshBonusesBalance = useCallback(() => {
    if (!bonusesBalanceisUninitialized) {
      refreshBonusesBalanceInternal();
    }
  }, [bonusesBalanceisUninitialized, refreshBonusesBalanceInternal]);

  // ошибка получения баланса юзера
  useEffect(() => {
    if (bonusesFetchError) {
      const errorText = 'Error at request current user bonuses';
      console.error(errorText, bonusesFetchError);
      captureException(errorText);
    }
  }, [bonusesFetchError, captureException]);

  // обновление стейта баланса для оптимальности
  useEffect(() => {
    setContextValue({
      value: bonusesBalance,
      isFetching: bonusesBalanceIsFetching,
      refreshBalance: refreshBonusesBalance,
    });
  }, [bonusesBalance, bonusesBalanceIsFetching, refreshBonusesBalance]);

  return <CurrentUserBalanceContext.Provider value={contextValue}>{children}</CurrentUserBalanceContext.Provider>;
};

export default CurrentUserBalanceProvider;
