import { useMediaQuery } from '@mui/material';
import { PartnerOfferListRequest, useGetPartnerProductOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType, EProductOfferSortType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';
import ProductOfferCard from '../../../../offer/product/components/card';
import { partnerDeskDetailsProductOffersIsNewFetchingSelector } from '../../store/selectors';

type PartnerDeskProductOffersDataContainerProps = {
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<EProductOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (newPage: number) => void;
};

const PartnerDeskProductOffersDataContainer = (props: PartnerDeskProductOffersDataContainerProps) => {
  const { guid, args, header, onChangePage } = props;

  const isDownLg = useMediaQuery(theme => theme.breakpoints.down('lg'));

  const { accessMatrix } = useCurrentUser();

  const isNewFetching = useSelector(partnerDeskDetailsProductOffersIsNewFetchingSelector);
  const { page } = args;

  const { data, error } = useGetPartnerProductOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const productOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Product,
      partnerId: args.partnerId,
    },
  });

  const onLoadMore = useCallback(() => {
    onChangePage(page + 1);
  }, [page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!productOffers && productOffers.length > 0;
  const isFavoritesAllowed = accessMatrix.productOffers.favorite;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {productOffers.map(productOffer => (
            <ProductOfferCard
              key={productOffer.id}
              product={productOffer}
              favorite={
                !isFavoritesAllowed
                  ? null
                  : {
                      showAlways: isDownLg,
                      active: isFavorite(EOfferType.Product, productOffer),
                      inProgress: isAddToFavoritesFetching(productOffer.id),
                      onClick: () => addOrRemoveFavorite(EOfferType.Product, productOffer),
                    }
              }
            />
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskProductOffersDataContainer;
