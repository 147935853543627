import { ArrowLeftIcon } from 'presentation/media/icons';
import React from 'react';
import { useHistory } from 'react-router';
import AppSvgIcon from '../../icon';
import { ButtonLinkStyled } from './controls';

type BackButtonProps = {
  readonly href?: Nullable<string>;
  readonly icon?: React.ReactNode;
  readonly onClick?: () => void;
  readonly children?: React.ReactNode;
};

const BackButton = ({ href, icon, onClick, children }: BackButtonProps) => {
  const history = useHistory();

  return (
    <ButtonLinkStyled
      fullWidth={false}
      color='secondary'
      startIcon={icon ?? <AppSvgIcon icon={ArrowLeftIcon} />}
      href={href ?? undefined}
      onClick={() => (!href ? (onClick ? onClick() : history.goBack()) : null)}
    >
      {children || 'Назад'}
    </ButtonLinkStyled>
  );
};

export default BackButton;
