import { Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { EOrderStatus } from 'domain/model/enums';
import { EDateFormat } from 'domain/model/formats';
import LightButton from 'presentation/components/common/buttons/light';
import Splitter from 'presentation/components/common/splitter';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { phoneFormatView } from 'presentation/utils/phone';
import { ProductOrder } from '../../../../domain/model/order';
// import OrderStatus from '../components/status';
import { EAppCategories } from '../../../types';
import { getPersonShortName } from '../../../utils';
import { toRubCurrency } from '../../../utils/currency';
import { utcToLocalTimeZone } from '../../../utils/date';
import { EPartnerDeskDetailsTab } from '../../partnerDesk/details/utils';
import { getPartnerDeskDetailsRoute } from '../../partnerDesk/entry';
import OrderCommonOfferCost from '../components/costCommon';
// import OrderItemStatus from '../components/itemStatus';
import OrderProductInfo from '../components/productInfo';

type OrderProductOfferDetailsComponentProps = {
  readonly order: ProductOrder;
  readonly onCancel: () => void;
};

const OrderProductOfferDetailsComponent = (props: OrderProductOfferDetailsComponentProps) => {
  const { order, onCancel } = props;

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down('sm'));

  const smallTextSize = downSm ? 'body2' : 'caption';

  const partnerLink = getPartnerDeskDetailsRoute({ id: order.partner.id, tab: EPartnerDeskDetailsTab.Contacts });

  const statusesToCancel = [EOrderStatus.New, EOrderStatus.Paid, EOrderStatus.Sent];

  return (
    <MPGrid
      container
      spacing={4}
      direction='column'
    >
      <MPGrid
        item
        container
        spacing={2}
        direction='column'
      >
        <MPGrid item>
          <Typography
            variant={smallTextSize}
            component='div'
            gutterBottom
          >
            {EAppCategories.ProductOffers}
          </Typography>
          <Typography
            variant={downSm ? 'subtitle1' : 'body1'}
            gutterBottom
            fontWeight='fontWeightBold'
          >
            Заказ №{order.number}
          </Typography>
          {/*<OrderStatus*/}
          {/*  status={order.status}*/}
          {/*  statusName={order.statusName}*/}
          {/*/>*/}
        </MPGrid>
        <MPGrid item>
          <Typography
            variant={smallTextSize}
            component='div'
            gutterBottom
            color='textSecondary'
          >
            Оформлен:
          </Typography>
          <Typography>{utcToLocalTimeZone(order.createdAt).format(EDateFormat.DisplayDefaultShort)}</Typography>
        </MPGrid>
        <MPGrid item>
          <Typography
            variant={smallTextSize}
            component='div'
            gutterBottom
            color='textSecondary'
          >
            Получатель:
          </Typography>
          <Typography>{getPersonShortName(order.customer)}</Typography>
        </MPGrid>
        <MPGrid item>
          <Typography
            variant={smallTextSize}
            component='div'
            gutterBottom
            color='textSecondary'
          >
            Телефон:
          </Typography>
          {order.customer.phone ? (
            <Link
              href={`tel:${phoneFormatView(order.customer.phone)}`}
              color='textPrimary'
              underline='hover'
            >
              {phoneFormatView(order.customer.phone)}
            </Link>
          ) : (
            <Typography>-</Typography>
          )}
        </MPGrid>
        {/*{order.deliveryTypeName && (*/}
        {/*  <MPGrid item>*/}
        {/*    <Typography*/}
        {/*      variant={smallTextSize}*/}
        {/*      component='div'*/}
        {/*      gutterBottom*/}
        {/*      color='textSecondary'*/}
        {/*    >*/}
        {/*      Способ получения:*/}
        {/*    </Typography>*/}
        {/*    <Typography>{order.deliveryTypeName}</Typography>*/}
        {/*  </MPGrid>*/}
        {/*)}*/}
        {order.deliveryCost && (
          <MPGrid item>
            <Typography
              variant={smallTextSize}
              component='div'
              gutterBottom
              color='textSecondary'
            >
              Стоимость доставки:
            </Typography>
            <Typography>{toRubCurrency(order.deliveryCost)}</Typography>
          </MPGrid>
        )}
        {/*{order.paymentTypeName && (*/}
        {/*  <MPGrid item>*/}
        {/*    <Typography*/}
        {/*      variant={smallTextSize}*/}
        {/*      component='div'*/}
        {/*      gutterBottom*/}
        {/*      color='textSecondary'*/}
        {/*    >*/}
        {/*      Способ оплаты:*/}
        {/*    </Typography>*/}
        {/*    <Typography>{order.paymentTypeName}</Typography>*/}
        {/*  </MPGrid>*/}
        {/*)}*/}
        <MPGrid item>
          <Typography
            variant={smallTextSize}
            component='div'
            gutterBottom
            color='textSecondary'
          >
            Итого:
          </Typography>
          <OrderCommonOfferCost
            originalCost={order.originalCost}
            cost={order.cost}
          />
        </MPGrid>
      </MPGrid>
      {order.items && order.items.length > 0 && (
        <MPGrid item>
          <Typography
            variant={downSm ? 'subtitle1' : 'body1'}
            fontWeight='fontWeightBold'
          >
            Состав заказа
          </Typography>
          <Splitter size={1} />
          <MPGrid
            container
            spacing={1}
            direction='column'
          >
            {order.items.map((item, index, array) => (
              <MPGrid
                item
                key={item.offer.id}
              >
                <Typography gutterBottom>
                  {item.name ?? '-'}, {item.qty} шт
                </Typography>
                <OrderCommonOfferCost
                  originalCost={item.originalCost}
                  cost={item.cost}
                />
                {/*{item.status === EOrderItemStatus.Cancelled && (*/}
                {/*  <OrderItemStatus*/}
                {/*    status={item.status}*/}
                {/*    statusName={item.statusName}*/}
                {/*  />*/}
                {/*)}*/}
                {array.length - 1 !== index && <Splitter size={1} />}
              </MPGrid>
            ))}
          </MPGrid>
        </MPGrid>
      )}
      {statusesToCancel.includes(order.status) && (
        <MPGrid item>
          <LightButton
            size='small'
            color='primary'
            onClick={onCancel}
          >
            Отменить заказ
          </LightButton>
        </MPGrid>
      )}
      <MPGrid item>
        <OrderProductInfo partnerLink={partnerLink} />
      </MPGrid>
    </MPGrid>
  );
};

export default OrderProductOfferDetailsComponent;
