import { useChangeUserLocationMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { UserLocalSettings, UserSettings } from 'system/userLocalSettings';
import useErrorTracking from '../../../hooks/useErrorTracking';

type UserSettingsProviderProps = {
  readonly userId: UUID;
};

export type UserSettingsContextType = {
  readonly settings: UserSettings;
  readonly setCity: (fiasId: UUID, name: string) => void;
  readonly setUserCookieAccepted: (value: boolean) => void;
  readonly isCurrentUserAcceptedCookies: boolean;
};

type UserSettingsProviderType = (props: PropsWithChildren<UserSettingsProviderProps>) => JSX.Element;

export const UserSettingsContext = React.createContext<UserSettingsContextType>({} as UserSettingsContextType);

const UserSettingsProvider: UserSettingsProviderType = ({ userId, children }) => {
  const [userLocalSettings] = useState<UserLocalSettings>(UserLocalSettings.getInstance(userId));
  const [settings, setSettings] = useState<UserSettings>(userLocalSettings.getSettings());
  const { captureException } = useErrorTracking();

  const [changeUserLocation, { error: changeUserLocationError }] = useChangeUserLocationMutation();

  const isCurrentUserAcceptedCookies = settings?.cookieAccepted === true;

  const setCity = useCallback(
    (fiasId: UUID, name: string) => {
      changeUserLocation({ userId, location: { id: fiasId, name } })
        .unwrap()
        .then(() => {
          const newSettings = {
            ...settings,
            city: {
              fiasId,
              name,
            },
          };
          userLocalSettings.setSettings(newSettings);
          setSettings(newSettings);
        })
        .catch(e => {
          console.error(e);
          captureException(e);
        });
    },
    [userId, settings, userLocalSettings, setSettings, changeUserLocation, captureException]
  );

  const setUserCookieAccepted = useCallback(
    (value: boolean) => {
      const newSettings: UserSettings = {
        ...settings,
        cookieAccepted: value,
      };
      userLocalSettings.setSettings(newSettings);
      setSettings(newSettings);
    },
    [settings, userLocalSettings, setSettings]
  );

  const value: UserSettingsContextType = useMemo(
    () => ({
      settings,
      setCity,
      setUserCookieAccepted,
      isCurrentUserAcceptedCookies,
    }),
    [settings, setCity, setUserCookieAccepted, isCurrentUserAcceptedCookies]
  );

  useEffect(() => {
    if (changeUserLocationError) {
      ErrorHandler.handleHttpError(changeUserLocationError);
    }
  }, [changeUserLocationError]);
  return <UserSettingsContext.Provider value={value}>{children}</UserSettingsContext.Provider>;
};

export default UserSettingsProvider;
