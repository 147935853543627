import { Text } from '../../components/controls';

const AboutTradingRulesRegisterInfo = () => {
  return (
    <>
      <Text>
        1. Оформить Заказ на Торговой площадке могут только зарегистрированные Покупатели, при этом Покупатель вправе
        зарегистрироваться на сайте только 1(один) раз, т.е. может иметь только один Личный кабинет.
      </Text>
      <Text>
        2. При регистрации на Торговой площадке, а также при дальнейшем ее использовании, Покупатель предоставляет ОАО
        «РЖД» свои регистрационные данные (в том числе, персональные данные), включая, но не ограничиваясь: Ф.И.О.,
        номер телефона, адрес электронной почты, адрес места нахождения, пол, дату рождения,
      </Text>
      <Text>
        3. «Витрина» осуществляет сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование,
        удаление, уничтожение регистрационных данных Покупателя (в том числе, персональных данных).
      </Text>
      <Text>
        4. «Витрина» не несет ответственности за точность и правильность информации, предоставляемой Покупателем при
        регистрации.
      </Text>
      <Text>
        5. Покупатель несёт ответственность за все возможные негативные последствия, в случае передачи учетных данных
        Личного кабинета третьим лицам.
      </Text>
    </>
  );
};
export default AboutTradingRulesRegisterInfo;
