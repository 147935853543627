import { useGetCorpOfferDetailsQuery } from 'data/api/corpOffer';
import { EOfferType, OfferPromotionType } from 'domain/model/enums';
import { User } from 'domain/model/user';
import moment from 'moment-timezone';
import { getUserProfileRoute } from 'presentation/features/user/entry';
import { FCC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { AppConfigurator } from 'system/appConfigurator';
import useCurrentUserBalance from '../../../../../../hooks/useCurrentUserBalance';
import useOfferActivations from '../../../../hooks/useOfferActivations';
import useOfferSubscription from '../../../../hooks/useOfferSubscription';
import CorpOfferSidebarContext, { CorpOfferSidebarContextType } from './context';
import useTakeOfferActivations from '../../../../hooks/useTakeOfferActivations';

type CorpOfferSidebarProviderProps = {
  readonly id: UUID;
  readonly promotionType: OfferPromotionType;
  readonly user: User;
};

const CorpOfferSidebarProvider: FCC<CorpOfferSidebarProviderProps> = props => {
  const { children, id, promotionType, user } = props;

  const history = useHistory();

  const { data: corpOffer = null, refetch: corpOfferRefetch } = useGetCorpOfferDetailsQuery(
    { id },
    { refetchOnMountOrArgChange: true }
  );

  const activation = useOfferActivations({
    offerId: id,
    offerPrice: corpOffer?.price,
    offerRefetch: corpOfferRefetch,
  });

  const { isUserBalanceNotEnough, isLoadActivationsFetching, lastReceivedActivation } = activation;

  const { takeOfferActivation } = useTakeOfferActivations({ lastActivation: lastReceivedActivation });

  const {
    isEnabled: isSubscriptionEnabled,
    isFetching: isSubscriptionFetching,
    isSupported: isSubscriptionSupported,
    onSubscribe,
    onUnSubscribe,
  } = useOfferSubscription({
    user,
    offer: corpOffer,
    offerType: EOfferType.Corp,
    promotionType,
  });

  const { value: userBalance, isFetching: userBalanceIsFetching, refreshBalance } = useCurrentUserBalance();
  const earnPointsUrl = AppConfigurator.getInstance().getCorpEarningsSystemUrl() ?? '';

  const onActivate = useCallback(() => {
    if (!corpOffer) {
      return;
    }
    activation.activateOffer(corpOffer);
  }, [corpOffer, activation]);

  const onFillProfile = useCallback(() => {
    history.push(getUserProfileRoute());
  }, [history]);

  useEffect(() => {
    if (isUserBalanceNotEnough) {
      refreshBalance();
    }
  }, [isUserBalanceNotEnough, refreshBalance]);

  const isFetching = isLoadActivationsFetching || (isUserBalanceNotEnough && userBalanceIsFetching);
  const endDate = corpOffer ? moment(corpOffer.endDate).endOf('day') : null;

  const isActivationReceivedNow = !!lastReceivedActivation;

  const value: CorpOfferSidebarContextType = {
    corpOffer,
    endDate,
    isFetching,
    earnPointsUrl,
    userBalance,
    activation,
    isSubscriptionEnabled,
    isSubscriptionFetching,
    isSubscriptionSupported,
    isActivationReceivedNow,
    onActivate,
    onSubscribe,
    onUnSubscribe,
    onFillProfile,
    takeOfferActivation,
  };

  return <CorpOfferSidebarContext.Provider value={value}>{children}</CorpOfferSidebarContext.Provider>;
};

export default CorpOfferSidebarProvider;
