import styled from '@emotion/styled';
import { Typography, typographyClasses } from '@mui/material';

export const Wrapper = styled.div`
  display: flex;
  margin-left: ${p => p.theme.spacing(0.5)};
`;

export const FavoriteText = styled(Typography)`
  &.${typographyClasses.root} {
    font-weight: ${p => p.theme.typography.fontWeightLight};
  }
`;
