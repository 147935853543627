import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import rootRouting from '../../../../../routing';
import { getSiteCaption } from '../../../../utils';
import { Subsection, Text } from '../../components/controls';

const AboutRightsAndObligationsInfo = () => {
  return (
    <>
      <Subsection>
        <Text>1. Торговая площадка «Витрина» обязуется:</Text>
        <Text>
          1.1. Обрабатывать персональные данные Покупателя в порядке, установленном действующим законодательством и
          Политикой «Витрины» в отношении организации обработки и обеспечения безопасности персональных данных.
        </Text>
        <Text>
          1.2. В случае достижения цели обработки персональных данных Покупателя, «Витрины» обязуется прекратить
          обработку персональных данных или обеспечить ее прекращение (если обработка персональных данных осуществляется
          другим лицом, действующим по поручению «Витрины») и уничтожить персональные данные или обеспечить их
          уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению
          «Витрины») в срок, не превышающий тридцати дней с даты достижения цели обработки персональных данных, если
          иное не предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является
          Покупатель, иным соглашением между «Витриной» и Покупателем.
        </Text>
        <Text>
          1.3. В случае отзыва Покупателем согласия на обработку его персональных данных «Витрины» обязуется прекратить
          их обработку или обеспечить прекращение такой обработки (если обработка персональных данных осуществляется
          другим лицом, действующим по поручению «Витрины») и в случае, если сохранение персональных данных более не
          требуется для целей обработки персональных данных, уничтожить персональные данные или обеспечить их
          уничтожение (если обработка персональных данных осуществляется другим лицом, действующим по поручению
          «Витрины») в срок, не превышающий тридцати дней с даты поступления указанного отзыва, если иное не
          предусмотрено договором, стороной которого, выгодоприобретателем или поручителем по которому является
          Покупатель.
        </Text>
      </Subsection>
      <Subsection>
        <Text> 2. Торговая площадка «Витрины» имеет право:</Text>
        <Text>
          2.1. Изменять настоящие Правила в одностороннем порядке, помещая их на Торговой площадке, расположенного по
          интернет-адресу:{' '}
          <Link
            color='primary'
            underline='none'
            component={RouterLink}
            to={rootRouting.root}
          >
            {getSiteCaption()}
          </Link>
          . Все изменения вступают в силу немедленно после публикации, и считаются доведенными до сведения Покупателя с
          момента такой публикации.
        </Text>
        <Text>
          2.2. Осуществлять записи телефонных переговоров с Покупателем. В соответствии с п. 4 ст. 16 Федерального
          закона «Об информации, информационных технологиях и о защите информации» «Витрины» обязуется: предотвращать
          попытки несанкционированного доступа к информации и/или передачу ее лицам, не имеющим непосредственного
          отношения к исполнению Заказов, своевременно обнаруживать и пресекать такие факты.
        </Text>
        <Text>
          2.3. Без согласования с Покупателем передавать свои права и обязанности по исполнению обязательств перед
          Покупателем третьим лицам.
        </Text>
        <Text>
          2.4. Использовать технологию «cookies». «Cookies» не содержат конфиденциальную информацию и не передаются
          третьим лицам.
        </Text>
        <Text>
          2.5. Получать информацию об ip-адресе посетителя веб-сайта{' '}
          <Link
            color='primary'
            underline='none'
            component={RouterLink}
            to={rootRouting.root}
          >
            {getSiteCaption()}
          </Link>
          . Данная информация не используется для установления личности посетителя и не подлежит передаче третьим лицам.
        </Text>
        <Text>
          2.6. Направлять Покупателю сообщения рекламно-информационного характера посредством e-mail и sms-рассылок с
          информацией о скидках, акциях, новых поступлениях и т.п. Частота рассылок определяется «Витриной»
          самостоятельно, в одностороннем порядке.
        </Text>
        <Text>
          2.7. В одностороннем порядке отменить Заказ Покупателя в случае нарушения Покупателем п.6.3.3. настоящих
          Правил.
        </Text>
        <Text>
          2.8. Изменять условия Акций, размещённых на сайте Торговой площадке{' '}
          <Link
            color='primary'
            underline='none'
            component={RouterLink}
            to={rootRouting.root}
          >
            {getSiteCaption()}
          </Link>
          , в одностороннем порядке без согласия Покупателя, путём размещения таких изменений на сайте Торговой площадки{' '}
          <Link
            color='primary'
            underline='none'
            component={RouterLink}
            to={rootRouting.root}
          >
            {getSiteCaption()}
          </Link>
        </Text>
        <Text>
          2.9. Отзыв настоящих Правил может быть осуществлён “Витриной» в любое время, но это не является основанием для
          отказа от обязательств ОАО “РЖД” и иных Продавцов, размещающих Товары на Торговой площадке, по уже заключённым
          договорам.
        </Text>
      </Subsection>
      <Subsection>
        <Text> 3. Покупатель обязуется:</Text>
        <Text>
          3.1. До момента заключения Договора ознакомиться с содержанием и условиями настоящих Правил, ценами на Товары,
          предлагаемыми на Торговой площадке,
        </Text>
        <Text>
          3.2. Во исполнение “Витриной» своих обязательств перед Покупателем последний должен сообщить все необходимые
          данные, однозначно идентифицирующие его как Покупателя
        </Text>
        <Text> 3.3. Не использовать Товар, заказанный на Торговой площадке в предпринимательских целях.</Text>
        <Text>
          3.4. Самостоятельно нести ответственность за доступ в личный кабинет. Обязуется не передавать третьим лицам
          доступ к его личному кабинету, Покупатель несёт ответственность за вред, причинённый Торговой площадке,
          продавцам, и любым третьим лицам, если вред причинён вследствие неправомерного использования личного кабинета
          Покупатель вправе отказаться от получения e-mail и sms-рассылок, для этого ему нужно нажать на ссылку в письме
          «Вы можете отписаться от рассылки из своего Личного кабинета» или зайти на страницу «Подписки» в Личном
          кабинете.
        </Text>
      </Subsection>
    </>
  );
};

export default AboutRightsAndObligationsInfo;
