import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { headerSetVisible } from '../../../../../header/store/slice';
import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { BookingDetailsId } from '../../types';

type SmartPhoneFixedBarAdapterProps = BookingDetailsId & {
  readonly isFavorite: boolean;
};

const SmartPhoneFixedBarAdapter: FC<SmartPhoneFixedBarAdapterProps> = ({ id, isFavorite }) => {
  const dispatch = useDispatch();
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { onBack, onAddToFavorite } = useContextHandlers();

  const { bookingOffer } = useBookingOfferDetailsData(id);

  useEffect(() => {
    if (isMdDown) {
      dispatch(headerSetVisible(false));
    } else {
      dispatch(headerSetVisible(true));
    }

    return () => {
      dispatch(headerSetVisible(true));
    };
  }, [dispatch, isMdDown]);

  if (!bookingOffer) {
    return null;
  }

  return (
    <OfferSmartPhoneFixedBar
      onBack={onBack}
      isFavorite={isFavorite}
      onFavoriteClick={onAddToFavorite}
      text={bookingOffer.name}
    />
  );
};

export default SmartPhoneFixedBarAdapter;
