import { Fragment } from 'react';
import { HeaderOptionItem } from '../../adapters/useHeaderOptions';
import { OptionsWrapper } from './controls';

export type HeaderOptionsProps = {
  readonly options: HeaderOptionItem[];
};

const HeaderOptions = ({ options }: HeaderOptionsProps) => {
  return (
    <OptionsWrapper>
      {options.map(option => (
        <Fragment key={option.type}>{option.component}</Fragment>
      ))}
    </OptionsWrapper>
  );
};

export default HeaderOptions;
