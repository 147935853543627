import { EAppCategories } from 'presentation/types';

export enum EUserFavoritesUrlParam {
  Tab = 'tab',
}

export enum EUserFavoritesTab {
  ProductOffers = 'productOffers',
  TradeOffers = 'tradeOffers',
  CorpOffers = 'corpOffers',
  BookingOffers = 'bookingOffers',
}

export type UserFavoritesTabInfo = {
  readonly label: string;
  readonly count?: number;
  readonly value: EUserFavoritesTab;
};

type GetUserFavoritesTabListProps = {
  readonly isFetching: boolean;
  readonly hasTradeOffersChanges: boolean;
  readonly hasCorpOffersChanges: boolean;
  readonly hasProductOffersChanges: boolean;
  readonly hasBookingOffersChanges: boolean;
  readonly tradeOffersCount: number;
  readonly corpOffersCount: number;
  readonly productOffersCount: number;
  readonly bookingOffersCount: number;
};

const FavoritesTabs = {
  [EUserFavoritesTab.ProductOffers]: EAppCategories.ProductOffers,
  [EUserFavoritesTab.TradeOffers]: EAppCategories.TradeOffers,
  [EUserFavoritesTab.CorpOffers]: EAppCategories.CorpOffers,
  [EUserFavoritesTab.BookingOffers]: EAppCategories.BookingOffers,
};

export const getUserFavoritesTabList = (props: GetUserFavoritesTabListProps): UserFavoritesTabInfo[] => {
  const {
    isFetching,
    hasTradeOffersChanges,
    hasCorpOffersChanges,
    hasProductOffersChanges,
    hasBookingOffersChanges,
    corpOffersCount,
    productOffersCount,
    tradeOffersCount,
    bookingOffersCount,
  } = props;

  if (isFetching) {
    return [];
  }

  const tabs: UserFavoritesTabInfo[] = [];

  if (corpOffersCount || hasCorpOffersChanges) {
    tabs.push({
      label: FavoritesTabs[EUserFavoritesTab.CorpOffers],
      count: corpOffersCount,
      value: EUserFavoritesTab.CorpOffers,
    });
  }
  if (tradeOffersCount || hasTradeOffersChanges) {
    tabs.push({
      label: FavoritesTabs[EUserFavoritesTab.TradeOffers],
      count: tradeOffersCount,
      value: EUserFavoritesTab.TradeOffers,
    });
  }
  if (productOffersCount || hasProductOffersChanges) {
    tabs.push({
      label: FavoritesTabs[EUserFavoritesTab.ProductOffers],
      count: productOffersCount,
      value: EUserFavoritesTab.ProductOffers,
    });
  }
  if (bookingOffersCount || hasBookingOffersChanges) {
    tabs.push({
      label: FavoritesTabs[EUserFavoritesTab.BookingOffers],
      count: bookingOffersCount,
      value: EUserFavoritesTab.BookingOffers,
    });
  }

  return tabs;
};

export const getUserFavoritesFirstActiveTab = (tab: Nullable<EUserFavoritesTab>, tabList: UserFavoritesTabInfo[]) => {
  return tabList.find(item => item.value === tab) ?? tabList?.[0] ?? null;
};
