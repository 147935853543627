import { useMediaQuery, useTheme } from '@mui/material';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import Splitter from 'presentation/components/common/splitter';
import useScrollToSections from 'presentation/features/about/hooks/useScrollToSections';
import { AboutInfoSection } from 'presentation/features/about/types';
import { useMemo, useState } from 'react';
import { useHeaderParams } from '../../hooks/useHeaderParams';
import BackgroundContainer from '../backgroundContainer';
import { EParentGridAreas } from '../detail/types';
import { Content, FitContainer, ParentLayoutGridArea, Sidebar, StickyTitle, Text, Wrapper } from './controls';
import { AboutSectionsLayoutProps } from './types';

const AboutSectionsLayout = <T extends string>(props: AboutSectionsLayoutProps<T>) => {
  const { title, description, tabs, children, close = null } = props;

  const theme = useTheme();
  const { headerHeight } = useHeaderParams();

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [stickyTitleRef] = useState<Nullable<HTMLDivElement>>(null);

  const scrollToSection = useScrollToSections<T>({
    topOffset: stickyTitleRef?.offsetHeight ?? 0,
  });

  const top = useMemo(() => {
    return headerHeight + +theme.spacing(isMobile ? 4 : 2).replace(/\D/g, '');
  }, [headerHeight, theme, isMobile]);

  const { current, sectionsRefs, setCurrentSection, scrollTo } = scrollToSection;

  const onChangeTab = (tab: AboutInfoSection<T>) => {
    setCurrentSection(tab);

    const currentEl = sectionsRefs.current.find(s => tab.id === s.id);
    scrollTo(currentEl?.element as HTMLDivElement);
  };

  return (
    <FitContainer disableGutters={isMobile}>
      <ParentLayoutGridArea area={EParentGridAreas.Inner}>
        <BackgroundContainer
          close={close}
          variant='main'
        >
          <Wrapper>
            <Content>
              <StickyTitle
                variant='h1'
                top={headerHeight}
              >
                {title}
              </StickyTitle>
              <Splitter size={isMobile ? theme.spacing(4) : 2} />
              {description && (
                <>
                  <Splitter size={isMobile ? 1 : 3} />
                  <Text>{description}</Text>
                </>
              )}
              {children({ ...scrollToSection, isCollapsable: isMobile })}
            </Content>
            <Sidebar top={top}>
              {!isMobile && tabs && (
                <MPGrid
                  item
                  sm={4}
                >
                  {tabs(current, onChangeTab)}
                </MPGrid>
              )}
            </Sidebar>
          </Wrapper>
        </BackgroundContainer>
      </ParentLayoutGridArea>
    </FitContainer>
  );
};

export default AboutSectionsLayout;
