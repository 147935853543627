import { ESecuredPartition } from 'domain/model/enums';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import PartnerDeskDetailsContainer from 'presentation/features/partnerDesk/details/container';
import { EPartnerDeskDetailsTab } from 'presentation/features/partnerDesk/details/utils';
import { PartnerDeskDetailsLocationState } from 'presentation/features/partnerDesk/entry';
import { EPartnerDeskUrlParam } from 'presentation/features/partnerDesk/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation, useParams } from 'react-router';
import useCurrentUser from '../../hooks/useCurrentUser';
import useVisibleSectionLinks from 'presentation/features/sectionLinks/hooks/useVisibleSectionLinks';

const PartnerDeskScreen = () => {
  const location = useLocation<Nullable<PartnerDeskDetailsLocationState>>();
  const { id } = useParams<{ id: UUID }>();
  const { accessMatrix } = useCurrentUser();

  const searchParams = new URLSearchParams(location.search);

  const tab = (searchParams.get(EPartnerDeskUrlParam.Tab) as EPartnerDeskDetailsTab) ?? null;
  const categoryId = searchParams.get(EPartnerDeskUrlParam.Category) ?? null;

  useVisibleSectionLinks();

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <PartnerDeskDetailsContainer
            guid={guid}
            id={id}
            tab={tab}
            categoryId={categoryId}
            isCorpUser={accessMatrix.isCorpUser}
            isTradeOfferFavoritesAllowed={accessMatrix[ESecuredPartition.TradeOffers].favorite}
            isCorpOfferFavoritesAllowed={accessMatrix[ESecuredPartition.CorpOffers].favorite}
            isProductOfferFavoritesAllowed={accessMatrix[ESecuredPartition.ProductOffers].favorite}
            isBookingOfferFavoritesAllowed={accessMatrix[ESecuredPartition.BookingOffers].favorite}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default PartnerDeskScreen;
