import ProductOfferDetailsContainer from 'presentation/features/offer/product/details/container';
import { EProductOfferDetailsTab } from 'presentation/features/offer/product/details/utils';
import { EProductOfferUrlParam } from 'presentation/features/offer/product/utils';
import { useLocation, useParams } from 'react-router';
import useCurrentUser from '../../hooks/useCurrentUser';
import ScreenLayout from '../../layouts/screen';

const ProductOfferScreen = () => {
  const location = useLocation();
  const { productOffers } = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const tab = (new URLSearchParams(location.search).get(EProductOfferUrlParam.Tab) ??
    EProductOfferDetailsTab.Characteristics) as EProductOfferDetailsTab;

  return (
    <ScreenLayout footer>
      <ProductOfferDetailsContainer
        id={id}
        tab={tab}
        canFavoriteActions={productOffers.favorite}
      />
    </ScreenLayout>
  );
};

export default ProductOfferScreen;
