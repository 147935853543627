import { EOfferStatus, ETradeOfferPromotionType } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import { OfferPromotionDownload } from '../../../../../components/promotionDownload/downloadUrl';
import { getOfferPromotionDownloadUrl } from '../../../../../utils';
import useTradeOfferSidebar from '../../provider/useTradeOfferSidebar';
import { TradeOfferSidebarCodePromotion } from './code';
import { TradeOfferSidebarReferralLinkPromotion } from './referralLink';
import TradeOfferSidebarWidgetPromotion from './widget';

type TradeOfferSidebarPromotionAdapterProps = {
  readonly wrapperRef: any;
};

export const TradeOfferSidebarPromotionAdapter = ({ wrapperRef }: TradeOfferSidebarPromotionAdapterProps) => {
  const {
    tradeOffer,
    activation,
    isActivationReceivedNow,
    onNeedCopy,
    copied,
    needCopy,
    onPartnerButtonClick,
    onTakeActivation,
    fileDownloading,
  } = useTradeOfferSidebar();

  return useMemo(() => {
    const { lastActivation } = activation;

    if (!lastActivation) {
      return null;
    }

    const promotionDownloadUrl = getOfferPromotionDownloadUrl(lastActivation);

    const isRenderAllowed = tradeOffer && (tradeOffer.status === EOfferStatus.Active || isActivationReceivedNow);

    const renderPromotions = () => {
      switch (lastActivation.promotion.type) {
        case ETradeOfferPromotionType.Voucher:
          return promotionDownloadUrl ? (
            <OfferPromotionDownload
              label={lastActivation.promotion.code}
              onClick={() => onTakeActivation(lastActivation)}
              isFetching={fileDownloading}
            />
          ) : null;

        case ETradeOfferPromotionType.PublicPromocode:
          return (
            <TradeOfferSidebarCodePromotion
              code={lastActivation.promotion.publicPromotionValue}
              onCopied={() => onTakeActivation(lastActivation)}
              onNeedCopy={onNeedCopy}
              copied={copied}
              needCopy={needCopy}
            />
          );
        case ETradeOfferPromotionType.AccessCode:
        case ETradeOfferPromotionType.Promocode:
          return (
            <TradeOfferSidebarCodePromotion
              code={lastActivation.promotion.code}
              onCopied={() => onTakeActivation(lastActivation)}
              onNeedCopy={onNeedCopy}
              copied={copied}
              needCopy={needCopy}
            />
          );
        case ETradeOfferPromotionType.Widget:
          return (
            <TradeOfferSidebarWidgetPromotion
              wrapperRef={wrapperRef}
              title={lastActivation.offer.title}
              srcDoc={lastActivation.promotion.publicPromotionValue}
            />
          );
        case ETradeOfferPromotionType.ReferralLink:
          return (
            <TradeOfferSidebarReferralLinkPromotion
              link={lastActivation.promotion.publicPromotionValue}
              onClick={onPartnerButtonClick}
            />
          );
        default:
          break;
      }
    };

    return isRenderAllowed ? (
      <MPGrid
        container
        alignItems='center'
        spacing={1}
      >
        <MPGrid
          item
          zero={12}
        >
          {renderPromotions()}
        </MPGrid>
      </MPGrid>
    ) : null;
  }, [
    activation,
    tradeOffer,
    isActivationReceivedNow,
    fileDownloading,
    onNeedCopy,
    copied,
    needCopy,
    wrapperRef,
    onPartnerButtonClick,
    onTakeActivation,
  ]);
};
