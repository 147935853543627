import ContentLoader from 'presentation/components/common/loader';
import ProductAttributesAdapter from 'presentation/features/offer/product/details/adapters/attributes';
import ProductOfferDetailsBreadcrumbsAdapter from 'presentation/features/offer/product/details/adapters/breadcrumbs';
import ProductOfferCarouselAdapter from './adapters/carousel';
import ProductOfferShortCharacteristicsAdapter from 'presentation/features/offer/product/details/adapters/characteristics';
import ProductOfferDetailsFavoriteOnlyAdapter from 'presentation/features/offer/product/details/adapters/favorite';
import ProductDetailsTabsAdapter from 'presentation/features/offer/product/details/adapters/tabs';
import ProductOfferDetailsOnlyTitleAdapter from 'presentation/features/offer/product/details/adapters/title';
import useProductOfferDetailsCategoriesData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsCategoriesData';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';
import useProductOfferPartnerDeskData from 'presentation/features/offer/product/details/hooks/useProductOfferPartnerDeskData';
import ProductOfferDetailsLayout from 'presentation/features/offer/product/details/layout';
import ProductOfferSidebarContainer from 'presentation/features/offer/product/sidebar/container';
import OfferDetailsErrorLayout from 'presentation/layouts/offerDetailsError';

import { defaultNetworkErrorStubText } from 'presentation/utils/constants';
import { createRef } from 'react';
import { useHistory } from 'react-router';
import { useHeaderParams } from '../../../../hooks/useHeaderParams';
import useScrollToElement from '../../../../hooks/useScrollToElement';
import { getProductOfferDetailsRoute } from '../entry';
import ProductOfferPartnerInfoAdapter from './adapters/partner';
import { EProductOfferDetailsTab } from './utils';
import { useMediaQuery, useTheme } from '@mui/material';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';

type ProductOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly tab: EProductOfferDetailsTab;
  readonly canFavoriteActions?: boolean;
};

const ErrorLayout = OfferDetailsErrorLayout;
const DetailsLayout = ProductOfferDetailsLayout;

const ProductOfferDetailsContainer = ({ id, tab }: ProductOfferDetailsContainerProps) => {
  const history = useHistory();
  const { headerHeight } = useHeaderParams();

  const characteristicTabRef = createRef<any>();

  const { scrollTo } = useScrollToElement({ adjusmentTop: headerHeight + 8 });

  const { categoriesIsFetching } = useProductOfferDetailsCategoriesData(id);
  const { isProductOfferError, isProductOfferFetching } = useProductOfferDetailsData(id);
  const { isPartnerDeskError, isPartnerDeskFetching } = useProductOfferPartnerDeskData(id);

  const changeTab = (newTab: EProductOfferDetailsTab) => {
    history.replace(getProductOfferDetailsRoute({ id, tab: newTab }));
  };

  const onShowAllCharacteristics = () => {
    changeTab(EProductOfferDetailsTab.Characteristics);
    scrollTo(characteristicTabRef.current);
  };

  const isFetching = isProductOfferFetching || isPartnerDeskFetching || categoriesIsFetching;
  const isError = isProductOfferError || isPartnerDeskError;

  const theme = useTheme();

  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  useVisibleSectionLinks(downMd);

  if (isError) {
    return <ErrorLayout text={defaultNetworkErrorStubText} />;
  }

  return (
    <>
      <DetailsLayout
        breadcrumbs={<ProductOfferDetailsBreadcrumbsAdapter id={id} />}
        title={<ProductOfferDetailsOnlyTitleAdapter id={id} />}
        favorite={<ProductOfferDetailsFavoriteOnlyAdapter id={id} />}
        carousel={<ProductOfferCarouselAdapter id={id} />}
        tabs={
          <ProductDetailsTabsAdapter
            id={id}
            tab={tab}
            characteristicTabRef={characteristicTabRef}
          />
        }
        attributes={<ProductAttributesAdapter id={id} />}
        shortCharacteristics={
          <ProductOfferShortCharacteristicsAdapter
            id={id}
            onShowAllCharacteristics={onShowAllCharacteristics}
          />
        }
        partnerInfo={<ProductOfferPartnerInfoAdapter id={id} />}
        sidebar={<ProductOfferSidebarContainer id={id} />}
      />
      {isFetching && <ContentLoader />}
    </>
  );
};

export default ProductOfferDetailsContainer;
