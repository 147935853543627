import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { MPButton } from 'presentation/theme/ui-kit/button';

export const StyledButtonLink = styled(MPButton)(
  ({ theme }) => css`
    &.MuiButton-root {
      min-height: 0;
      height: auto;

      padding: 0;
    }

    & .MuiTypography-root {
      font-weight: ${theme.typography.fontWeightMedium};
    }

    &:hover {
      background: none;

      ${theme.breakpoints.up('md')} {
        text-decoration: underline;
      }
    }
  `
);
