import { Logout } from '@mp-npm/mp-auth-client';
import { useGetCurrentUserQuery } from 'data/api/user';
import { ServerErrorResponse } from 'data/network/types';
import { EUserRole } from 'domain/model/enums';
import { UserAccessMatrix } from 'domain/model/security';
import { SecurityToken } from 'domain/model/user';
import ContentLoader from 'presentation/components/common/loader';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Notifier from 'system/notifier';
import useTechConfig from '../../../../hooks/useTechConfig';
import useUserCorpConfirmation from '../../../../hooks/useUserCorpConfirmation';
import { isSecurityBusinessError } from '../../../../utils/auth';
import { buildUserAccessMatrix } from '../../securityUtils';
import CurrentUserContext, { CurrentUserContextType } from './context';

type CurrentUserProviderProps = {
  readonly token: SecurityToken;
  readonly roles: EUserRole[];
  readonly logOut: Logout;
  readonly refreshToken: () => Promise<void>;
  readonly resetPassword: () => void;
  readonly bindRzhdProfile: () => void;
  readonly children: JSX.Element;
};

type CurrentUserProviderType = (props: CurrentUserProviderProps) => JSX.Element;

const CurrentUserProvider: CurrentUserProviderType = props => {
  const { roles, logOut, refreshToken, resetPassword, bindRzhdProfile, children } = props;

  const dispatch = useDispatch();
  const { hasFeature } = useTechConfig();
  const [accessMatrix, setAccessMatrix] = useState<Nullable<UserAccessMatrix>>(null);
  const [contextValue, setContextValue] = useState<Nullable<CurrentUserContextType>>(null);

  const { data: user, error: userFetchError, refetch: userRefetch } = useGetCurrentUserQuery({});

  const refreshTokenInternal = useCallback(() => {
    return refreshToken().then(() => {
      userRefetch();
    });
  }, [refreshToken, userRefetch]);

  const { errorCorpConfirmation } = useUserCorpConfirmation({
    refreshToken,
    userRefetch,
    bindRzhdProfile,
  });

  // проверка наличия вообще ролей
  useEffect(() => {
    if (roles) {
      const hasAnyRole = (roles && roles?.length > 0) ?? false;

      if (!hasAnyRole) {
        Notifier.getInstance().addError('Вы не обладаете полномочиями');
        console.error('Not found user roles');
        logOut();
      }
    }
  }, [dispatch, logOut, roles]);

  useEffect(() => {
    if (user) {
      // пересчет матрицы доступа
      const newAccessMatrix = buildUserAccessMatrix(user, roles, hasFeature);
      setAccessMatrix(newAccessMatrix);
      console.debug('access matrix', newAccessMatrix);
    }
  }, [user, roles, hasFeature]);

  // ошибка загрузки текущего специфического юзера
  useEffect(() => {
    if (userFetchError && !isSecurityBusinessError((userFetchError as any)?.data as ServerErrorResponse)) {
      Notifier.getInstance().addError('При получении информации о текущем пользователе произошла ошибка');
      console.error('Error at request current user');
      logOut({ withTimeout: true });
    }
  }, [logOut, userFetchError]);

  // обновление стейта контекста для оптимальности
  useEffect(() => {
    if (!user || !accessMatrix) {
      return;
    }

    setContextValue({
      user,
      accessMatrix,
      defaultRoute: accessMatrix.defaultRoute,
      refreshToken: refreshTokenInternal,
      logOut,
      bindRzhdProfile,
      resetPassword,
    });
  }, [user, accessMatrix, logOut, refreshTokenInternal, bindRzhdProfile, resetPassword]);

  useEffect(() => {
    if (errorCorpConfirmation) {
      // если произошла ошибка подтверждения - делаем логаут
      logOut({ withTimeout: true });
    }
  }, [errorCorpConfirmation, logOut]);

  if (!contextValue) {
    return <ContentLoader />;
  }

  return <CurrentUserContext.Provider value={contextValue}>{children}</CurrentUserContext.Provider>;
};

export default CurrentUserProvider;
