import styled from '@emotion/styled/macro';
import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';

type IconButtonProps = MuiIconButtonProps & {
  readonly selected?: boolean;
};

export const IconButton = styled(MuiIconButton)<IconButtonProps>`
  svg path {
    fill: ${p => (p.selected ? p.theme.palette.brand.main : p.theme.palette.secondary.A900)};
    transition: fill 300ms ease;
  }
`;
