import { List, ListItem, Text } from '../../components/controls';

const AboutTradingRulesOrderingInfo = () => {
  return (
    <>
      <Text>1. Торговая площадка предназначена для реализации Товаров и услуг ОАО «РЖД» и иных Продавцов,</Text>
      <Text>
        2. Заказывая на Торговой площадке Товар, реализуемый ОАО «РЖД», Покупатель вступает в договорные отношения с ОАО
        «РЖД» в рамках договора купли-продажи Товара.
      </Text>
      <Text>
        3. Заказывая на Торговой площадке Товар, реализуемый иными Продавцами, Покупатель в рамках договора
        купли-продажи вступает в договорные отношения непосредственно с Продавцом, реализующим данный Товар. Доставка
        Товара, а также прием оплаты от Покупателей за Товар, приобретаемый на Торговой площадке осуществляется
        Продавцом.
      </Text>
      <Text>
        4. Взаиморасчеты с Покупателем за Товар, приобретенный им на Торговой площадке, осуществляется Продавцом от
        своего имени или по поручению непосредственного Продавца от имени последнего.
      </Text>
      <Text> 5. Покупатель может ознакомиться с информацией о продавце товара при оформлении Заказа.</Text>
      <Text>
        6. Доставка любого Товара, приобретаемого Покупателем посредством Торговой площадки, осуществляется Продавцом.
      </Text>
      <Text>
        7. До момента совершения Заказа Покупатель обязуется ознакомиться со всеми условиями оказания Услуг, а также
        приобретения Товаров на Торговой площадке. При этом очевидные ошибки, включая опечатки, допущенные в описании
        Товара либо в описании Услуги, юридически обязывающими не являются.
      </Text>
      <Text>
        8. Оформление Заказа на Торговой площадке означает согласие Покупателя со всеми существенными условиями оказания
        Продавцом услуг доставки Товаров и/или существенными условиями продажи Товара Продавцом, а также настоящими
        Правилами.
      </Text>
      <Text>
        9. Существенные условия заключаемого Покупателем договора купли-продажи Товара, а также договора на оказание
        услуг доставки указываются в опубликованном на Торговой площадке описании Товара, предоставленного в форме
        оформления Заказа, а также в настоящих Правилах.
      </Text>
      <Text>
        10. Осуществляя Заказ, Покупатель соглашается с тем, что «Витрина» может поручить исполнение договора третьему
        лицу, НЕ оставаясь ответственным за его исполнение перед Покупателем.
      </Text>
      <Text> 11. Покупатель подтверждает свое согласие с тем, что:</Text>
      <List>
        <ListItem>
          регистрационные данные (в том числе персональные данные) передаются в электронной форме по открытым каналам
          связи сети «Интернет»;
        </ListItem>
        <ListItem> регистрационные данные (в том числе персональные данные) указаны им добровольно;</ListItem>
        <ListItem>
          регистрационные данные (в том числе персональные данные) переданы торговой площадке «Витрина» для реализации
          целей, указанных в настоящих Правилах и в этих же целях могут быть переданы третьим лицам;
        </ListItem>
        <ListItem>
          регистрационные данные (в том числе персональные данные) могут быть использованы торговой площадкой «Витрина»
          в целях продвижения товаров и услуг, путем осуществления прямых контактов с Покупателем с помощью каналов
          связи;
        </ListItem>
        <ListItem>
          регистрационные данные (в том числе персональные данные) могут быть использованы торговой площадкой «Витрина»
          в целях направления Покупателю рекламы и информации по сетям электросвязи, в том числе через Интернет, в
          соответствии со ст.18 Ф3 «О рекламе» от 13.03.2006 г.;
        </ListItem>
        <ListItem>
          данное Покупателем согласие на обработку его регистрационных данных (в том числе персональных данных) является
          бессрочным и может быть отозвано Покупателем или его законным представителем, подачей письменного заявления,
          переданного торговой площадке «Витрины».
        </ListItem>
      </List>
      <Text> 12. Осуществляя Заказ, Покупатель подтверждает, что:</Text>
      <Text> а) полностью ознакомился с положениями настоящих Правил;</Text>
      <Text>
        б) полностью понимает настоящие Правила, а также предмет и условия заключаемого с торговой площадкой «Витрина»
        договора на оказание услуг Товаров, а также договора купли-продажи Товаров с ОАО «РЖД» или иным Продавцом
        посредством Торговой площадки;
      </Text>
      <Text>
        в) полностью понимает значение и последствия своих действий в отношении заключения и исполнения заключенного с
        ОАО «РЖД» договора купли-продажи Товаров и услуг с ОАО «РЖД» или иным Продавцом посредством Торговой площадки.
      </Text>
      <Text>
        13. Оформляя заказ на Торговой площадке, Покупатель соглашается на получение: (а) электронных
        писем/push-уведомлений, которые «Витрина» может направить Покупателю; (б) сообщений (SMS) на телефонный номер,
        указанный Покупателем, а также (в) звонка с предложением оценить качество работы Торговой площадки на телефонный
        номер, указанный Пользователем.
      </Text>
      <Text>
        14. Торговая площадка «Витрина» вправе заблокировать для Покупателя возможность осуществления Заказа в случае
        неоднократной отмены осуществленных Покупателем Заказов, а также в случае нарушения Покупателем настоящих
      </Text>
    </>
  );
};
export default AboutTradingRulesOrderingInfo;
