import { ScopeContext } from '@sentry/types/types/scope';
import SentryClient from 'integration/sentry/client';
import { useMemo, useState } from 'react';
import SentryContext, { SentryContextType } from './context';

type SentryProviderProps = {
  readonly user: { id: string; email: string };
  readonly children: JSX.Element;
};

type SentryProviderType = (props: SentryProviderProps) => JSX.Element;

const SentryProvider: SentryProviderType = props => {
  const { user, children } = props;
  const { id: userId, email: userEmail } = user;

  const captureException = useMemo<SentryContextType['captureException']>(
    () => (exception, context) => {
      const sentryClient = SentryClient.getInstance();
      const contextResult: Partial<ScopeContext> = { ...(context ?? {}) };
      const captureUserAttributes = sentryClient.getCaptureUserAttributes();
      if (captureUserAttributes?.length) {
        contextResult.user = captureUserAttributes.reduce<any>((next, prev) => {
          const key = prev as keyof typeof user;
          if (key === 'id') {
            next[prev] = userId;
          }
          if (key === 'email') {
            next[prev] = userEmail;
          }
          return next;
        }, {});
      }
      SentryClient.getInstance().captureException(exception, contextResult);
    },
    [userId, userEmail]
  );

  const [contextValue] = useState<SentryContextType>({
    captureException,
  });

  return <SentryContext.Provider value={contextValue}>{children}</SentryContext.Provider>;
};

export default SentryProvider;
