import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import SentryProvider from 'presentation/components/sentry/provider';
import AboutEntry from 'presentation/features/about/entry';
import CartEntry from 'presentation/features/cart/entry';
import CmsEntry from 'presentation/features/cms/entry';
import CookiesAcceptContainer from 'presentation/features/cookiesAccept/container';
import DataEntry from 'presentation/features/data/entry';
import LocationDetector from 'presentation/features/general/locationDetector/container';
import { RouteByFeature } from 'presentation/features/general/routing/routeByFeature';
import LandingEntry from 'presentation/features/landing/entry';
import AppNotificationsReceiver from 'presentation/features/notification/receiver';
import BookingOfferEntry from 'presentation/features/offer/booking/entry';
import CorpOfferEntry from 'presentation/features/offer/corp/entry';
import ProductOfferEntry from 'presentation/features/offer/product/entry';
import TradeOfferEntry from 'presentation/features/offer/trade/entry';
import PartnerDeskEntry from 'presentation/features/partnerDesk/entry';
import CurrentUserBalanceProvider from 'presentation/features/user/current/balance/provider';
import UserSettingsProvider from 'presentation/features/user/current/userSettingsProvider';
import UserEntry from 'presentation/features/user/entry';
import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { EAppFeature } from 'presentation/types';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import NoopDecorator from '../../../components/common/renderDecoratorContainer/noopDecorator';
import useTechConfig from '../../../hooks/useTechConfig';
import HeaderContainer from '../../header/container';
import SearchEntry from '../../search/entry';
import SectionLinksContainer from '../../sectionLinks/container';
import { WsClientProvider } from '../../wsClient/wsClientProvider';
import { Content, Wrapper } from './controls';

const MainContainer = () => {
  const { user, accessMatrix, defaultRoute } = useCurrentUser();
  const { hasFeature } = useTechConfig();

  return (
    <SentryProvider user={user}>
      <WsClientProvider>
        <CurrentUserBalanceProvider
          user={user}
          accessMatrix={accessMatrix}
        >
          <UserSettingsProvider userId={user.id}>
            <AppNotificationsReceiver />
            <LocationDetector>
              <RenderDecoratorContainer
                decorators={[
                  hasFeature(EAppFeature.Cookies) ? (
                    <CookiesAcceptContainer key='cookiesAcceptContainer' />
                  ) : (
                    <NoopDecorator />
                  ),
                ]}
              >
                <Wrapper>
                  <HeaderContainer />
                  <SectionLinksContainer />
                  <Content>
                    <Switch>
                      <Route
                        path={rootRouting.partner}
                        component={PartnerDeskEntry}
                      />
                      <RouteByFeature
                        feature={EAppFeature.Trade}
                        path={rootRouting.tradeOffer}
                        component={TradeOfferEntry}
                        defaultRoute={defaultRoute}
                      />
                      <Route
                        path={rootRouting.corpOffer}
                        component={CorpOfferEntry}
                      />
                      <Route
                        path={rootRouting.user}
                        component={UserEntry}
                      />
                      <Route
                        path={rootRouting.about}
                        component={AboutEntry}
                      />
                      <RouteByFeature
                        feature={EAppFeature.Product}
                        path={rootRouting.product}
                        component={ProductOfferEntry}
                        defaultRoute={defaultRoute}
                      />
                      <RouteByFeature
                        feature={EAppFeature.Product}
                        path={rootRouting.cart}
                        component={CartEntry}
                        defaultRoute={defaultRoute}
                      />
                      <RouteByFeature
                        feature={EAppFeature.Booking}
                        path={rootRouting.bookingOffer}
                        component={BookingOfferEntry}
                        defaultRoute={defaultRoute}
                      />
                      <Route
                        path={rootRouting.data}
                        component={DataEntry}
                      />
                      <Route
                        path={rootRouting.cms}
                        component={CmsEntry}
                      />
                      <Route
                        path={rootRouting.search}
                        component={SearchEntry}
                      />
                      <RouteByFeature
                        feature={EAppFeature.LandingWindow}
                        path={rootRouting.root}
                        component={LandingEntry}
                        defaultRoute={defaultRoute}
                      />
                      <Redirect to={defaultRoute} />
                    </Switch>
                  </Content>
                </Wrapper>
              </RenderDecoratorContainer>
            </LocationDetector>
          </UserSettingsProvider>
        </CurrentUserBalanceProvider>
      </WsClientProvider>
    </SentryProvider>
  );
};

export default MainContainer;
