import { useGetUserOrderQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import ContentLoader from 'presentation/components/common/loader';
import { useEffect } from 'react';
import OrderDetails from '../../../../order/components/details';

type UserOrderContainerProps = {
  readonly id: UUID;
};

const UserOrderContainer = ({ id }: UserOrderContainerProps) => {
  const {
    data: order,
    isFetching,
    isLoading,
    error: orderFetchError,
  } = useGetUserOrderQuery(id, { refetchOnMountOrArgChange: true });

  useEffect(() => {
    if (orderFetchError) {
      ErrorHandler.handleHttpError(orderFetchError);
    }
  }, [orderFetchError]);

  return (
    <>
      {order && <OrderDetails order={order} />}
      {(isFetching || isLoading) && <ContentLoader />}
    </>
  );
};

export default UserOrderContainer;
