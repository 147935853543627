import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { CheckIcon } from 'presentation/media/icons';
import { MPChip } from 'presentation/theme/ui-kit/chip';
import { useMemo } from 'react';

export const UserProfileCorpLinkedAdapter = () => {
  const {
    accessMatrix: { isCorpUser },
  } = useCurrentUser();

  return useMemo(
    () =>
      isCorpUser ? (
        <MPChip
          icon={<CheckIcon />}
          label='Работник ОАО «РЖД»'
          size='medium'
          color='success'
        />
      ) : null,
    [isCorpUser]
  );
};
