import { Typography } from '@mui/material';
import FileDownloadButton from 'presentation/components/common/buttons/fileDownload';
import { PromotionButtonWrapper } from '../../trade/details/sidebar/controls';
import { DownloadLabel, StyledDottedButton, StyledDownloadIcon, TextLabel } from './controls';

type OfferPromotionDownloadLegacyProps = {
  readonly label: string;
  readonly url: string;
  readonly onDownload?: () => void;
};

export const OfferPromotionDownloadLegacy = (props: OfferPromotionDownloadLegacyProps) => {
  const { label, url, onDownload } = props;

  return (
    <PromotionButtonWrapper>
      <FileDownloadButton
        src={url}
        fullWidth
        buttonComponent={StyledDottedButton}
        variant='outlined'
        onDownload={isDownloaded => isDownloaded && onDownload?.()}
      >
        <>
          <TextLabel color='textPrimary'>{label}</TextLabel>
          <DownloadLabel>
            <StyledDownloadIcon fontSize='micro' />
            <Typography variant='body2'>скачать</Typography>
          </DownloadLabel>
        </>
      </FileDownloadButton>
    </PromotionButtonWrapper>
  );
};
