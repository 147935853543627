import UserFavoritesStubAdapter from 'presentation/features/user/details/favorites/adapters/stub';
import UserDetailsLayout from 'presentation/layouts/userDetails';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { getUserFavoritesRoute } from '../../entry';
import UserFavoritesLoaderAdapter from './adapters/loader';
import { useUserFavoritesTabs } from './hooks/useTabs';
import {
  UserFavoritesBookingsOffersContainer,
  UserFavoritesCorpOffersContainer,
  UserFavoritesProductOffersContainer,
  UserFavoritesTabs,
  UserFavoritesTradeOffersContainer,
} from './tabs';
import { EUserFavoritesTab } from './utils';

type UserFavoritesContainerProps = {
  readonly guid: UUID;
  readonly tab: EUserFavoritesTab;
};

const Layout = UserDetailsLayout;

const UserFavoritesContainer = (props: UserFavoritesContainerProps) => {
  const history = useHistory();

  const { guid, tab } = props;

  const [queryGuid] = useMemo<UUID>(() => uuidv4(), []);

  const { tabList, tabToDisplay } = useUserFavoritesTabs({
    guid,
    tab,
  });

  const onChangeTab = useCallback(
    (newTab: EUserFavoritesTab) => {
      history.replace(getUserFavoritesRoute({ guid, tab: newTab }));
    },
    [guid, history]
  );

  const tabsComponent = useMemo(
    () => (
      <UserFavoritesTabs
        tab={tabToDisplay}
        tabs={tabList}
        onChangeTab={onChangeTab}
      />
    ),
    [tabToDisplay, tabList, onChangeTab]
  );

  return (
    <Layout
      header='Избранное'
      toolbar={tabsComponent}
      loader={<UserFavoritesLoaderAdapter guid={queryGuid} />}
    >
      {tabToDisplay?.value === EUserFavoritesTab.ProductOffers && (
        <UserFavoritesProductOffersContainer
          guid={guid}
          queryGuid={queryGuid}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.TradeOffers && (
        <UserFavoritesTradeOffersContainer
          guid={guid}
          queryGuid={queryGuid}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.CorpOffers && (
        <UserFavoritesCorpOffersContainer
          guid={guid}
          queryGuid={queryGuid}
        />
      )}
      {tabToDisplay?.value === EUserFavoritesTab.BookingOffers && (
        <UserFavoritesBookingsOffersContainer
          guid={guid}
          queryGuid={queryGuid}
        />
      )}
      <UserFavoritesStubAdapter
        countGuid={guid}
        tabsCount={tabList.length}
      />
    </Layout>
  );
};

export default UserFavoritesContainer;
