import { FC } from 'react';
import { SeeAndSunIcon } from 'presentation/media/icons';
import OrderLayout from '../layout';
import CloseAdapter from '../adapters/close';
import BookingOfferOrderSmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import BookingOfferOrderOptionsAdapter from './adapters/options';
import BookingOfferOrderDetailsAdapter from './adapters/details';
import { BookingOrderProps } from './types';
import { useOrderPrepare } from '../hooks/useOrderPrepare';

const Close = <CloseAdapter />;

const BookingOfferOrder: FC<BookingOrderProps> = props => {
  useOrderPrepare();

  return (
    <OrderLayout
      smartphoneFixedBar={<BookingOfferOrderSmartPhoneFixedBarAdapter {...props} />}
      close={Close}
      options={<BookingOfferOrderOptionsAdapter {...props} />}
      icon={SeeAndSunIcon}
      details={<BookingOfferOrderDetailsAdapter {...props} />}
    />
  );
};

export default BookingOfferOrder;
