import { createSelector } from '@reduxjs/toolkit';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { userApi } from 'data/api/user';
import { RootState } from 'data/store/store';

export const getUserFavoritesTradeOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesTradeOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesCorpOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesCorpOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesProductOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesProductOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesBookingsOffersIsFetchingSelector = (guid: UUID) => (store: RootState) =>
  userApi.endpoints.getUserFavoritesBookingsOffers.select({ guid })(store).status === QueryStatus.pending;

export const getUserFavoritesOffersIsFetchingSelector = (guid: UUID) =>
  createSelector(
    getUserFavoritesTradeOffersIsFetchingSelector(guid),
    getUserFavoritesCorpOffersIsFetchingSelector(guid),
    getUserFavoritesProductOffersIsFetchingSelector(guid),
    getUserFavoritesBookingsOffersIsFetchingSelector(guid),
    (tradeOffersIsFetching, corpOffersIsFetching, productOffersIsFetching, bookingOffersIsFetching) => {
      return tradeOffersIsFetching || corpOffersIsFetching || productOffersIsFetching || bookingOffersIsFetching;
    }
  );

export const getUserFavoritesSelector = () => (store: RootState) => {
  const request = userApi.endpoints.getUserFavorites.select()(store);
  return request.data;
};
