import { AppOptionTyped } from './index';

export enum ENoticeStatus {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export enum EUserRole {
  Customer = 'customer',
  Corp = 'corp_user',
  AdminMp = 'admin_mp',
  AdminPartner = 'admin_partner',
}

export enum EBannerSortType {
  ByNovelty = 'startDate,desc',
}

export enum ETradeOfferSortType {
  Default = 'sortIndex,id',
  ByNovelty = 'startDate,desc',
  ByEndDate = 'endDate',
}

export enum ECorpOfferSortType {
  Default = 'sortIndex,id',
  ByPriceAsc = 'price,asc',
  ByPriceDesc = 'price,desc',
  ByNovelty = 'startDate,desc',
  ByEndDate = 'endDate',
}

export enum EBookingOfferSortType {
  Default = 'rating',
  ByMinPrice = 'minPrice',
  ByMaxPrice = 'minPrice,desc',
  ByNovelty = 'new,desc',
}

export type OfferSortTypes = ETradeOfferSortType | ECorpOfferSortType | EProductOfferSortType | EBookingOfferSortType;

export enum EProductOfferSortType {
  ByPriceAsc = 'price,asc',
  ByPriceDesc = 'price,desc',
  ByDiscount = 'salePercent,desc',
  ByNovelty = 'createdAt,desc',
}

export enum EOfferActivationSortType {
  ByCreatedAt = 'createdAt,desc',
}

export enum EOrderSortType {
  ByCreatedAt = 'createdAt,desc',
}

export enum ESortDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EOfferStatus {
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
  OutOfStock = 'OUT_OF_STOCK',
}

export enum ETradeOfferPausedReason {
  PausedByAdmin = 'PAUSED_BY_ADMIN',
  PausedByPartner = 'PAUSED_BY_PARTNER',
  EndOfCodes = 'END_OF_CODES',
}

export enum EUserStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
  NotFilled = 'NOT_FILLED ',
}

export enum EPartnerStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED',
  Deleted = 'DELETED',
}

export enum EPartnerType {
  Inner = 'INNER_PARTNER',
  External = 'EXTERNAL_PARTNER',
}

export enum EOwnershipType {
  LegalEntity = 'LEGAL_ENTITY',
  Individual = 'INDIVIDUAL',
  IndividualEntrepreneur = 'INDIVIDUAL_ENTREPRENEUR',
  SelfEmployed = 'SELF_EMPLOYED',
  Bank = 'BANK',
  CreditOrganization = 'CREDIT_ORGANIZATION',
}

export enum EOrderStatus {
  /** новый */
  New = 'NEW',
  /** подтвержден */
  Confirmed = 'CONFIRMED',
  /** возврат полный */
  Returned = 'RETURNED',
  /** отменен */
  Cancelled = 'CANCELLED',
  /** получен клиентом */
  Given = 'GIVEN',
  /** активен */
  Active = 'ACTIVE',
  /** оплачен */
  Paid = 'PAID',
  /** отправлен */
  Sent = 'SENT',
  /** возврат частичный */
  PartiallyReturned = 'PARTIALLY_RETURNED',
}

export enum EOrderItemStatus {
  /** новый */
  New = 'NEW',
  /** подтвержден */
  Confirmed = 'CONFIRMED',
  /** возврат полный */
  Returned = 'RETURNED',
  /** отменен */
  Cancelled = 'CANCELLED',
  /** получен клиентом */
  Given = 'GIVEN',
  /** изменён */
  Changed = 'CHANGED',
  /** получено частично */
  PartiallyReceived = 'PARTIALLY_RECEIVED',
}

export enum ETradeOfferPromotionType {
  PublicPromocode = 'PUBLIC_PROMOCODE',
  Promocode = 'PROMOCODE',
  Voucher = 'VOUCHER',
  ReferralLink = 'REFERRAL_LINK',
  Widget = 'WIDGET',
  AccessCode = 'ACCESS_CODE',
}

export enum ECorpOfferPromotionType {
  Certificate = 'CORP_CERTIFICATE',
  ExternalCertificate = 'EXTERNAL_CORP_CERTIFICATE',
}

export type TradePersonalPromotionType =
  | ETradeOfferPromotionType.Promocode
  | ETradeOfferPromotionType.Voucher
  | ETradeOfferPromotionType.AccessCode;

export type OfferPromotionType = ETradeOfferPromotionType | ECorpOfferPromotionType;

export type TradePublicPromotionType =
  | ETradeOfferPromotionType.PublicPromocode
  | ETradeOfferPromotionType.Widget
  | ETradeOfferPromotionType.ReferralLink;

export type PersonalPromotionType = TradePersonalPromotionType | ECorpOfferPromotionType;

export enum EOfferActivateError {
  InvalidOfferStatus = 'INVALID_OFFER_STATUS',
  PromotionFreshOut = 'PROMOTION_FRESH_OUT',
  OfferActivationAlreadyExist = 'OFFER_ACTIVATION_ALREADY_EXIST',
  InappropriateTargeting = 'INAPPROPRIATE_TARGETING',
  PromotionTimeLimit = 'PROMOTION_TIME_LIMIT',
  ProfileNotFilled = 'INVALID_USER_STATUS',
  Unknown = 'UNKNOWN',
  PromotionDayCountLimit = 'PROMOTION_DAY_COUNT_LIMIT',
}

export enum EOrderCreateError {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Unknown = 'UNKNOWN',
}

export type OfferUnavailabilityReason = EOfferActivateError;

export enum ETargetType {
  Geo = 'GEO',
  Corp = 'CORP',
}

export enum EUserGender {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum EAudienceType {
  Corporate = 'CORP_USER',
  External = 'EXTERNAL_USER',
}

// Оператор утверждения(all - все значения перечисления, in - подмножество)
export enum EMultiSelectorValueType {
  All = 'all',
  In = 'in',
}

export const USER_GENDER: { [name in EUserGender]: AppOptionTyped<EUserGender> } = {
  [EUserGender.Male]: {
    id: EUserGender.Male,
    name: 'Мужской',
  },
  [EUserGender.Female]: {
    id: EUserGender.Female,
    name: 'Женский',
  },
};

export enum ECurrencyType {
  Point = 'POINT',
}

export enum ESecuredPartition {
  TradeOffers = 'tradeOffers',
  CorpOffers = 'corpOffers',
  ProductOffers = 'productOffers',
  BookingOffers = 'bookingOffers',
  CmsTest = 'cmsTest',
}

export enum EBannerPartition {
  TradeOffers = 'trade-offers',
  CorpOffers = 'corp-offers',
  ProductOffers = 'product-offers',
}

export enum EBannerPlace {
  TradeOffersListPageTop = 'TRADE_OFFERS_LIST_PAGE_TOP',
  CorpOffersListPageTop = 'CORP_OFFERS_LIST_PAGE_TOP',
  ProductOffersListPageTop = 'PRODUCT_OFFERS_LIST_PAGE_TOP',
}

export enum EBannerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum EOfferType {
  Trade = 'TRADE_OFFER',
  Corp = 'CORP_OFFER',
  Product = 'PRODUCT_OFFER',
  Booking = 'BOOKING_OFFER',
}

export enum EUserEventTypeCode {
  OfferReceiveClicked = 'OFFER_RECEIVE_CLICKED',
  JumpToPartnerSite = 'JUMP_TO_PARTNER_SITE',
  ObjectAddedToFavorites = 'OBJECT_ADDED_TO_FAVORITES',
  //просмотр каточки предложения
  OfferView = 'CUSTOMER_OFFER_VIEW',
  //показ каточки предложения в списке
  OfferShow = 'CUSTOMER_OFFER_SHOW',
}

export enum EOfferActivationStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Given = 'GIVEN',
}

export const tradeOfferSortType: { [name in ETradeOfferSortType]: AppOptionTyped<ETradeOfferSortType> } = {
  [ETradeOfferSortType.Default]: {
    id: ETradeOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [ETradeOfferSortType.ByNovelty]: {
    id: ETradeOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
  [ETradeOfferSortType.ByEndDate]: {
    id: ETradeOfferSortType.ByEndDate,
    name: 'Скоро закончатся',
  },
};

export const corpOfferSortType: { [name in ECorpOfferSortType]: AppOptionTyped<ECorpOfferSortType> } = {
  [ECorpOfferSortType.Default]: {
    id: ECorpOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [ECorpOfferSortType.ByPriceAsc]: {
    id: ECorpOfferSortType.ByPriceAsc,
    name: 'Сначала дешевле',
  },
  [ECorpOfferSortType.ByPriceDesc]: {
    id: ECorpOfferSortType.ByPriceDesc,
    name: 'Сначала дороже',
  },
  [ECorpOfferSortType.ByNovelty]: {
    id: ECorpOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
  [ECorpOfferSortType.ByEndDate]: {
    id: ECorpOfferSortType.ByEndDate,
    name: 'Скоро закончатся',
  },
};

export const bookingOfferSortType: { [name in EBookingOfferSortType]: AppOptionTyped<EBookingOfferSortType> } = {
  [EBookingOfferSortType.Default]: {
    id: EBookingOfferSortType.Default,
    name: 'Сначала популярные',
  },
  [EBookingOfferSortType.ByMinPrice]: {
    id: EBookingOfferSortType.ByMinPrice,
    name: 'Сначала дешевле',
  },
  [EBookingOfferSortType.ByMaxPrice]: {
    id: EBookingOfferSortType.ByMaxPrice,
    name: 'Сначала дороже',
  },
  [EBookingOfferSortType.ByNovelty]: {
    id: EBookingOfferSortType.ByNovelty,
    name: 'Сначала новые',
  },
};

export const productOfferSortType: { [name in EProductOfferSortType]: AppOptionTyped<EProductOfferSortType> } = {
  [EProductOfferSortType.ByPriceAsc]: {
    id: EProductOfferSortType.ByPriceAsc,
    name: 'Сначала дешевле',
  },
  [EProductOfferSortType.ByPriceDesc]: {
    id: EProductOfferSortType.ByPriceDesc,
    name: 'Сначала дороже',
  },
  [EProductOfferSortType.ByDiscount]: {
    id: EProductOfferSortType.ByDiscount,
    name: 'По скидке',
  },
  [EProductOfferSortType.ByNovelty]: {
    id: EProductOfferSortType.ByNovelty,
    name: 'По новизне',
  },
};

export enum ECmsContainerType {
  Banner1 = 'BANNER_1',
  Banner1Resizable = 'BANNER_1_RESIZABLE',
  Banner1LinkResizable = 'BANNER_1_LINK_RESIZABLE',
  Banner2 = 'BANNER_2',
  Banner2MobileWide = 'BANNER_2_MOBILE_WIDE',
  Banner3 = 'BANNER_3',
  Banner3Carousel = 'BANNER_3_CAROUSEL',
  Banner3Resizable = 'BANNER_3_RESIZABLE',
  Banner4 = 'BANNER_4',
  Banner4High = 'BANNER_4_HIGH',
  CompilationOffer = 'COMPILATION_OFFER',
  CompilationPartner = 'COMPILATION_PARTNER',
  Category4Offer = 'CATEGORY_4_OFFER',
}

export enum ECmsContainerStatus {
  Draft = 'DRAFT',
  Upcoming = 'UPCOMING',
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  Archived = 'ARCHIVED',
}

export enum ECmsLinkObjectType {
  Banner = 'BANNER',
  PartnerCollection = 'PARTNER_COLLECTION',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  Collection = 'COLLECTION',
  CorpOfferCollection = 'CORP_OFFER_COLLECTION',
}

export enum ECmsBannerLinkObjectType {
  Partner = 'PARTNER',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
  CorpOfferCategory = 'CORP_OFFER_CATEGORY',
  TradeOfferCategory = 'TRADE_OFFER_CATEGORY',
  Collection = 'COLLECTION',
  Link = 'LINK',
}

export enum EFavoriteProcessAction {
  Add = 'add',
  Remove = 'remove',
}

export enum ECmsCollectionLinkObjectType {
  Partner = 'PARTNER',
  TradeOffer = 'TRADE_OFFER',
  CorpOffer = 'CORP_OFFER',
}

export enum EOfferListType {
  Common = 'common',
  Upcoming = 'upcoming',
}

export enum PartnerStatus {
  Unverified = 'Unverified',
  ToVerification = 'ToVerification',
  OnVerification = 'OnVerification',
  Rejected = 'Rejected',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
  Deleted = 'Deleted',
}

export enum EPaymentType {
  Cash = 'CASH',
  Card = 'CARD',
  Invoice = 'INVOICE',
  CashOrCard = 'CASH_OR_CARD',
  Other = 'OTHER',
}

export enum EDeliveryType {
  Self = 'SELF',
  Delivery = 'DELIVERY',
  SelfOrDelivery = 'SELF_OR_DELIVERY',
  Other = 'OTHER',
}

// Адресный уровень элемента в виде номера (уровень в ГАР)
export enum EAddressLevel {
  // Субъект РФ
  Region = 1,
  // Административный район
  AdministrativeRegion = 2,
  // Муниципальное образование (в т.ч. городской округ)
  Municipality = 3,
  // Сельское/городское поселение (по сути это некое образование, которое может включать несколько населенных пунктов)
  Area = 4,
  // Город
  City = 5,
  // Населенный пункт
  Settlement = 6,
  // Элемент планировочной структуры - особый вариант
  PlanningStructureVariant = 65,
  // Элемент планировочной структуры (микрорайоны, снт, днт и т.д.)
  PlanningStructure = 7,
  // Элемент улично-дорожной сети (улица)
  Street = 8,
  // Земельный участок
  Stead = 9,
  // Здание/дом
  House = 10,
  // Помещение/квартира
  Apartment = 11,
  // Помещения в пределах помещения
  SubApartment = 12,
  // Машино-место
  Parking = 17,
}

// Адресные опции для отображения
export enum EAddressOption {
  // Почтовый индекс
  PostalCode,
}

/** Способ заказа услуги по датам */
export enum EOrderByDateType {
  // Можно выбрать период
  Period = 'PERIOD',
  // Можно выбрать по дням
  Days = 'DAYS',
  // Нельзя выбрать даты
  None = 'NONE',
}

/** Дискриминатор для типов офферов */
export enum EOfferShortDiscriminator {
  Trade = 'CustomerTradeOfferShort',
  Corp = 'CustomerCorpOfferShort',
  Booking = 'CustomerBookingOfferShort',
}

export enum EOrderShortDiscriminator {
  ProductOrderShort = 'ProductOrderShort',
  BookingOrderShort = 'BookingOrderShort',
}

export enum EOrderRequestDiscriminator {
  BookingOrderRequest = 'BookingOrderRequest',
}

export enum EOrderDiscriminator {
  ProductOrder = 'ProductOrder',
  BookingOrder = 'BookingOrder',
}

export enum EReasonInitiator {
  Customer = 'customer',
  Partner = 'partner',
}

export enum EActivationDiscriminator {
  PersonalPromotionActivation = 'PersonalPromotionActivation',
  PublicActivation = 'PublicActivation',
}
