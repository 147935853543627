import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import { ContentWrapper } from './controls';
import useGridSpacing from 'presentation/theme/hooks/useGridSpacing';
import { PartnerDeskDetailsLayoutType } from './types';

export const PartnerDeskDetailsLayout: PartnerDeskDetailsLayoutType = props => {
  const { header, tabs, toolbar: Toolbar, loader, children } = props;

  const { x2, x3, x4 } = useGridSpacing();

  return (
    <DefaultContentWrapper>
      <ContentWrapper>
        {header}
        <Splitter size={x2} />
        {tabs}
        <Splitter size={x4} />
        {Toolbar && <Toolbar />}
        <Splitter size={x3} />
        {children}
        {loader}
      </ContentWrapper>
    </DefaultContentWrapper>
  );
};

