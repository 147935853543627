import ErrorHandler from 'data/network/errorHandler';
import { useCallback, useEffect } from 'react';
import useUserBindCorpRole from './useUserBindCorpRole';

type UseUserCorpConfirmationProps = {
  readonly refreshToken: () => Promise<void>;
  readonly userRefetch: () => void;
  readonly bindRzhdProfile: () => void;
};

type UseUserCorpConfirmation = {
  readonly errorCorpConfirmation: boolean;
};

const useUserCorpConfirmation: (props: UseUserCorpConfirmationProps) => UseUserCorpConfirmation = props => {
  const { refreshToken, bindRzhdProfile, userRefetch } = props;

  const refreshTokenInternal = useCallback(() => {
    return refreshToken().then(() => {
      userRefetch();
    });
  }, [userRefetch, refreshToken]);

  const { error } = useUserBindCorpRole({
    refreshToken: refreshTokenInternal,
    bindRzhdProfile,
  });

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  return {
    errorCorpConfirmation: error,
  };
};

export default useUserCorpConfirmation;
