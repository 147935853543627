import { EUserGender } from 'domain/model/enums';
import { MPTab } from 'presentation/theme/ui-kit/tab';
import { FC } from 'react';
import { GenderTabs } from './controls';

type UserGenderSelectorProps = {
  readonly value: Nullable<EUserGender>;
  readonly disabled?: boolean;
  readonly onChange: (value: EUserGender) => void;
};

export const UserGenderSelector: FC<UserGenderSelectorProps> = props => {
  const { value, disabled, onChange } = props;

  return (
    <GenderTabs
      value={value}
      onChange={(event, value) => onChange(value)}
    >
      <MPTab
        label='М'
        value={EUserGender.Male}
        disabled={disabled}
      />
      <MPTab
        label='Ж'
        value={EUserGender.Female}
        disabled={disabled}
      />
    </GenderTabs>
  );
};
