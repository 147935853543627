import { Dialog, Typography, useMediaQuery } from '@mui/material';
import BackButton from 'presentation/components/common/buttons/back';
import { CloseIcon } from 'presentation/media/icons';
import AppSvgIcon from '../../../components/common/icon';
import { useHeaderParams } from '../../../hooks/useHeaderParams';
import { BackButtonWrapper, CloseButton, ContentWrapper, Drawer, DrawerContainer } from './controls';

export type WrapperModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
};

export const ModalContentWrapper: React.FCC<WrapperModalProps> = ({ children, isOpen, onClose }) => {
  const isSmDown = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const { headerHeight } = useHeaderParams();
  return isSmDown ? (
    <Drawer
      variant='temporary'
      elevation={0}
      disableAutoFocus
      disableEnforceFocus
      anchor='right'
      open={isOpen}
      offsetTop={headerHeight}
      onClose={onClose}
      onOpen={() => null}
    >
      <DrawerContainer>
        <BackButtonWrapper>
          <BackButton onClick={onClose}>Отменить заказ?</BackButton>
        </BackButtonWrapper>
        {children}
      </DrawerContainer>
    </Drawer>
  ) : (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <ContentWrapper>
        <Typography variant='h2'>Отменить заказ?</Typography>
        <CloseButton onClick={onClose}>
          <AppSvgIcon icon={CloseIcon} />
        </CloseButton>

        {children}
      </ContentWrapper>
    </Dialog>
  );
};
