import { Logout } from '@mp-npm/mp-auth-client';
import { UserAccessMatrix } from 'domain/model/security';
import { User } from 'domain/model/user';
import React from 'react';

export type CurrentUserContextType = {
  readonly user: User;
  readonly accessMatrix: UserAccessMatrix;

  readonly defaultRoute: string;

  readonly logOut: Logout;
  readonly refreshToken: () => Promise<void>;
  readonly bindRzhdProfile: () => void;
  readonly resetPassword: () => void;
};

const CurrentUserContext = React.createContext<CurrentUserContextType>({} as CurrentUserContextType);

export default CurrentUserContext;
