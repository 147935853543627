import SmartPhoneFixedBar from 'presentation/components/common/smartPhoneFixedBar';
import { IconButton } from '../../../../components/common/buttons/favorite/controls';
import AppSvgIcon from '../../../../components/common/icon';
import { IconFavoriteFilled } from '../../../../media/icons';

type OfferSmartPhoneFixedBarProps = {
  readonly isFavorite?: boolean;
  readonly text: string;
  readonly onFavoriteClick?: () => void;
  readonly onBack: () => void;
};

const OfferSmartPhoneFixedBar = (props: OfferSmartPhoneFixedBarProps) => {
  const { text, onFavoriteClick, isFavorite, onBack } = props;

  return (
    <SmartPhoneFixedBar
      onBack={onBack}
      text={text}
      favorite={
        onFavoriteClick ? (
          <IconButton
            selected={isFavorite}
            onClick={onFavoriteClick}
          >
            <AppSvgIcon icon={IconFavoriteFilled} />
          </IconButton>
        ) : undefined
      }
    />
  );
};

export default OfferSmartPhoneFixedBar;
