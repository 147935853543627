import { useMediaQuery } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { headerSetVisible } from '../../../../../header/store/slice';
import OfferSmartPhoneFixedBar from '../../../../components/smartPhoneFixedBar';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useCorpOfferDetailsData from '../../hooks/useCorpOfferDetailsData';

type SmartPhoneFixedBarAdapterProps = {
  readonly id: UUID;
  readonly isFavorite: boolean;
};

const SmartPhoneFixedBarAdapter = ({ id, isFavorite }: SmartPhoneFixedBarAdapterProps) => {
  const dispatch = useDispatch();
  const isMdDown = useMediaQuery(theme => theme.breakpoints.down('md'));

  const { onBack, onAddToFavorite } = useContextHandlers();

  const { corpOffer } = useCorpOfferDetailsData(id);

  useEffect(() => {
    if (isMdDown) {
      dispatch(headerSetVisible(false));
    } else {
      dispatch(headerSetVisible(true));
    }

    return () => {
      dispatch(headerSetVisible(true));
    };
  }, [dispatch, isMdDown]);

  if (!corpOffer) {
    return null;
  }

  return (
    <OfferSmartPhoneFixedBar
      onBack={onBack}
      isFavorite={isFavorite}
      onFavoriteClick={onAddToFavorite}
      text={corpOffer.name}
    />
  );
};

export default SmartPhoneFixedBarAdapter;
