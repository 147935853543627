import {
  UserProfileActionsAdapter,
  UserProfileBehaviorAdapter,
  UserProfileCommonAdapter,
  UserProfileCorpLinkedAdapter,
  UserProfileEmailAdapter,
  UserProfileLoaderAdapter,
  UserProfileLocalityAdapter,
  UserProfilePasswordAdapter,
  UserProfilePhoneAdapter,
} from './adapters';
import UserProfileLayout from './layout';

const Layout = UserProfileLayout;

const UserProfileContainer = () => {
  return (
    <>
      <UserProfileBehaviorAdapter />
      <Layout
        title='Настройки'
        subTitle='Профиль'
        loader={<UserProfileLoaderAdapter />}
        corpLinked={<UserProfileCorpLinkedAdapter />}
        actions={<UserProfileActionsAdapter />}
        /*notifications={<UserProfileNotificationsAdapter />} временно отключено в рамках редизайна*/
      >
        <UserProfileCommonAdapter />
        <UserProfilePhoneAdapter />
        <UserProfileEmailAdapter />
        <UserProfileLocalityAdapter />
        <UserProfilePasswordAdapter />
      </Layout>
    </>
  );
};

export default UserProfileContainer;
