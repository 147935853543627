import { Typography } from '@mui/material';
import { ButtonLink } from 'presentation/components/common/buttons/link';
import ContentLoader from 'presentation/components/common/loader';
import AppSvgIcon from '../../../../components/common/icon';

import { IconFavoriteFilled } from '../../../../media/icons';
import { ButtonLinkWrapper } from './controls';

type OfferDetailsFavoriteOnlyProps = {
  readonly isFavorite: boolean;
  readonly favoritesAllowed: boolean;
  readonly isAddToFavoriteFetching: boolean;
  readonly onAddToFavorite: () => void;
};

const OfferDetailsFavoriteOnly = (props: OfferDetailsFavoriteOnlyProps) => {
  const { isFavorite, favoritesAllowed, isAddToFavoriteFetching, onAddToFavorite } = props;

  return (
    <>
      {favoritesAllowed && (
        <ButtonLinkWrapper>
          <ButtonLink
            startIcon={
              <AppSvgIcon
                color={isFavorite ? 'primary' : 'secondary'}
                icon={IconFavoriteFilled}
              />
            }
            onClick={onAddToFavorite}
            disabled={isAddToFavoriteFetching}
          >
            <Typography color='textPrimary'>{isFavorite ? 'В избранном' : 'В избранное'}</Typography>
            {isAddToFavoriteFetching && <ContentLoader size={20} />}
          </ButtonLink>
        </ButtonLinkWrapper>
      )}
    </>
  );
};

export default OfferDetailsFavoriteOnly;
