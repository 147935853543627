import { Link as MuiLink } from '@mui/material';
import { Location } from 'history';
import { Link as RouterLink } from 'react-router-dom';

export type OrderProductInfoProps = {
  readonly partnerLink: Location | string;
};

const OrderProductInfo = ({ partnerLink }: OrderProductInfoProps) => {
  return (
    <div>
      По всем вопросам, связанным с заказом обращайтесь по указанным{' '}
      <MuiLink
        color='primary'
        underline='hover'
        component={RouterLink}
        to={partnerLink}
      >
        контактам партнера
      </MuiLink>
    </div>
  );
};

export default OrderProductInfo;
