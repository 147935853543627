import { Fade } from '@mui/material';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import { ContentWrapper, DataWrapper } from './controls';
import { ProductOfferListLayoutType } from './types';

const ProductOfferListLayout: ProductOfferListLayoutType = props => {
  const { search, banners, breadcrumbs, aside, header, children, stub, loader } = props;

  return (
    <>
      {search && <ScreenNavWrapper>{search}</ScreenNavWrapper>}
      {banners}
      <Fade in>
        <DefaultContentWrapper>
          {breadcrumbs}
          {header}
          <ContentWrapper>
            {aside}
            <DataWrapper>{children}</DataWrapper>
          </ContentWrapper>
          {stub}
          {loader}
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default ProductOfferListLayout;
