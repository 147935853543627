import AboutPublicOfferScreen from 'presentation/screen/about/publicOffer';
import AboutTradingRulesScreen from 'presentation/screen/about/tradingPlatformRules';
import { Redirect, Route, Switch } from 'react-router-dom';
import rootRouting from '../../../routing';

const root = rootRouting.about;

const routing = {
  root,
  customers: `${root}/customers`,
  company: `${root}/company`,
  publicOffer: `${root}/public-offer`,
  tradingRules: `${root}/rules`,
};

export const getAboutRootRoute = () => routing.root;

export const getAboutTradingRulesRoute = () => routing.tradingRules;

const AboutEntry = () => {
  return (
    <Switch>
      <Route
        exact
        path={routing.publicOffer}
        component={AboutPublicOfferScreen}
      />
      <Route
        exact
        path={routing.tradingRules}
        component={AboutTradingRulesScreen}
      />
      <Redirect
        exact
        path={routing.root}
        to={routing.tradingRules}
      />
      <Redirect to={rootRouting.root} />
    </Switch>
  );
};

export default AboutEntry;
