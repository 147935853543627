import AboutTextSectionsContainer from '../textSections/container';
import AboutTradingRulesCommonInfo from './info/common';
import AboutTradingRulesForceMajorInfo from './info/forceMajor';
import AboutTradingRulesOrderingInfo from './info/ordering';
import AboutTradingRulesPaymentAndPriceInfo from './info/paymentPrice';
import AboutTradingRulesRegisterInfo from './info/register';
import AboutRightsAndObligationsInfo from './info/rightObligations';
import AboutTradingRulesTermsInfo from './info/terms';

enum ESection {
  Terms = 'terms',
  Common = 'common',
  SiteRegistration = 'siteRegistration',
  Ordering = 'ordering',
  PriceAndPayment = 'priceAndPayment',
  RightsAndObligations = 'rightsAndObligations',
  ForceMajor = 'forceMajor',
}

const AboutTradingRulesContainer = () => {
  return (
    <AboutTextSectionsContainer
      title='Правила пользования торговой площадкой'
      sections={[
        {
          id: ESection.Terms,
          name: 'Термины и определения',
          component: AboutTradingRulesTermsInfo,
        },
        {
          id: ESection.Common,
          name: 'Общие положения',
          component: AboutTradingRulesCommonInfo,
        },
        {
          id: ESection.SiteRegistration,
          name: 'Регистрация на сайте',
          component: AboutTradingRulesRegisterInfo,
        },
        {
          id: ESection.Ordering,
          name: 'Оформление заказа на Торговой площадке',
          component: AboutTradingRulesOrderingInfo,
        },
        {
          id: ESection.PriceAndPayment,
          name: 'Цена и оплата',
          component: AboutTradingRulesPaymentAndPriceInfo,
        },
        {
          id: ESection.RightsAndObligations,
          name: 'Права и обязанности сторон',
          component: AboutRightsAndObligationsInfo,
        },
        {
          id: ESection.ForceMajor,
          name: 'Форс-мажор',
          component: AboutTradingRulesForceMajorInfo,
        },
      ]}
    />
  );
};

export default AboutTradingRulesContainer;
