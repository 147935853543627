import AboutTradingRulesContainer from 'presentation/features/about/tradingPlatformRules/container';
import ScreenLayout from 'presentation/layouts/screen';

const AboutTradingRulesScreen = () => {
  return (
    <ScreenLayout footer>
      <AboutTradingRulesContainer />
    </ScreenLayout>
  );
};

export default AboutTradingRulesScreen;
