import { PartnerOfferListRequest, useGetPartnerTradeOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferListType, EOfferType, ETradeOfferSortType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import TradeOfferListItem from 'presentation/features/offer/trade/components/listItem';
import { partnerDeskDetailsTradeOffersIsNewFetchingSelector } from 'presentation/features/partnerDesk/details/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';

type PartnerDeskTradeOffersDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<ETradeOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (tradeOffer: TradeOfferShort) => void;
};

const PartnerDeskTradeOffersDataContainer = (props: PartnerDeskTradeOffersDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { accessMatrix } = useCurrentUser();
  const isNewFetching = useSelector(partnerDeskDetailsTradeOffersIsNewFetchingSelector);
  const { page } = args;

  const { data, error, isSuccess, isFetching } = useGetPartnerTradeOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const tradeOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  // TODO использовать favorite в OfferListItem'ах вместо дублирования кода
  const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Trade,
      partnerId: args.partnerId,
    },
  });

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!tradeOffers && tradeOffers.length > 0;
  const isFavoritesAllowed = accessMatrix.tradeOffers.favorite;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {tradeOffers.map(tradeOffer => (
            <InView
              key={tradeOffer.id}
              active={isSuccess && !isFetching}
              sessionKey={sessionKey}
              data={tradeOffer}
              onShow={onShowCard}
            >
              <TradeOfferListItem
                offer={tradeOffer}
                favoriteProps={
                  !isFavoritesAllowed
                    ? null
                    : {
                        active: isFavorite(EOfferType.Trade, tradeOffer),
                        inProgress: isAddToFavoritesFetching(tradeOffer.id),
                        onClick: () => addOrRemoveFavorite(EOfferType.Trade, tradeOffer),
                      }
                }
              />
            </InView>
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskTradeOffersDataContainer;
