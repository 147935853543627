import { ReactNode } from 'react';
import BackgroundContainer from '../backgroundContainer';
import {
  ContentBarGrid,
  ContentBarGridArea,
  InnerLayoutGrid,
  InnerLayoutGridArea,
  ParentLayoutGrid,
  ParentLayoutGridArea,
  SidebarGrid,
  SidebarGridArea,
} from './controls';
import { EContentbarGridAreas, EInnerGridAreas, EParentGridAreas, ESidebarGridAreas, FixedPositions } from './types';

type DetailLayoutProps = {
  /** Фиксированный блок для мобилок внизу экрана */
  readonly smartphoneFixedBar?: ReactNode;
  readonly topper?: ReactNode;
  readonly breadcrumb?: ReactNode;
  readonly title?: ReactNode;
  readonly favorite?: ReactNode;
  readonly description?: ReactNode;
  /** Переопределение на фиксированную ширину блока description */
  readonly descriptionFixedWidth?: string;
  readonly images?: ReactNode;
  readonly sidebar?: ReactNode;
  readonly partnerInfo?: ReactNode;
  readonly content?: ReactNode;
  readonly close?: ReactNode;
  /** Фиксированные по позициям (неперемещаемые при изменении разрешения) блоки шаблона */
  readonly fixedPositionsAreas?: FixedPositions;
};

const DetailLayout = (props: DetailLayoutProps) => {
  const {
    sidebar,
    images,
    description,
    descriptionFixedWidth,
    partnerInfo,
    smartphoneFixedBar,
    content,
    title,
    topper,
    breadcrumb,
    close,
    favorite,
    fixedPositionsAreas,
  } = props;

  return (
    <ParentLayoutGrid>
      <ParentLayoutGridArea area={EParentGridAreas.Bar}>{topper}</ParentLayoutGridArea>
      <ParentLayoutGridArea area={EParentGridAreas.Inner}>
        <BackgroundContainer close={close}>
          <InnerLayoutGrid>
            <InnerLayoutGridArea area={EInnerGridAreas.SmartphoneFixedBar}>{smartphoneFixedBar}</InnerLayoutGridArea>
            <InnerLayoutGridArea area={EInnerGridAreas.Breadcrumbs}>{breadcrumb}</InnerLayoutGridArea>
            <InnerLayoutGridArea area={EInnerGridAreas.Topbar}>{title}</InnerLayoutGridArea>
            <InnerLayoutGridArea area={EInnerGridAreas.Favorite}>{favorite}</InnerLayoutGridArea>
            <InnerLayoutGridArea area={EInnerGridAreas.Contentbar}>
              <ContentBarGrid
                fixedPositionsAreas={fixedPositionsAreas}
                descriptionFixedWidth={descriptionFixedWidth}
              >
                <ContentBarGridArea area={EContentbarGridAreas.Breadcrumbs}>{breadcrumb}</ContentBarGridArea>
                {!fixedPositionsAreas?.includes(EContentbarGridAreas.SmartphoneTitle) && (
                  <ContentBarGridArea area={EContentbarGridAreas.SmartphoneTitle}>{title}</ContentBarGridArea>
                )}
                <ContentBarGridArea area={EContentbarGridAreas.Image}>{images}</ContentBarGridArea>
                <ContentBarGridArea area={EContentbarGridAreas.Description}>{description}</ContentBarGridArea>
                <ContentBarGridArea area={EContentbarGridAreas.LargeDesktopTabs}>{content}</ContentBarGridArea>
              </ContentBarGrid>
            </InnerLayoutGridArea>
            <InnerLayoutGridArea area={EInnerGridAreas.Sidebar}>
              <SidebarGrid>
                <SidebarGridArea area={ESidebarGridAreas.Actions}>{sidebar}</SidebarGridArea>
                <SidebarGridArea area={ESidebarGridAreas.PartnerInfo}>{partnerInfo}</SidebarGridArea>
              </SidebarGrid>
            </InnerLayoutGridArea>
            {!fixedPositionsAreas?.includes(EInnerGridAreas.Bottombar) && (
              <InnerLayoutGridArea area={EInnerGridAreas.Bottombar}>{content}</InnerLayoutGridArea>
            )}
          </InnerLayoutGrid>
        </BackgroundContainer>
      </ParentLayoutGridArea>
    </ParentLayoutGrid>
  );
};

export default DetailLayout;
