import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import OfferListLayout from 'presentation/layouts/offerList';
import CommonRenderManager from '../../../../components/common/renderManager/container';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';
import CorpOfferBonusUnavailableAdapter from '../components/bonusUnavailableStub';
import {
  CorpOfferListAsideAdapter,
  CorpOfferListBannersAdapter,
  CorpOfferListDataCommonAdapter,
  CorpOfferListDataUpcomingAdapter,
  CorpOfferListLoaderAdapter,
  CorpOfferListSortSelectAdapter,
  CorpOfferListStubAdapter,
  CorpOffersHeaderAdapter,
} from './adapters';
import { CorpOfferListContext } from './context';
import useCorpOfferListHandlers from './hooks/useCorpOfferList';

const Layout = OfferListLayout;
const Header = <CorpOffersHeaderAdapter />;
const Aside = <CorpOfferListAsideAdapter />;
const Stub = <CorpOfferListStubAdapter />;
const SortSelect = <CorpOfferListSortSelectAdapter />;

type CorpOfferListContainerProps = {
  readonly guid: UUID;
  readonly name: Nullable<string>;
  readonly categoryId: Nullable<UUID>;
  readonly partnerId: Nullable<UUID>;
};

const CorpOfferListContainer = (props: CorpOfferListContainerProps) => {
  const { guid, name, categoryId, partnerId } = props;

  const handlers = useCorpOfferListHandlers({ guid, name, categoryId, partnerId });

  useVisibleSectionLinks();

  return (
    <CorpOfferListContext.Provider value={handlers}>
      <RenderDecoratorContainer
        decorators={[
          <CommonRenderManager
            key='loader'
            loader={<CorpOfferListLoaderAdapter />}
            stub={CorpOfferListStubAdapter}
          />,
          <CorpOfferBonusUnavailableAdapter key='bonusUnavailable' />,
        ]}
      >
        <Layout
          banners={<CorpOfferListBannersAdapter guid={guid} />}
          header={Header}
          aside={Aside}
          controls={SortSelect}
          stub={Stub}
        >
          <CorpOfferListDataCommonAdapter guid={guid} />
          <CorpOfferListDataUpcomingAdapter guid={guid} />
        </Layout>
      </RenderDecoratorContainer>
    </CorpOfferListContext.Provider>
  );
};

export default CorpOfferListContainer;
