import { EOfferType } from 'domain/model/enums';
import OfferDetailsFavoriteOnly from 'presentation/features/offer/components/detailsOnlyFavorite';
import useOfferFavorite from 'presentation/features/offer/hooks/useOfferFavorite';
import useProductOfferDetailsData from 'presentation/features/offer/product/details/hooks/useProductOfferDetailsData';

type ProductOfferDetailsFavoriteOnlyAdapterProps = {
  readonly id: UUID;
};

const ProductOfferDetailsFavoriteOnlyAdapter = ({ id }: ProductOfferDetailsFavoriteOnlyAdapterProps) => {
  const { productOffer } = useProductOfferDetailsData(id);

  const {
    add: addFavorite,
    remove: removeFavorite,
    isFetching: isFavoriteFetching,
    isFavorite: checkFavorite,
  } = useOfferFavorite({
    guid: id,
    payload: {
      id,
      offerType: EOfferType.Product,
      partnerId: productOffer?.partner?.id,
    },
  });

  const isFavorite = checkFavorite(EOfferType.Product, id);

  const onAddToFavorite = () => {
    if (!productOffer) {
      return;
    }

    if (isFavorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  };

  return (
    <OfferDetailsFavoriteOnly
      favoritesAllowed
      isFavorite={isFavorite}
      isAddToFavoriteFetching={isFavoriteFetching}
      onAddToFavorite={onAddToFavorite}
    />
  );
};

export default ProductOfferDetailsFavoriteOnlyAdapter;
