import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { ECmsContainerStatus } from 'domain/model/enums';
import { DataFilterQueryDslOperator } from 'domain/model/filter';
import { PartnerDesk, PartnerNameMap, PartnerWindow } from 'domain/model/partner';
import { createCancelToken } from './index';
import { ApiCancellable, ApiRequestPageable } from './types';
import { appendQueryDslParams, getUserQueryParams } from './utils';

type DeskOneProps = ApiCancellable & {
  readonly id: UUID;
};

type PageOneProps = ApiCancellable & {
  readonly partnerId: UUID;
};

type PageTestOneProps = ApiCancellable & {
  readonly partnerId: UUID;
};

type DeskAllProps = ApiCancellable &
  ApiRequestPageable & {
    readonly query?: Nullable<string>;
    readonly name?: Nullable<string[]>;
  };

type DeskCollectionProps = ApiCancellable & {
  readonly id: UUID;
};

type PartnerApi = {
  readonly desk: {
    readonly all: (props: DeskAllProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerDesk[]>>;
    readonly collection: (props: DeskCollectionProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerDesk[]>>;
    readonly one: (props: DeskOneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerDesk>>;
  };
  readonly page: (props: PageOneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerWindow>>;
  readonly nameMap: () => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerNameMap>>;
  readonly pageTest: (props: PageTestOneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, PartnerWindow>>;
};

/**
 * АПИ по работе с компаниями
 */
const partner: PartnerApi = {
  desk: {
    one: ({ id }) => {
      return { url: `/partner-desks/${id}`, method: 'GET' };
    },
    all: props => {
      const { name, signal, ...queryParams } = props;

      const params = getUserQueryParams(queryParams);

      if (name?.length) {
        name.forEach(n => {
          params.append('partnerName', n);
        });
      }

      return {
        url: `/customers/current/partner-desks`,
        method: 'GET',
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      };
    },
    collection: props => {
      const { id, signal } = props;
      return {
        url: `/customers/current/partner-desks/collections/${id}`,
        method: 'GET',
        cancelToken: signal && createCancelToken(axios, signal),
      };
    },
  },
  page: ({ partnerId }) => {
    const params = new URLSearchParams();
    params.append('partnerId', partnerId);
    return { url: `/customers/current/containers`, method: 'GET', params };
  },
  nameMap: () => {
    return { url: `/customers/current/partner-desks/name-map`, method: 'GET' };
  },
  pageTest: ({ partnerId }) => {
    const params = new URLSearchParams();
    appendQueryDslParams(params, [
      {
        field: 'status',
        operator: DataFilterQueryDslOperator.In,
        value: [ECmsContainerStatus.Active],
      },
    ]);

    params.append('partnerId', partnerId);
    return { url: `/customers/current/containers`, method: 'GET', params };
  },
};

export default partner;
