import { useAuthentication } from '@mp-npm/mp-auth-client';
import { SecurityToken } from 'domain/model/user';
import { ExternalTheme } from 'presentation/theme';
import { AppConfigurator } from 'system/appConfigurator';

const useUserExternalTheme = (): Nullable<ExternalTheme> => {
  const { token } = useAuthentication<SecurityToken>();
  return token?.groups ? AppConfigurator.getInstance().getExternalTheme(token.groups) : null;
};

export default useUserExternalTheme;
