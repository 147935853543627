import { Text } from '../../components/controls';

const AboutTradingRulesForceMajorInfo = () => {
  return (
    <>
      <Text>
        1. «Витрины» освобождается от ответственности за полное или частичное неисполнение своих обязательств в
        соответствии с настоящими Правилами, если это неисполнение было вызвано обстоятельствами непреодолимой силы,
        возникшими после принятия Покупателем условий настоящих Правил. «Обстоятельства Непреодолимой Силы», означают
        чрезвычайные события или обстоятельства, которые нельзя было предвидеть или предотвратить доступными средствами.
        Такие чрезвычайные события или обстоятельства включают в себя, в частности: забастовки, наводнения, пожары,
        землетрясения и иные стихийные бедствия, войны, военные действия, действия российских или иностранных
        государственных органов, а также любые иные обстоятельства, выходящие за пределы разумного контроля.
      </Text>
      <Text></Text>
    </>
  );
};

export default AboutTradingRulesForceMajorInfo;
