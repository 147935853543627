import { UUID } from '../../../../../../../domain/model/types';
import { ButtonLink } from '../../../../../../components/common/buttons/link';
import AppSvgIcon from '../../../../../../components/common/icon';
import ContentLoader from '../../../../../../components/common/loader';
import { IconFavoriteFilled } from '../../../../../../media/icons';
import { useContextHandlers } from '../../hooks/useContextHandlers';
import useCorpOfferDetailsData from '../../hooks/useCorpOfferDetailsData';
import { FavoriteText, Wrapper } from './controls';

type CorpOfferDetailsFavoriteAdapterProps = {
  readonly id: UUID;
  readonly guid: UUID;
};

// TODO заменить на OfferDetailsFavoriteOnly

const CorpOfferDetailsFavoriteAdapter = ({ id }: CorpOfferDetailsFavoriteAdapterProps) => {
  const { corpOffer } = useCorpOfferDetailsData(id);
  const { onAddToFavorite, isFavorite, isFavoriteProcessing } = useContextHandlers();

  if (!corpOffer) {
    return null;
  }

  return (
    <Wrapper>
      <ButtonLink
        startIcon={
          <AppSvgIcon
            color={isFavorite ? 'brand' : 'secondary'}
            icon={IconFavoriteFilled}
          />
        }
        onClick={onAddToFavorite}
        disabled={isFavoriteProcessing}
      >
        <FavoriteText
          variant='body2'
          color='primary'
        >
          {isFavorite ? 'В избранном' : 'В избранное'}
        </FavoriteText>
        {isFavoriteProcessing && <ContentLoader size={20} />}
      </ButtonLink>
    </Wrapper>
  );
};

export default CorpOfferDetailsFavoriteAdapter;
