import { Link as MUILink, Typography, useMediaQuery } from '@mui/material';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import TradeOfferListItemInfo from 'presentation/features/offer/trade/components/listItem/info';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import CardFavoriteButton, {
  CardFavoriteButtonProps,
} from '../../../../../components/common/buttons/cardFavorite/button';
import { getTradeOfferDetailsRoute } from '../../entry';
import { MPChip } from '../../../../../theme/ui-kit/chip';

export type TradeOfferListItemProps = {
  readonly offer: Pick<TradeOfferShort, 'id' | 'name' | 'title' | 'images' | 'partner'>;
  readonly direction?: OfferListItemProps['direction'];
  readonly favoriteProps?: Nullable<CardFavoriteButtonProps> | false;
};

const TradeOfferListItem = forwardRef<any, TradeOfferListItemProps>((props, ref) => {
  const { offer, direction, favoriteProps } = props;
  const { id, images, name } = offer;
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getTradeOfferDetailsRoute({ id })}
    >
      <OfferListItem
        images={images}
        description={name}
        header={
          <MPChip
            size='micro'
            label={<Typography variant={isSmUp ? 'body2' : 'caption'}>{offer.title}</Typography>}
            color='warning'
          />
        }
        favorite={favoriteProps && <CardFavoriteButton {...favoriteProps} />}
        info={<TradeOfferListItemInfo {...offer} />}
        direction={direction}
      />
    </MUILink>
  );
});

TradeOfferListItem.displayName = 'TradeOfferListItem';

export default TradeOfferListItem;
