enum UserSettingsVersion {
  V1,
  V2,
}

type UserSettingsPropsV1 = {
  readonly city: Nullable<{
    readonly fiasId: UUID;
  }>;
};

type UserSettingsPropsV2 = {
  readonly city: Nullable<{
    readonly fiasId: UUID;
    readonly name: string;
  }>;
  readonly cookieAccepted: boolean;
};
type UserSettingsV2 = { readonly version: UserSettingsVersion.V2 } & UserSettingsPropsV2;

export type UserSettingsVariant = UserSettingsV2;
export type UserSettings = UserSettingsPropsV2;
const currentVersion = UserSettingsVersion.V2;

export class UserLocalSettings {
  private static instance: UserLocalSettings;

  public static getInstance(userId: UUID): UserLocalSettings {
    if (!UserLocalSettings.instance) {
      UserLocalSettings.instance = new UserLocalSettings();
    }
    UserLocalSettings.instance.init(userId);

    return UserLocalSettings.instance;
  }

  private constructor() {}

  private storage: Storage = window.localStorage;
  private userId: Nullable<UUID> = null;
  private settings: Nullable<UserSettings> = null;

  getSavedVersion(): UserSettingsVersion {
    return this.getStorageValue()?.version ?? currentVersion;
  }

  private getStorageValue(): Nullable<UserSettingsVariant> {
    const key = this.getStorageKey();
    const item = this.storage.getItem(key);
    if (!item) {
      return null;
    }

    return JSON.parse(item) as UserSettingsVariant;
  }

  private setStorageValue(settings: UserSettings) {
    const key = this.getStorageKey();
    this.storage.setItem(key, JSON.stringify({ ...settings, version: currentVersion }));
  }

  getStorageKey() {
    return `MP_USER_SETTINGS_${this.userId}`;
  }

  setSettings(settings: UserSettings) {
    this.setStorageValue(settings);
    this.settings = {
      ...settings,
    };
  }

  getSettings(): UserSettings {
    return (
      this.settings ?? {
        city: null,
        cookieAccepted: false,
      }
    );
  }

  static getSettingsDefault(): UserSettings {
    return {
      city: null,
      cookieAccepted: false,
    };
  }

  private init(userId: UUID) {
    this.userId = userId;
    const savedVersion = this.getSavedVersion();

    if (currentVersion > savedVersion) {
      /**
       * тут должны быть миграции версий
       * например появилась V3
       * в этом методе нужно прописать переходы V1->V3 и V2->V3
       */
      if (savedVersion === UserSettingsVersion.V1 && currentVersion === UserSettingsVersion.V2) {
        const savedSettings = this.getStorageValue() as UserSettingsPropsV1;
        const convertedSettings: UserSettings = {
          ...savedSettings,
          city: null,
          cookieAccepted: false,
        };
        this.setStorageValue(convertedSettings);
      }
    }

    this.settings = this.getStorageValue() || UserLocalSettings.getSettingsDefault();
  }
}
