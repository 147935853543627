import { FCC } from 'react';
import { Wrapper, WrapperProps } from './controls';

export const PartnerGrid: FCC<WrapperProps> = props => {
  const { columnsCount = 5, mdColumnsCount = 3, smColumnsCount = 2, xsColumnsCount = 2, children } = props;

  return (
    <Wrapper
      columnsCount={columnsCount}
      mdColumnsCount={mdColumnsCount}
      smColumnsCount={smColumnsCount}
      xsColumnsCount={xsColumnsCount}
    >
      {children}
    </Wrapper>
  );
};
