import { useGetUserActivationsCountQuery } from 'data/api/user';
import useCurrentUser from '../../../hooks/useCurrentUser';

const useActivationsCounter = () => {
  const {
    user: { id: userId },
  } = useCurrentUser();
  const { data } = useGetUserActivationsCountQuery({ userId });

  return data ?? 0;
};

export default useActivationsCounter;
