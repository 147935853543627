import { useMediaQuery } from '@mui/material';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import BackButton from 'presentation/components/common/buttons/back';
import { FCC } from 'react';
import { BackButtonWrapper, Container, Drawer, Wrapper } from './controls';

type UserOrderContainerWrapperProps = {
  readonly onClose: () => void;
};

const UserOrderContainerWrapper: FCC<UserOrderContainerWrapperProps> = props => {
  const { children, onClose } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  if (isMdUp) {
    return !!children && <Wrapper>{children}</Wrapper>;
  } else {
    return (
      <Drawer
        variant='temporary'
        elevation={0}
        disableAutoFocus
        disableEnforceFocus
        anchor='right'
        open={!!children}
        onClose={onClose}
        onOpen={() => null}
      >
        <Container disableGutters>
          <BackButtonWrapper>
            <BackButton onClick={onClose}>К заказам</BackButton>
          </BackButtonWrapper>
          {children}
        </Container>
      </Drawer>
    );
  }
};

export default UserOrderContainerWrapper;
