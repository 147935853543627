import { useAuthentication } from '@mp-npm/mp-auth-client';
import HttpClient from 'data/network/http';
import { SecurityToken } from 'domain/model/user';
import SentryClient from 'integration/sentry/client';
import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import AuthEntry from 'presentation/features/auth/entry';
import ErrorBoundary from 'presentation/features/general/errorBoundary';
import HistoryExtensionContainer from 'presentation/features/general/historyExtension/container';
import MetadataContainer from 'presentation/features/general/metadata/container';
import Notifier from 'presentation/features/general/notifier/container';
import LegalAgreementContainer from 'presentation/features/legal/agreement/container';
import CheckSignsContainer from 'presentation/features/legal/checkSigns/container';
import MainContainer from 'presentation/features/main/container';
import MainSecurity from 'presentation/features/main/security';
import { WebAnalyticsInitializer } from 'presentation/features/webAnalytics';
import useUserExternalTheme from 'presentation/hooks/useUserExternalTheme';
import { GlobalPreviewModeContainer } from 'presentation/screen/cms/globalPreviewMode/container';
import { createAppTheme, MPThemeProvider, theme } from 'presentation/theme';
import { FCC } from 'react';

const UserThemeProvider: FCC = ({ children }) => {
  const externalTheme = useUserExternalTheme();

  let appTheme = theme;
  if (externalTheme) {
    appTheme = createAppTheme(externalTheme);
  }

  return <MPThemeProvider theme={appTheme}>{children}</MPThemeProvider>;
};

const AppSecured = () => {
  const { initialized, authService, logOut } = useAuthentication<SecurityToken>();

  if (!initialized) {
    console.log('Wait auth initialized');
    return null;
  }

  HttpClient.getInstance().setAuthService(authService);

  return (
    <ErrorBoundary
      variant={SentryClient.getInstance().isActive() ? 'sentry' : 'common'}
      onLogout={authService.authenticated && logOut}
    >
      <UserThemeProvider>
        <WebAnalyticsInitializer />
        <GlobalPreviewModeContainer />
        <Notifier />
        <HistoryExtensionContainer />
        {authService.authenticated ? (
          <RenderDecoratorContainer
            decorators={[
              <LegalAgreementContainer key='legalAgreementContainer' />,
              <CheckSignsContainer key='checkSignsContainer' />,
            ]}
          >
            <MetadataContainer>
              <MainSecurity>
                <MainContainer />
              </MainSecurity>
            </MetadataContainer>
          </RenderDecoratorContainer>
        ) : (
          <AuthEntry />
        )}
      </UserThemeProvider>
    </ErrorBoundary>
  );
};

export default AppSecured;
