import { ESecuredPartition } from 'domain/model/enums';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import TradeOfferDetailsContainer from 'presentation/features/offer/trade/details/container';
import { ETradeOfferDetailsTab } from 'presentation/features/offer/trade/details/utils';
import { ETradeOfferUrlParam } from 'presentation/features/offer/trade/utils';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation, useParams } from 'react-router';
import useCurrentUser from '../../hooks/useCurrentUser';

const TradeOfferScreen = () => {
  const location = useLocation();
  const accessMatrix = useCurrentUser().accessMatrix;

  const { id } = useParams<{ id: UUID }>();
  const tab = (new URLSearchParams(location.search).get(ETradeOfferUrlParam.Tab) ??
    ETradeOfferDetailsTab.Description) as ETradeOfferDetailsTab;

  const favoritesAllowed = accessMatrix[ESecuredPartition.TradeOffers].favorite;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <TradeOfferDetailsContainer
            guid={guid}
            id={id}
            tab={tab}
            canFavoriteActions={favoritesAllowed}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default TradeOfferScreen;
