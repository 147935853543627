import { BaseQueryArg } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig } from 'axios';
import { CmsBanner, CmsCollection } from 'domain/model/cms';
import { DataFilterQueryDslOperator } from 'domain/model/filter';
import { createCancelToken } from './index';
import { ApiCancellable } from './types';
import { appendQueryDslParams } from './utils';

type BannerOneProps = ApiCancellable & {
  readonly id: UUID;
};

type BannerByIdsProps = ApiCancellable & {
  readonly ids: UUID[];
};

type CmsApi = {
  readonly banner: {
    readonly one: (props: BannerOneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CmsBanner>>;
    readonly byIds: (props: BannerByIdsProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CmsBanner[]>>;
  };
  readonly collection: {
    readonly one: (props: BannerOneProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CmsCollection>>;
    readonly byIds: (props: BannerByIdsProps) => BaseQueryArg<BaseQueryFn<AxiosRequestConfig, CmsCollection[]>>;
  };
};

/**
 * АПИ по работе с cms
 */
const cms: CmsApi = {
  banner: {
    one: ({ id }) => {
      return { url: `/cms/banners/${id}`, method: 'GET' };
    },
    byIds: ({ ids, signal }) => {
      const params = new URLSearchParams();
      appendQueryDslParams(params, [
        {
          field: 'id',
          operator: DataFilterQueryDslOperator.In,
          value: ids,
        },
      ]);
      return {
        url: `/cms/banners`,
        method: 'GET',
        params,
        cancelToken: signal && createCancelToken(axios, signal),
      };
    },
  },
  collection: {
    one: ({ id }) => {
      return { url: `/cms/collections/${id}`, method: 'GET' };
    },
    byIds: ({ ids, signal }) => {
      const params = new URLSearchParams();
      appendQueryDslParams(params, [
        {
          field: 'id',
          operator: DataFilterQueryDslOperator.In,
          value: ids,
        },
      ]);
      return {
        url: `/cms/collections`,
        method: 'GET',
        params: {
          params,
          cancelToken: signal && createCancelToken(axios, signal),
        },
      };
    },
  },
};

export default cms;
