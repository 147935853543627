import { LinkProps } from '@mui/material';
import { ReactNode } from 'react';
import AppSvgIcon from '../../../components/common/icon';
import { StartIconWrapper, StyledLink } from './controls';

type MPLinkProps = LinkProps & {
  readonly startIcon?: ReactNode;
  readonly iconSize?: 'micro' | 'small' | 'medium' | 'large';
};

export const MPLink = ({ children, startIcon, iconSize = 'micro', ...props }: MPLinkProps) => {
  return (
    <StyledLink {...props}>
      {startIcon && (
        <StartIconWrapper>
          <AppSvgIcon
            fontSize={iconSize}
            icon={startIcon}
          />
        </StartIconWrapper>
      )}
      {children}
    </StyledLink>
  );
};
