import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { PhoneIcon } from 'presentation/media/icons';
import { phoneFormatView } from 'presentation/utils/phone';
import { useState } from 'react';
import { UserProfileEditAttributePhone } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';
import { useUserProfileAttributeEdit } from '../hooks/useAttributeEdit';

export const UserProfilePhoneAdapter = () => {
  const [editMode, setEditMode] = useState<boolean>(false);

  const { user } = useCurrentUser();
  const { value, validation, isUpdating, update, reset, onChange } = useUserProfileAttributeEdit({
    attribute: 'phone',
    user,
    initialValue: '',
  });

  const showEdit = () => setEditMode(true);

  const hideEdit = () => {
    setEditMode(false);
    reset();
  };

  const updateInternal = () => {
    update().then(result => result && hideEdit());
  };

  return (
    <>
      <UserProfileViewAttribute
        label='Телефон'
        value={phoneFormatView(user.phone ?? '-')}
        icon={<PhoneIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributePhone
          label='Изменение телефона'
          value={value}
          validation={validation?.phone}
          isFetching={isUpdating}
          onChange={onChange}
          onClose={hideEdit}
          onSave={updateInternal}
        />
      )}
    </>
  );
};
