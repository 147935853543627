import { useAuthentication } from '@mp-npm/mp-auth-client';
import axios from 'axios';
import { useLazyGetUserAgreementsQuery, useSignUserAgreementsMutation } from 'data/api/agreements';
import { ENetworkErrorCode } from 'data/network/types';
import { SecurityToken } from 'domain/model/user';
import { FCC, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import ContentLoader from '../../../components/common/loader';
import SignAgreementsDialog from '../components/signAgreementsDialog';
import { BackdropWrapper } from './controls';

const LegalAgreementContainer: FCC = ({ children }) => {
  const { logOut, authService } = useAuthentication<SecurityToken>();

  const dispatch = useDispatch();

  const [triggerFetchUserAgreements, agreementsToSignResult] = useLazyGetUserAgreementsQuery();

  const { data: agreementsToSign, isFetching: isAgreementsListFetching } = agreementsToSignResult;

  const [triggerSignAgreements, { isLoading: isAgreementsListSigning }] = useSignUserAgreementsMutation();

  const open = !!agreementsToSign?.length;

  useLayoutEffect(() => {
    // TODO (@Protopopov Ruslan):mp-auth: вернуть после фикса mp-auth
    // if (!authService.tokenParsed) {
    //   return;
    // }

    const interceptor = axios.interceptors.response.use(undefined, error => {
      if (error?.response?.status === 403 && error?.response?.data?.code === ENetworkErrorCode.MissingUserAgreement) {
        triggerFetchUserAgreements({});
      }

      return Promise.reject(error);
    });

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [authService.tokenParsed, dispatch, triggerFetchUserAgreements]);

  const onAccept = async () => {
    if (!authService.tokenParsed || !agreementsToSign) {
      return;
    }

    const docLinks = agreementsToSign.map(a => ({
      id: a.doc.id,
    }));

    try {
      await triggerSignAgreements({ data: { docs: docLinks } });
    } catch {
      console.error('User agreements sign is failed');
    }
  };

  const onReject = () => {
    logOut();
  };

  return (
    <>
      {children}
      {isAgreementsListFetching && (
        <BackdropWrapper>
          <ContentLoader />
        </BackdropWrapper>
      )}
      {open && (
        <SignAgreementsDialog
          agreements={agreementsToSign ?? []}
          open
          isAccepting={isAgreementsListSigning}
          onAccept={onAccept}
          onReject={onReject}
        />
      )}
    </>
  );
};

export default LegalAgreementContainer;
