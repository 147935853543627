import { useLocation } from 'react-router';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserFavoritesContainer from 'presentation/features/user/details/favorites/container';
import { EUserFavoritesTab, EUserFavoritesUrlParam } from 'presentation/features/user/details/favorites/utils';
import { UserFavoritesLocationState } from 'presentation/features/user/entry';
import ScreenLayout from 'presentation/layouts/screen';

const UserFavoritesScreen = () => {
  const location = useLocation<Nullable<UserFavoritesLocationState>>();
  const searchParams = new URLSearchParams(location.search);

  const tab = (searchParams.get(EUserFavoritesUrlParam.Tab) as EUserFavoritesTab) ?? EUserFavoritesTab.ProductOffers;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <UserFavoritesContainer
            guid={guid}
            tab={tab}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserFavoritesScreen;
