import { OfferListRequest } from 'data/api';
import { useGetTradeOfferListQuery } from 'data/api/tradeOffer';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferListType, EOfferType } from 'domain/model/enums';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import TradeOfferListItem from 'presentation/features/offer/trade/components/listItem';
import useCategoriesData from 'presentation/features/offer/trade/list/hooks/useCategoriesData';
import { tradeOfferListIsNewFetchingSelector } from 'presentation/features/offer/trade/list/store/selectors';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import useOffersFavorites from '../../hooks/useOffersFavorites';

type TradeOfferListDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: OfferListRequest;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (tradeOffer: TradeOfferShort) => void;
};

const TradeOfferListDataContainer = (props: TradeOfferListDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { accessMatrix } = useCurrentUser();
  const isNewFetching = useSelector(tradeOfferListIsNewFetchingSelector);
  const { page } = args;

  const { subCategories } = useCategoriesData();

  const { data, error, isSuccess, isFetching } = useGetTradeOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const tradeOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Trade,
    },
  });

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasSubCategories = !!subCategories?.length;
  const hasOffers = !!tradeOffers && tradeOffers.length > 0;
  const isFavoritesAllowed = accessMatrix.tradeOffers.favorite;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <CardPageableList
        span={hasSubCategories ? 4 : 3}
        lgSpan={hasSubCategories ? 6 : 4}
        totalCount={totalCount}
        onLoadMore={onLoadMore}
      >
        {tradeOffers.map(tradeOffer => (
          <InView
            key={tradeOffer.id}
            active={isSuccess && !isFetching}
            sessionKey={sessionKey}
            data={tradeOffer}
            onShow={onShowCard}
          >
            <TradeOfferListItem
              offer={tradeOffer}
              favoriteProps={
                !isFavoritesAllowed
                  ? null
                  : {
                      active: isFavorite(EOfferType.Trade, tradeOffer),
                      inProgress: isAddToFavoritesFetching(tradeOffer.id),
                      onClick: () => addOrRemoveFavorite(EOfferType.Trade, tradeOffer),
                    }
              }
            />
          </InView>
        ))}
      </CardPageableList>
    </div>
  );
};

export default TradeOfferListDataContainer;
