import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bottomNavigationSetVisible, headerSetVisible } from '../../../../header/store/slice';

export const useOrderPrepare = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(headerSetVisible(false));
    dispatch(bottomNavigationSetVisible(false));

    return () => {
      dispatch(headerSetVisible(true));
      dispatch(bottomNavigationSetVisible(true));
    };
  }, [dispatch]);
};
