import { Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import rootRouting from '../../../../../routing';
import { getSiteCaption } from '../../../../utils';
import { List, ListItem, Text } from '../../components/controls';

const AboutTradingRulesTermsInfo = () => {
  return (
    <>
      <Text>
        1. В настоящих Правилах, если контекст не требует иного, нижеприведенные термины имеют следующие значения и
        являются их составной неотъемлемой частью:
      </Text>
      <List>
        <ListItem>
          «ЕК АСУТР. Модуль корпоративной витрины (ЕК АСУТР. ВКП)» — веб-сайт{' '}
          <Link
            color='primary'
            underline='none'
            component={RouterLink}
            to={rootRouting.root}
          >
            {getSiteCaption()}
          </Link>{' '}
          являющийся сервисом, предоставляющим доступ к информации о предложениях Партнеров о товарах и услугах,
          предназначенных для потенциальных Покупателей (потребителей). (Далее «Витрина»)
        </ListItem>
        <ListItem>
          Владелец Торговой площадки — Открытое акционерное общество «Российские железные дороги» (по тексту настоящих
          Правил — ОАО «РЖД» (Юридический адрес: 107174, г. Москва, вн.тер.г. муниципальный округ Басманный, ул Новая
          Басманная, д. 2/1, стр. 1.
        </ListItem>
        <ListItem>
          Покупатель — любое физическое лицо, использующее Торговую площадку на условиях настоящих Правил.
        </ListItem>
        <ListItem>
          Продавец — индивидуальный предприниматель, юридическое лицо или самозанятый гражданин, - поставщик
          корпоративных и торговых предложений, размещенных на Витрине.
        </ListItem>
        <ListItem>
          Товары и услуги — предмет договора купли-продажи, заключаемого между Покупателем и Продавцом. Продавец
          является собственником реализуемого Товара. Все взаимоотношения, связанные с куплей-продажей Товара возникают
          между Продавцом, размещающим Товар или Услуги на Торговой площадке и Покупателем.
        </ListItem>
        <ListItem>
          Личный кабинет — персональный и не доступный третьим лицам раздел Торговой площадки, служащий в целях
          оформления Заказов и оформления Услуг, а также получения специальных предложений в соответствии с настоящими
          Правилами, доступ к которому осуществляется после регистрации путем ввода аутентификационных данных
          Покупателя.
        </ListItem>
        <ListItem>
          Заказ — осуществление Покупателем действий, направленных на заключение им договора/договоров купли-продажи
          соответствующего/соответствующих Товара/Товаров с ОАО «РЖД» и/или иным Продавцом/Продавцами, посредством
          оформления заказа указанных услуг и Товаров на Торговой площадке.
        </ListItem>
        <ListItem>
          Акционная скидка — это скидка, которая предоставляется на определенный Товар или услугу. Размер скидки
          указывается на странице Товара или услуги. Цена на Товар или услуги указана с учетом акционной скидки.
        </ListItem>
        <ListItem>
          Скидка по промокоду — это скидка, которая предоставляется на определенную группу Товаров или улсуг при
          оформлении Покупателем Заказа.
        </ListItem>
        <ListItem>
          Промокод — это специальный код, который предоставляет скидку на определенную группу Товаров или услуг и имеет
          срок действия. Промокоды применяются автоматически. К одной единице товара может быть применен только один
          промокод.
        </ListItem>
        <ListItem>
          Баланс бонусных баллов — сумма бонусных баллов, находящихся в распоряжении покупателя, которыми он может
          распоряжаться по своему усмотрению посредством осуществления оплаты последующих заказов на сайте Торговой
          площадки в разделе «Корпоративные предложения» в размере до 100% стоимости товара или услуги.{' '}
        </ListItem>
      </List>
    </>
  );
};

export default AboutTradingRulesTermsInfo;
