import { Fade, Typography } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import { PartnerDeskListLayoutType } from './types';

const PartnerDeskListLayout: PartnerDeskListLayoutType = props => {
  const { filter, showMore, children, stub, loader } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        <Typography variant='h1'>Партнёры</Typography>
        <Splitter size={3} />

        {filter}
        <Splitter size={3} />

        {children}
        <Splitter size={3} />

        {showMore}
        {stub}
        {loader}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default PartnerDeskListLayout;
