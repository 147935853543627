import PartnerDeskListLoaderAdapter from 'presentation/features/partnerDesk/list/adapters/loader';
import PartnerDeskListDataAdapter from './adapters/data';
import PartnerDeskListFilterAdapter from './adapters/filter';
import PartnerDeskListShowMoreAdapter from './adapters/showMore';
import PartnerDeskListStubAdapter from './adapters/stub';
import PartnerDeskListLayout from './layout';
import PartnerDeskListProvider from './provider/provider';
import useVisibleSectionLinks from 'presentation/features/sectionLinks/hooks/useVisibleSectionLinks';

type PartnerDeskListContainerProps = {
  readonly guid: UUID;
};

const Filter = <PartnerDeskListFilterAdapter />;
const Stub = <PartnerDeskListStubAdapter />;
const ShowMore = <PartnerDeskListShowMoreAdapter />;

const Layout = PartnerDeskListLayout;

const PartnerDeskListContainer = ({ guid }: PartnerDeskListContainerProps) => {
  useVisibleSectionLinks();

  return (
    <PartnerDeskListProvider guid={guid}>
      <Layout
        filter={Filter}
        stub={Stub}
        loader={<PartnerDeskListLoaderAdapter guid={guid} />}
        showMore={ShowMore}
      >
        <PartnerDeskListDataAdapter />
      </Layout>
    </PartnerDeskListProvider>
  );
};

export default PartnerDeskListContainer;
