import { Fade } from '@mui/material';
import DefaultContentWrapper from '../../../../components/common/wrappers/content';
import { PartnerWindowLayoutType } from './types';

const PartnerWindowLayout: PartnerWindowLayoutType = props => {
  const { links, children } = props;

  return (
    <Fade in>
      <DefaultContentWrapper>
        {links}
        {children}
      </DefaultContentWrapper>
    </Fade>
  );
};

export default PartnerWindowLayout;
