export enum EParentGridAreas {
  Bar = 'trade-bar',
  Inner = 'trade-inner',
}

export enum EInnerGridAreas {
  SmartphoneFixedBar = 'trade-smartphone-fixed-bar',
  Breadcrumbs = 'trade-breadcrumbs',
  Title = 'trade-title',
  Image = 'trade-image',
  Description = 'trade-description',
  Favorite = 'trade-favorite',
  Sidebar = 'trade-side-bar',
  Tabs = 'trade-tabs',
}
