import SearchOffersHeaderAdapter from 'presentation/features/search/offers/adapters/header';
import SearchOffersLoaderAdapter from 'presentation/features/search/offers/adapters/loader';
import SearchOffersStubAdapter from 'presentation/features/search/offers/adapters/stub';
import SearchResultsLayout from '../../../layouts/searchResults';
import SearchOffersDataAdapter from './adapters/data';
import { SearchOffersContext } from './context';
import useSearchResultsHandlers from './hooks/useSearchOffers';
import useVisibleSectionLinks from '../../sectionLinks/hooks/useVisibleSectionLinks';
import { EOfferType } from '../../../../domain/model/enums';

const Layout = SearchResultsLayout;
const Header = <SearchOffersHeaderAdapter />;
const Stub = <SearchOffersStubAdapter />;
const Loader = <SearchOffersLoaderAdapter />;

type SearchResultsContainerProps = {
  readonly guid: UUID;
  readonly query: Nullable<string>;
  readonly offerType?: Nullable<EOfferType[]>;
};

const SearchOffersContainer = (props: SearchResultsContainerProps) => {
  const { guid, query, offerType = [] } = props;

  const handlers = useSearchResultsHandlers({ guid, query, offerType });

  useVisibleSectionLinks();

  return (
    <SearchOffersContext.Provider value={handlers}>
      <Layout
        header={Header}
        stub={Stub}
        loader={Loader}
      >
        <SearchOffersDataAdapter guid={guid} />
      </Layout>
    </SearchOffersContext.Provider>
  );
};

export default SearchOffersContainer;
