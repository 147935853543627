import { Location } from 'history';
import { v4 as uuidv4 } from 'uuid';

const rootRouting = {
  root: '/',
  tradeOffer: '/trade-offers',
  corpOffer: '/corp-offers',
  bookingOffer: '/booking-offers',
  partner: '/partners',
  user: '/user',
  about: '/about',
  legal: '/legal',
  payment: '/payment',
  cart: '/cart',
  product: '/products',
  data: '/data',
  cms: '/cms',
  search: '/search',
};

type AddSearchParamToLocationProps<S> = {
  readonly location: Location;
  readonly state?: S;
};

type GetHomepageRouteProps = {
  readonly guid?: Nullable<UUID>;
};

export type HomepageLocationState = {
  readonly guid: UUID;
};

export const getHomepageRoute = (props?: GetHomepageRouteProps): Location<HomepageLocationState> => {
  const newGuid = props?.guid ?? uuidv4();

  return {
    pathname: rootRouting.root,
    search: '',
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

export const addStateToLocation = <S>(props: AddSearchParamToLocationProps<S>): Location => {
  const { location, state } = props;
  const { pathname, search, state: prevState, hash, key } = location;

  const newState = typeof prevState === 'object' ? { ...prevState, ...state } : { ...state };

  return {
    pathname,
    search,
    state: newState,
    hash,
    key,
  };
};

export default rootRouting;
