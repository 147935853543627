import RenderDecoratorContainer from 'presentation/components/common/renderDecoratorContainer';
import CommonRenderManager from 'presentation/components/common/renderManager/container';
import RenderManagerErrorProvider from 'presentation/components/common/renderManager/error/provider';
import CorpOfferBonusUnavailableAdapter from 'presentation/features/offer/corp/components/bonusUnavailableStub';
import CorpOfferBreadcrumbAdapter from 'presentation/features/offer/corp/details/adapters/breadcrumb';
import CorpOfferDetailsPartnerInfoAdapter from 'presentation/features/offer/corp/details/adapters/partnerInfo';
import CorpOfferDetailSidebarAdapter from 'presentation/features/offer/corp/details/adapters/sidebar';
import CorpOfferDetailsTabsAdapter from 'presentation/features/offer/corp/details/adapters/tabs';
import CorpOfferDetailsTitleAdapter from 'presentation/features/offer/corp/details/adapters/title';
import CorpOfferCarouselAdapter from './adapters/carousel';
import CorpOfferConditionsAdapter from './adapters/condition';
import CorpOfferDetailsFavoriteAdapter from './adapters/favorite';
import CorpOfferDetailsLoaderAdapter from './adapters/loader';
import SmartPhoneFixedBarAdapter from './adapters/smartPhoneFixedBar';
import CorpOfferDetailStub from './adapters/stub';
import { CorpOfferDetailContext } from './context';
import useCorpOfferDetails from './hooks/useCorpOfferDetails';
import CorpOfferDetailsLayout from './layout';
import { ECorpOfferDetailsTab } from './utils';
import CorpOfferCloseAdapter from './adapters/close';
import { useMediaQuery, useTheme } from '@mui/material';
import useVisibleSectionLinks from '../../../sectionLinks/hooks/useVisibleSectionLinks';

type CorpOfferDetailsContainerProps = {
  readonly id: UUID;
  readonly guid: UUID;
  readonly tab: ECorpOfferDetailsTab;
  readonly canFavoriteActions?: boolean;
};

const DetailsLayout = CorpOfferDetailsLayout;

const CorpOfferDetailsContainer = ({ guid, id, tab, canFavoriteActions = false }: CorpOfferDetailsContainerProps) => {
  const handlers = useCorpOfferDetails({ guid, id });

  const theme = useTheme();

  const downMd = useMediaQuery(theme.breakpoints.down('md'));

  useVisibleSectionLinks(downMd);

  return (
    <CorpOfferDetailContext.Provider value={handlers}>
      <RenderManagerErrorProvider>
        <RenderDecoratorContainer
          decorators={[
            <CommonRenderManager
              key='loader'
              loader={<CorpOfferDetailsLoaderAdapter id={id} />}
              stub={CorpOfferDetailStub}
            />,
            <CorpOfferBonusUnavailableAdapter key='bonusUnavailable' />,
          ]}
        >
          <DetailsLayout
            id={id}
            image={<CorpOfferCarouselAdapter id={id} />}
            favorite={
              canFavoriteActions && (
                <CorpOfferDetailsFavoriteAdapter
                  guid={guid}
                  id={id}
                />
              )
            }
            description={<CorpOfferConditionsAdapter id={id} />}
            breadcrumb={<CorpOfferBreadcrumbAdapter id={id} />}
            smartphoneFixedBar={
              <SmartPhoneFixedBarAdapter
                id={id}
                isFavorite={handlers.isFavorite}
              />
            }
            partnerInfo={<CorpOfferDetailsPartnerInfoAdapter id={id} />}
            sidebar={<CorpOfferDetailSidebarAdapter id={id} />}
            tabs={
              <CorpOfferDetailsTabsAdapter
                id={id}
                guid={guid}
                tab={tab}
              />
            }
            title={<CorpOfferDetailsTitleAdapter id={id} />}
            close={<CorpOfferCloseAdapter />}
          />
        </RenderDecoratorContainer>
      </RenderManagerErrorProvider>
    </CorpOfferDetailContext.Provider>
  );
};

export default CorpOfferDetailsContainer;
