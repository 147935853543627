import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EOfferType } from '../../../../../../domain/model/enums';
import useOfferFavorite from '../../../hooks/useOfferFavorite';
import { getCorpOffersListRoute } from '../../entry';
import { corpOfferDetailsStartSession } from '../store/slice';
import useCorpOfferDetailsData from './useCorpOfferDetailsData';

export type UseCorpOfferDetails = {
  readonly onAddToFavorite: () => void;
  readonly onBack: () => void;
  readonly isFavoriteProcessing: boolean;
  readonly isFavorite: boolean;
};

export type UseCorpOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const useCorpOfferDetails = ({ id, guid }: UseCorpOfferDetailsProps): UseCorpOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const { corpOffer } = useCorpOfferDetailsData(id);

  useEffect(() => {
    webAnalytics.offerView(id);
  }, [webAnalytics, id]);

  useEffect(() => {
    dispatch(corpOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const {
    add: addFavorite,
    remove: removeFavorite,
    isFetching,
    isFavorite: checkFavorite,
  } = useOfferFavorite({
    guid: id,
    payload: {
      id,
      offerType: EOfferType.Corp,
      partnerId: corpOffer?.partner?.id,
    },
  });

  const isFavorite = checkFavorite(EOfferType.Corp, id);

  const onAddToFavorite = () => {
    if (!corpOffer) {
      return;
    }

    if (isFavorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  };

  const onBack = () => {
    gotoPrevIndependentLocation(getCorpOffersListRoute());
  };

  return {
    onAddToFavorite,
    onBack,
    isFavoriteProcessing: isFetching,
    isFavorite,
  };
};

export default useCorpOfferDetails;
