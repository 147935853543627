import CurrentUserContext, { CurrentUserContextType } from 'presentation/features/user/current/settings/context';
import { useContext } from 'react';

type UseCurrentUser = () => CurrentUserContextType;

const useCurrentUser: UseCurrentUser = () => {
  return useContext<CurrentUserContextType>(CurrentUserContext);
};

export default useCurrentUser;
