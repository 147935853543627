import { CaseReducer, createSelector, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from 'data/store/store';
import { UserFavoriteProcess } from 'domain/model';

export type FavoritesProvideStateByGuid = {
  readonly processes: UserFavoriteProcess[];
};

export type FavoritesProvideState = Record<UUID, FavoritesProvideStateByGuid>;

type Reducer<T> = CaseReducer<FavoritesProvideState, PayloadAction<T>>;

type Reducers = SliceCaseReducers<FavoritesProvideState> & {
  favoritesProvideStartSession: Reducer<{ guid: UUID }>;
  favoritesProvideStopSession: Reducer<{ guid: UUID }>;
  favoritesProvideChangeProcess: Reducer<{ guid: UUID; process: UserFavoriteProcess }>;
};

const slice = createSlice<FavoritesProvideState, Reducers, 'provide'>({
  name: 'provide',
  initialState: {},
  reducers: {
    favoritesProvideStartSession: (state, { payload }) => {
      const { guid } = payload;
      const sessionState = state[guid];
      if (!sessionState) {
        state[guid] = { processes: [] };
      }
    },
    favoritesProvideStopSession: (state, { payload }) => {
      const { guid } = payload;
      delete state[guid];
    },
    favoritesProvideChangeProcess: (state, { payload }) => {
      const { guid, process } = payload;

      const sessionState = state[guid];
      if (sessionState) {
        const currentProcess = sessionState.processes.find(p => p.id === process.id);
        if (currentProcess) {
          currentProcess.action = process.action;
          currentProcess.id = process.id;
          currentProcess.isFetching = process.isFetching;
          currentProcess.isFetched = process.isFetched;
          currentProcess.isFailed = process.isFailed;
          currentProcess.isCancelled = process.isCancelled;
          currentProcess.offerType = process.offerType;
        } else {
          sessionState.processes.push(process);
        }
      }
    },
  },
});

export const { favoritesProvideStartSession, favoritesProvideStopSession, favoritesProvideChangeProcess } =
  slice.actions;

const sessionsSelector = (state: RootState) => state.favorites.provide;
const sessionGuidSelector = (state: RootState, guid: UUID) => guid;
const processIdSelector = (state: RootState, guid: UUID, processId: UUID) => processId;

export const createFavoriteProvideByProcessIdSelector = createSelector(
  sessionsSelector,
  sessionGuidSelector,
  processIdSelector,
  (sessions, guid, processId) =>
    (sessions?.[guid]?.processes as UserFavoriteProcess[])?.find(p => p.id === processId) ?? null
);

export const getFavoriteProvideProcessesSelector = createSelector(
  sessionsSelector,
  sessionGuidSelector,
  (sessions, guid): Nullable<UserFavoriteProcess[]> => sessions?.[guid]?.processes ?? null
);

export default slice.reducer;
