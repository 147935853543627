import { useWebAnalytics } from 'presentation/features/webAnalytics';
import useHistoryExtensions from 'presentation/hooks/useHistoryExtensions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EOfferType } from '../../../../../../domain/model/enums';
import useOfferFavorite from '../../../hooks/useOfferFavorite';
import { getTradeOffersListRoute } from '../../entry';
import { tradeOfferDetailsStartSession } from '../store/slice';
import useTradeOfferDetailsData from './useTradeOfferDetailsData';

export type UseTradeOfferDetails = {
  readonly onAddToFavorite: () => void;
  readonly onBack: () => void;
  readonly isFavoriteProcessing: boolean;
  readonly isFavorite: boolean;
};

export type UseTradeOfferDetailsProps = {
  readonly guid: UUID;
  readonly id: UUID;
};

const useTradeOfferDetails = ({ id, guid }: UseTradeOfferDetailsProps): UseTradeOfferDetails => {
  const { webAnalytics } = useWebAnalytics();
  const dispatch = useDispatch();
  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const { tradeOffer } = useTradeOfferDetailsData(id);

  useEffect(() => {
    webAnalytics.offerView(id);
  }, [webAnalytics, id]);

  useEffect(() => {
    dispatch(tradeOfferDetailsStartSession({ guid }));
  }, [dispatch, guid]);

  const {
    add: addFavorite,
    remove: removeFavorite,
    isFetching,
    isFavorite: checkFavorite,
  } = useOfferFavorite({
    guid: id,
    payload: {
      id,
      offerType: EOfferType.Trade,
      partnerId: tradeOffer?.partner?.id,
    },
  });

  const isFavorite = checkFavorite(EOfferType.Trade, id);

  const onAddToFavorite = () => {
    if (!tradeOffer) {
      return;
    }

    if (isFavorite) {
      removeFavorite();
    } else {
      addFavorite();
    }
  };

  const onBack = () => {
    gotoPrevIndependentLocation(getTradeOffersListRoute());
  };

  return {
    onAddToFavorite,
    onBack,
    isFavoriteProcessing: isFetching,
    isFavorite,
  };
};

export default useTradeOfferDetails;
