import { RootState } from 'data/store/store';
import { UserFavoriteProcess } from 'domain/model';
import { EOfferType } from 'domain/model/enums';
import { createFavoriteProvideByProcessIdSelector, getFavoriteProvideProcessesSelector } from './slice';

export const favoriteProvideSelector = (store: RootState) => store.favorites.provide;

export const favoriteProvideByProcessIdSelector = (guid: UUID, processId: UUID) => (state: RootState) =>
  createFavoriteProvideByProcessIdSelector(state, guid, processId);

export const favoriteProvideProcessesSelector =
  (guid: UUID) =>
  (state: RootState): Nullable<UserFavoriteProcess[]> =>
    getFavoriteProvideProcessesSelector(state, guid);

// наличие изменений в рамках guid-а
export const favoriteProvideHasOffersChangesByTypeSelector =
  (guid: UUID, offerType: EOfferType) =>
  (state: RootState): boolean =>
    !!getFavoriteProvideProcessesSelector(state, guid)?.filter(item => item.offerType === offerType)?.length;
