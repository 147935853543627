import styled from '@emotion/styled/macro';
import { css, Link } from '@mui/material';

export const StartIconWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    margin-right: ${theme.spacing(1)};
  `
);

export const StyledLink = styled(Link)(
  () => css`
    display: flex;
    align-items: center;
  `
);
