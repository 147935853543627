import { useCancelOrderMutation, userApi } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useDialogInHistory from '../../../hooks/useDialogInHistory';
import { CancelOrderModal } from '../cancelOrderModal';
import OrderProductOfferDetailsComponent from './component';
import { ProductOrder } from '../../../../domain/model/order';

type OrderProductOfferDetailsContainerProps = {
  readonly order: ProductOrder;
};

const OrderProductOfferDetailsContainer = (props: OrderProductOfferDetailsContainerProps) => {
  const { order } = props;

  const dispatch = useDispatch();

  const [cancelOrder, { isLoading, error }] = useCancelOrderMutation();
  const { open: isModalOpen, onOpen, onClose } = useDialogInHistory({});

  const onCancel = () => {
    onOpen();
  };

  const onCloseCancelModal = () => {
    // block closing modal while mutation in progress(even for close icon)
    if (!isLoading) onClose();
  };

  const onCancelOrder = (id: UUID, comment: string) => {
    if (order.id) {
      cancelOrder({
        id: order.id,
        cancellationType: { id },
        comment,
      })
        .unwrap()
        .then(response => {
          if (order.id) {
            onClose();
            dispatch(
              userApi.util.updateQueryData('getUserOrder', order.id, draft => {
                Object.assign(draft, response);
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    if (error) ErrorHandler.handleHttpError(error);
  }, [error]);

  return (
    <>
      <OrderProductOfferDetailsComponent
        order={order}
        onCancel={onCancel}
      />

      <CancelOrderModal
        isOpen={isModalOpen}
        isFetching={isLoading}
        onClose={onCloseCancelModal}
        onCancelOrder={onCancelOrder}
      />
    </>
  );
};

export default OrderProductOfferDetailsContainer;
