import { Link as MUILink } from '@mui/material';
import { BookingOfferShort } from 'domain/model/bookingOffer';
import OfferListItem, { OfferListItemProps } from 'presentation/features/offer/components/listItem';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import CardFavoriteButton, {
  CardFavoriteButtonProps,
} from '../../../../../components/common/buttons/cardFavorite/button';
import { getBookingOffersDetailsRoute } from '../../entry';
import BookingListItemInfo from './info';
import BookingListItemPrice from './price';

export type BookingOfferListItemProps = {
  readonly offer: BookingOfferShort;
  readonly favoriteProps?: Nullable<CardFavoriteButtonProps>;
  readonly direction?: OfferListItemProps['direction'];
};

const BookingOfferListItem = forwardRef<any, BookingOfferListItemProps>((props, ref) => {
  const {
    offer,
    offer: { id, images, name },
    favoriteProps,
    direction,
  } = props;

  return (
    <MUILink
      ref={ref}
      component={Link}
      underline='none'
      to={getBookingOffersDetailsRoute({ id })}
    >
      <OfferListItem
        images={images}
        description={name ?? ''}
        header={offer.displayPriceItem ? <BookingListItemPrice priceItem={offer.displayPriceItem} /> : null}
        favorite={favoriteProps && <CardFavoriteButton {...favoriteProps} />}
        info={<BookingListItemInfo {...offer} />}
        direction={direction}
      />
    </MUILink>
  );
});

export default BookingOfferListItem;
