import { PartnerOfferListRequest, useGetPartnerCorpOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { ECorpOfferSortType, EOfferListType, EOfferType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import InView from 'presentation/components/common/inView';
import CorpOfferListItem from 'presentation/features/offer/corp/components/listItem';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useCurrentUser from '../../../../../hooks/useCurrentUser';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';
import { partnerDeskDetailsCorpOffersIsNewFetchingSelector } from '../../store/selectors';

type PartnerDeskCorpOffersDataContainerProps = {
  readonly type: EOfferListType;
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<ECorpOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (type: EOfferListType, newPage: number) => void;
  readonly onShowCard: (corpOffer: CorpOfferShort) => void;
};

const PartnerDeskCorpOffersDataContainer = (props: PartnerDeskCorpOffersDataContainerProps) => {
  const { type, guid, args, header, onChangePage, onShowCard } = props;

  const { accessMatrix } = useCurrentUser();
  const isNewFetching = useSelector(partnerDeskDetailsCorpOffersIsNewFetchingSelector);
  const { page } = args;

  const { data, error, isSuccess, isFetching } = useGetPartnerCorpOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const corpOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Corp,
      partnerId: args.partnerId,
    },
  });

  const onLoadMore = useCallback(() => {
    onChangePage(type, page + 1);
  }, [type, page, onChangePage]);

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!corpOffers && corpOffers.length > 0;
  const isFavoritesAllowed = accessMatrix.corpOffers.favorite;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  const sessionKey = JSON.stringify(args);

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={onLoadMore}
        >
          {corpOffers.map(corpOffer => (
            <InView
              key={corpOffer.id}
              active={isSuccess && !isFetching}
              sessionKey={sessionKey}
              data={corpOffer}
              onShow={onShowCard}
            >
              <CorpOfferListItem
                offer={corpOffer}
                favoriteProps={
                  !isFavoritesAllowed
                    ? null
                    : {
                        active: isFavorite(EOfferType.Corp, corpOffer),
                        inProgress: isAddToFavoritesFetching(corpOffer.id),
                        onClick: () => addOrRemoveFavorite(EOfferType.Corp, corpOffer),
                      }
                }
              />
            </InView>
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskCorpOffersDataContainer;
