import { FC } from 'react';
import { UUID } from 'domain/model/types';
import { ButtonLink } from 'presentation/components/common/buttons/link';
import AppSvgIcon from 'presentation/components/common/icon';
import ContentLoader from 'presentation/components/common/loader';
import { IconFavoriteFilled } from 'presentation/media/icons';
import useBookingOfferDetails from '../../hooks/useBookingOfferDetails';
import useBookingOfferDetailsData from '../../hooks/useBookingOfferDetailsData';
import { FavoriteText, Wrapper } from './controls';
import { BookingDetailsId } from '../../types';

type BookingOfferDetailsFavoriteAdapterProps = BookingDetailsId & {
  readonly guid: UUID;
};

// TODO заменить на OfferDetailsFavoriteOnly

const BookingOfferDetailsFavoriteAdapter: FC<BookingOfferDetailsFavoriteAdapterProps> = ({ id }) => {
  const { bookingOffer } = useBookingOfferDetailsData(id);
  const { onAddToFavorite, isFavorite, isFavoriteProcessing } = useBookingOfferDetails(id);

  if (!bookingOffer) {
    return null;
  }

  return (
    <Wrapper>
      <ButtonLink
        startIcon={
          <AppSvgIcon
            color={isFavorite ? 'brand' : 'secondary'}
            icon={IconFavoriteFilled}
          />
        }
        onClick={onAddToFavorite}
        disabled={isFavoriteProcessing}
      >
        <FavoriteText
          variant='body2'
          color='primary'
        >
          {isFavorite ? 'В избранном' : 'В избранное'}
        </FavoriteText>
        {isFavoriteProcessing && <ContentLoader size={20} />}
      </ButtonLink>
    </Wrapper>
  );
};

export default BookingOfferDetailsFavoriteAdapter;
