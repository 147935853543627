import { Fade, useMediaQuery } from '@mui/material';
import Splitter from 'presentation/components/common/splitter';
import DefaultContentWrapper from 'presentation/components/common/wrappers/content';
import ScreenNavWrapper from 'presentation/components/common/wrappers/screenNavWrapper';
import {
  DetailsGrid,
  DetailsGridArea,
  EProductDetailsGridArea,
} from 'presentation/features/offer/product/details/controls';
import { ReactNode } from 'react';

type ProductOfferDetailsLayoutProps = {
  readonly toolbar?: ReactNode;
  readonly breadcrumbs: ReactNode;
  readonly title: ReactNode;
  readonly favorite: ReactNode;
  readonly carousel: ReactNode;
  readonly tabs: ReactNode;
  readonly attributes: ReactNode;
  readonly shortCharacteristics: ReactNode;
  readonly partnerInfo: ReactNode;
  readonly sidebar: ReactNode;
}

const ProductOfferDetailsLayout = (props: ProductOfferDetailsLayoutProps) => {
  const {
    breadcrumbs,
    toolbar,
    title,
    favorite,
    tabs,
    carousel,
    attributes,
    shortCharacteristics,
    partnerInfo,
    sidebar,
  } = props;

  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (
    <>
      {toolbar && <ScreenNavWrapper>{toolbar}</ScreenNavWrapper>}
      <Fade in>
        <DefaultContentWrapper>
          <DetailsGrid>
            <DetailsGridArea area={EProductDetailsGridArea.Breadcrumbs}>{breadcrumbs}</DetailsGridArea>
            <DetailsGridArea area={EProductDetailsGridArea.Title}>{title}</DetailsGridArea>
            <DetailsGridArea
              area={EProductDetailsGridArea.Favorite}
              container
              alignItems='flex-end'
              justifyContent={isMdUp ? 'flex-end' : 'flex-start'}
            >
              {favorite}
            </DetailsGridArea>
            <DetailsGridArea
              area={EProductDetailsGridArea.Presentation}
              flexDirection='column'
            >
              {carousel}
              {isMdUp && <Splitter size={5} />}
              {isMdUp && tabs}
            </DetailsGridArea>
            <DetailsGridArea
              area={EProductDetailsGridArea.Controls}
              flexDirection='column'
            >
              {attributes}
              {isMdUp && <Splitter size={4} />}
              {isMdUp && shortCharacteristics}
              {!isMdUp && partnerInfo}
              {!isMdUp && tabs}
            </DetailsGridArea>
            <DetailsGridArea
              area={EProductDetailsGridArea.Sidebar}
              flexDirection='column'
            >
              {sidebar}
              {isMdUp && partnerInfo}
            </DetailsGridArea>
          </DetailsGrid>
        </DefaultContentWrapper>
      </Fade>
    </>
  );
};

export default ProductOfferDetailsLayout;
