import styled from '@emotion/styled/macro';
import { Card as MuiCard, css, Typography } from '@mui/material';

const imageSize = '80px';

export const Card = styled(MuiCard)(
  ({ theme }) => css`
    height: 100%;

    position: relative;
    overflow: inherit;

    padding: ${theme.spacing(2, 3)};

    display: flex;
    flex-direction: column;
    align-items: center;

    user-select: none;
    box-shadow: none;
    border-radius: 0;
    background: none;

    cursor: pointer;
  `
);

export const WrapperImage = styled.div(
  () => css`
    width: ${imageSize};
    height: ${imageSize};

    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      max-width: ${imageSize};
      max-height: ${imageSize};
      object-fit: contain;
      object-position: center center;
    }
  `
);

export const CardTitle = styled(Typography)(
  ({ theme }) => css`
    height: 2.5rem;
    overflow: hidden;

    text-overflow: ellipsis;
    text-align: center;
    word-break: break-word;

    margin-top: ${theme.spacing(1.25)};
    margin-bottom: ${theme.spacing(0.5)};
    padding: ${theme.spacing(0, 1)};

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  `
);

export const Stub = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  `
);
