import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useAddUserOfferToFavoritesMutation, useRemoveUserOfferFromFavoritesMutation } from 'data/api/user';
import { EFavoriteProcessAction, EOfferType } from 'domain/model/enums';
import { favoriteProvideByProcessIdSelector } from '../../favorite/provide/store/selectors';
import { UseFavoriteCommonProps } from '../../favorite/provide/types';
import useFavoritesMutation from '../../favorite/provide/useFavoritesMutation';
import { getUserFavoritesSelector } from '../../user/details/favorites/store/selectors';

type PayloadType = {
  readonly id: UUID;
  readonly offerType: EOfferType;
  readonly partnerId?: UUID;
};

type UseOfferFavoriteProps = UseFavoriteCommonProps<PayloadType>;

type UseOfferFavoriteResult = {
  readonly isRemoving: boolean;
  readonly isAdding: boolean;
  readonly isFetching: boolean;
  readonly isFavorite: (type: EOfferType, id: string) => boolean;
  readonly add: () => void;
  readonly remove: () => void;
};

/**
 * хук для работы с избранным одного оффера
 */
const useOfferFavorite = (props: UseOfferFavoriteProps): UseOfferFavoriteResult => {
  const { guid, payload } = props;

  const process = useSelector(favoriteProvideByProcessIdSelector(guid, payload.id));
  const favorites = useSelector(getUserFavoritesSelector());

  const addMutation = useAddUserOfferToFavoritesMutation();
  const removeMutation = useRemoveUserOfferFromFavoritesMutation();

  const { add: addInternal, remove: removeInternal } = useFavoritesMutation({
    guid,
    payload,
    addMutation,
    removeMutation,
  });

  const add = useCallback(() => addInternal(payload.id), [payload.id, addInternal]);

  const remove = useCallback(() => removeInternal(payload.id), [payload.id, removeInternal]);

  const isFavorite = useCallback(
    (type: EOfferType, id: string) => {
      return favorites?.[type] ? favorites[type].indexOf(id) > -1 : false;
    },
    [favorites]
  );

  const isRemoving = process?.action === EFavoriteProcessAction.Remove && process?.isFetching;

  const isAdding = process?.action === EFavoriteProcessAction.Add && process?.isFetching;

  return {
    isRemoving,
    isAdding,
    isFetching: isAdding || isRemoving,
    isFavorite,
    add,
    remove,
  };
};

export default useOfferFavorite;
