import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: auto;

    ${theme.breakpoints.up('md')} {
      max-width: 944px;
    }
  `
);

export const ContentWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;

    ${theme.breakpoints.up('md')} {
      flex-wrap: wrap;
    }
  `
);

export const ListWrapper = styled.div(
  () => css`
    width: 100%;
    min-height: 10rem;

    position: relative;
  `
);

export const DetailsWrapper = styled.div(
  () => css`
    flex: 1;
    overflow: auto;
  `
);
