import { ECorpOfferPromotionType, EOfferActivationStatus } from 'domain/model/enums';
import { MPGrid } from 'presentation/theme/ui-kit/grid';
import { useMemo } from 'react';
import { getOfferPromotionDownloadUrl } from '../../../../../utils';
import useCorpOfferSidebar from '../../provider/useCorpOfferSidebar';
import { OfferPromotionDownloadLegacy } from 'presentation/features/offer/components/promotionDownload/downloadUrlLegacy';

export const CorpOfferSidebarPromotionAdapter = () => {
  const { corpOffer, activation, takeOfferActivation } = useCorpOfferSidebar();

  const promotionDownloadUrl = activation.lastActivation
    ? getOfferPromotionDownloadUrl(activation.lastActivation)
    : null;

  const isNotExternal = corpOffer?.promotionType !== ECorpOfferPromotionType.ExternalCertificate;

  return useMemo(
    () =>
      corpOffer &&
      isNotExternal &&
      (activation.lastActivation?.status === EOfferActivationStatus.Approved ||
        activation.lastActivation?.status === EOfferActivationStatus.Given) &&
      promotionDownloadUrl ? (
        <MPGrid
          container
          alignItems='center'
          spacing={1}
        >
          <MPGrid
            item
            zero={12}
          >
            <OfferPromotionDownloadLegacy
              label={corpOffer.code}
              url={promotionDownloadUrl}
              onDownload={() => activation.lastActivation && takeOfferActivation(activation.lastActivation)}
            />
          </MPGrid>
        </MPGrid>
      ) : null,
    [corpOffer, isNotExternal, activation.lastActivation, promotionDownloadUrl, takeOfferActivation]
  );
};
