import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PartnerOfferListRequest, useGetPartnerBookingOfferListQuery } from 'data/api/partner';
import ErrorHandler from 'data/network/errorHandler';
import { EBookingOfferSortType, EOfferType } from 'domain/model/enums';
import { CardPageableList } from 'presentation/components/common/cardPageableList';
import { partnerDeskDetailsBookingOffersIsNewFetchingSelector } from 'presentation/features/partnerDesk/details/store/selectors';
import BookingOfferListItem from '../../../../offer/booking/components/listItem';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';
import useCurrentUser from '../../../../../hooks/useCurrentUser';

type PartnerDeskBookingOffersDataContainerProps = {
  readonly guid: UUID;
  readonly args: PartnerOfferListRequest<EBookingOfferSortType>;
  readonly header?: React.ReactNode;
  readonly onChangePage: (newPage: number) => void;
};

const PartnerDeskBookingOffersDataContainer = (props: PartnerDeskBookingOffersDataContainerProps) => {
  const { args, guid, header, onChangePage } = props;

  const { accessMatrix } = useCurrentUser();
  const isNewFetching = useSelector(partnerDeskDetailsBookingOffersIsNewFetchingSelector);
  const { page } = args;

  const { data, error } = useGetPartnerBookingOfferListQuery(args, {
    refetchOnMountOrArgChange: true,
  });

  const bookingOffers = data?.data;
  const totalCount = data?.totalCount ?? 0;

  const {
    isFetching: isAddToFavoritesFetching,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Booking,
      partnerId: args.partnerId,
    },
  });

  useEffect(() => {
    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [error]);

  const hasOffers = !!bookingOffers && bookingOffers.length > 0;
  const isFavoritesAllowed = accessMatrix.bookingOffers.favorite;

  if (!hasOffers || isNewFetching) {
    return null;
  }

  return (
    <div>
      {header}
      <div>
        <CardPageableList
          totalCount={totalCount}
          onLoadMore={() => onChangePage(page + 1)}
        >
          {bookingOffers.map(bookingOffer => (
            <BookingOfferListItem
              key={bookingOffer.id}
              offer={bookingOffer}
              favoriteProps={
                !isFavoritesAllowed
                  ? null
                  : {
                      active: isFavorite(EOfferType.Booking, bookingOffer),
                      inProgress: isAddToFavoritesFetching(bookingOffer.id),
                      onClick: () => addOrRemoveFavorite(EOfferType.Booking, bookingOffer),
                    }
              }
            />
          ))}
        </CardPageableList>
      </div>
    </div>
  );
};

export default PartnerDeskBookingOffersDataContainer;
