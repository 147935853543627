import axios, { AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { MPAuthenticationClient } from 'presentation/features/auth/types';
import Notifier from 'system/notifier';
import { AppDispatch } from '../store/store';
import { EHeaders, ENetworkErrorCode } from './types';

export default class HttpClient {
  private static instance: HttpClient;

  public static getInstance(): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }

    return HttpClient.instance;
  }

  private authService: Nullable<MPAuthenticationClient> = null;

  private constructor() {}

  dispatch: Nullable<AppDispatch> = null;

  init(dispatch?: AppDispatch) {
    if (dispatch) {
      this.dispatch = dispatch;
    }
    this.initAxios();
  }

  private initAxios() {
    axios.defaults.headers.common[EHeaders.AppId] = 'coms';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common.Accept = 'application/json';
    this.initTokenInterceptor();
  }

  setAuthService(authService: MPAuthenticationClient) {
    this.authService = authService;
  }

  getAuthService(): Nullable<MPAuthenticationClient> {
    return this.authService;
  }

  private initTokenInterceptor() {
    axios.interceptors.request.use(
      config => {
        const headers: AxiosRequestHeaders = config.headers ?? {};
        headers.Authorization = `Bearer ${this.authService?.token}`;
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(undefined, error => {
      const logOut = () => {
        setTimeout(() => {
          this.authService?.logout({ redirectUri: window.location.origin });
        }, 3000);
      };

      const status = error?.response?.status;
      const code = error?.response?.data?.code;

      switch (status) {
        case 401: {
          this.authService
            ?.updateToken(99999999999999)
            .then(success => {
              if (success) {
                console.debug('Token was updated');
              } else {
                Notifier.getInstance().addError('Необходима авторизация');
                logOut();
              }
            })
            .catch(updateTokenError => {
              Notifier.getInstance().addError('Необходима авторизация');
              console.warn(`Не удалось обновить токен безопасности: ${updateTokenError?.message}`);
              logOut();
            });
          break;
        }
        case 403: {
          if (code === ENetworkErrorCode.UserBlocked) {
            Notifier.getInstance().addError('Текущий пользователь был заблокирован');
            logOut();
          }
          break;
        }
      }
      return Promise.reject(error);
    });
  }

  public applyInterceptor(
    fullfilledCallback: (c: InternalAxiosRequestConfig) => InternalAxiosRequestConfig,
    rejectedCallback: (e: any) => any
  ) {
    return axios.interceptors.request.use(fullfilledCallback, rejectedCallback);
  }

  public ejectInterceptor(interceptorId: number) {
    return axios.interceptors.request.eject(interceptorId);
  }
}
