import { FC, useEffect } from 'react';
import { useGetUserFavoritesTradeOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType } from 'domain/model/enums';
import CardList from 'presentation/components/common/cardList';
import TradeOfferListItem from 'presentation/features/offer/trade/components/listItem';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';
import { TabProps } from './types';

export const UserFavoritesTradeOffersContainer: FC<TabProps> = ({ guid, queryGuid }) => {
  const { data, error: tradeOffersError } = useGetUserFavoritesTradeOffersQuery({ guid: queryGuid });
  const tradeOffers = data?.data;

  const {
    isFetching: isAddToFavoritesFetching,
    canAdd,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Trade,
    },
    clearAfterUnmount: false, //чтобы не скрывался таб после убирания всех сердечек
  });

  useEffect(() => {
    if (tradeOffersError) {
      ErrorHandler.handleHttpError(tradeOffersError);
    }
  }, [tradeOffersError]);

  if (!tradeOffers) {
    return null;
  }

  return (
    <CardList>
      {tradeOffers.map(offer => (
        <TradeOfferListItem
          key={offer.id}
          offer={offer}
          favoriteProps={{
            active: canAdd(EOfferType.Trade, offer) && isFavorite(EOfferType.Trade, offer),
            inProgress: isAddToFavoritesFetching(offer.id),
            onClick: () => addOrRemoveFavorite(EOfferType.Trade, offer),
          }}
        />
      ))}
    </CardList>
  );
};
