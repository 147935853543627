import { ESecuredPartition, EUserRole } from 'domain/model/enums';
import { UserAccessMatrix } from 'domain/model/security';
import { User } from 'domain/model/user';
import { EAppFeature } from '../../types';
import { getAllowedRoles, getUserDefaultRoute } from './utils';

type BuildUserAccessMatrixType = (
  user: User,
  roles: EUserRole[],
  hasFeature: (feature: EAppFeature) => boolean
) => UserAccessMatrix;

export const buildUserAccessMatrix: BuildUserAccessMatrixType = (user, roles, hasFeature): UserAccessMatrix => {
  const allowedRoles = getAllowedRoles(roles);

  const hasRole = (role: EUserRole) => allowedRoles?.some(allowedRole => role === allowedRole) ?? false;

  const hasOneOfRole = (...r: EUserRole[]) => r.some(sr => roles.includes(sr));

  const defaultRoute = getUserDefaultRoute(hasRole, hasFeature);

  return {
    roles,
    defaultRoute,
    [ESecuredPartition.TradeOffers]: {
      view: hasFeature(EAppFeature.Trade),
      favorite: hasFeature(EAppFeature.Trade),
      activation: hasFeature(EAppFeature.Trade),
    },
    [ESecuredPartition.ProductOffers]: {
      view: hasFeature(EAppFeature.Product),
      favorite: hasFeature(EAppFeature.Product),
      activation: hasFeature(EAppFeature.Product),
    },
    [ESecuredPartition.CorpOffers]: {
      view: hasRole(EUserRole.Corp),
      favorite: hasRole(EUserRole.Corp),
      activation: hasRole(EUserRole.Corp),
    },
    [ESecuredPartition.BookingOffers]: {
      view: hasFeature(EAppFeature.Booking),
      favorite: hasFeature(EAppFeature.Booking),
    },
    [ESecuredPartition.CmsTest]: {
      view: hasOneOfRole(EUserRole.AdminMp, EUserRole.AdminPartner),
    },
    isCorpUser: hasRole(EUserRole.Corp),
  };
};
