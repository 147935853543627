import UserSuccessEmailUpdateDialog from 'presentation/features/user/components/successEmailUpdateDialog';
import { useUserProfileEmailEdit } from 'presentation/features/user/details/profile/hooks/useEmailEdit';
import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { MailIcon } from 'presentation/media/icons';
import { useState } from 'react';
import { UserProfileEditAttributeEmail } from '../../../components/attributeEdit';
import UserProfileViewAttribute from '../../../components/attributeView';

const label = 'Электронная почта';

export const UserProfileEmailAdapter = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [successDialogVisible, setSuccessDialogVisible] = useState<boolean>(false);

  const { user } = useCurrentUser();
  const { value, validation, isUpdating, update, reset, onChange } = useUserProfileEmailEdit({
    user,
  });

  const showEdit = () => setEditMode(true);

  const resetAndHideEdit = () => {
    reset();
    hideEdit();
  };

  const hideEdit = () => setEditMode(false);

  const showSuccessDialog = () => setSuccessDialogVisible(true);

  const hideSuccessDialog = () => {
    reset();
    setSuccessDialogVisible(false);
  };

  const updateInternal = () => {
    update().then(result => {
      if (result) {
        hideEdit();
        showSuccessDialog();
      }
    });
  };

  return (
    <>
      <UserProfileViewAttribute
        label={label}
        value={user.email || value}
        icon={<MailIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserProfileEditAttributeEmail
          label={label}
          value={value}
          validation={validation?.email}
          isFetching={isUpdating}
          onChange={onChange}
          onClose={resetAndHideEdit}
          onSave={updateInternal}
        />
      )}
      {successDialogVisible && (
        <UserSuccessEmailUpdateDialog
          email={value}
          open
          isFetching={isUpdating}
          onClose={hideSuccessDialog}
          onRepeatUpdate={updateInternal}
        />
      )}
    </>
  );
};
