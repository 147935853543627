import { CorpOffer } from 'domain/model/corpOffer';
import { Moment } from 'moment-timezone';
import React from 'react';
import { OfferActivationState } from '../../../../hooks/useOfferActivations';
import { OfferActivation } from '../../../../../../../domain/model/activation';

export type CorpOfferSidebarContextType = {
  readonly corpOffer: Nullable<CorpOffer>;
  readonly endDate: Nullable<Moment>;
  readonly earnPointsUrl: string;
  readonly userBalance: Nullable<number>;
  readonly isFetching: boolean;
  readonly activation: OfferActivationState;
  readonly isSubscriptionEnabled: boolean;
  readonly isSubscriptionFetching: boolean;
  readonly isSubscriptionSupported: boolean;
  readonly isActivationReceivedNow: boolean;
  readonly onActivate: () => void;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe: () => void;
  readonly onFillProfile: () => void;
  readonly takeOfferActivation: (activation: OfferActivation) => void;
};

const CorpOfferSidebarContext = React.createContext<CorpOfferSidebarContextType>(
  null as unknown as CorpOfferSidebarContextType
);

export default CorpOfferSidebarContext;
