import { TradeOffer } from 'domain/model/tradeOffer';
import { Moment } from 'moment-timezone';
import React from 'react';
import { ActivateOfferResponse } from '../../../../../../../data/api/user';
import { OfferActivationState } from '../../../../hooks/useOfferActivations';

export type TradeOfferSidebarContextType = {
  readonly tradeOffer: Nullable<TradeOffer>;
  readonly partnerLink: Nullable<string>;
  readonly endDate: Nullable<Moment>;
  readonly activation: OfferActivationState;
  readonly guid: UUID;
  readonly isFetching: boolean;
  readonly isSubscriptionEnabled: boolean;
  readonly isSubscriptionFetching: boolean;
  readonly isSubscriptionSupported: boolean;
  readonly isReceiptDateSupported: boolean;
  readonly isActivationAvailable: boolean;
  readonly isActivationReceivedNow: boolean;
  readonly isTimerActive: boolean;
  readonly stopTimer: () => void;
  readonly onSubscribe: () => void;
  readonly onUnSubscribe: () => void;
  readonly onPartnerButtonClick: () => void;
  readonly onFillProfile: () => void;
  readonly onTakeActivation: (result: ActivateOfferResponse) => void;
  readonly onActivate: () => Promise<Nullable<ActivateOfferResponse>>;
  readonly onCopied: () => void;
  readonly copied: boolean;
  readonly needCopy: boolean;
  readonly fileDownloading: boolean;
  readonly onNeedCopy: (value: boolean) => void;
};

const TradeOfferSidebarContext = React.createContext<TradeOfferSidebarContextType>(
  null as unknown as TradeOfferSidebarContextType
);

export default TradeOfferSidebarContext;
