import { Container } from '@mui/material';
import { offerSectionToTabMap } from 'presentation/features/header/utils';
import { getBookingOffersListRoute } from 'presentation/features/offer/booking/entry';
import { getCorpOffersListRoute } from 'presentation/features/offer/corp/entry';
import { getProductOfferListRoute } from 'presentation/features/offer/product/entry';
import { getTradeOffersListRoute } from 'presentation/features/offer/trade/entry';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Splitter from '../../components/common/splitter';
import useGridSpacing from '../../theme/hooks/useGridSpacing';
import { LinksWrapper } from './controls';
import useLinks from './hooks/useLinks';
import { sectionLinksVisibleSelector } from './store/selectors';

const SectionLinksContainer = () => {
  const { x3 } = useGridSpacing();

  const matchedRoute = useRouteMatch([
    getBookingOffersListRoute({}).pathname,
    getTradeOffersListRoute({}).pathname,
    getCorpOffersListRoute({}).pathname,
    getProductOfferListRoute({}).pathname,
  ]);

  const selected = offerSectionToTabMap.find(s => s.path === matchedRoute?.path);

  const links = useLinks();

  const isVisible = useSelector(sectionLinksVisibleSelector);

  if (!isVisible) {
    return null;
  }

  return (
    links &&
    links.length > 0 && (
      <Container>
        <LinksWrapper
          value={selected?.tab}
          textColor='primary'
          variant='scrollable'
          scrollButtons={false}
        >
          {links}
        </LinksWrapper>
        <Splitter size={x3} />
      </Container>
    )
  );
};

export default SectionLinksContainer;
