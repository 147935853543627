import { ESearchUrlParam } from 'presentation/types';
import { ChangeEventHandler, FC, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import SearchInput, { SearchInputProps } from '../components/input';
import { getSearchResultsRoute } from '../entry';
import { searchSections } from '../utils';

type SearchControlContainerProps = Pick<SearchInputProps, 'placeholder' | 'onSubmit' | 'onClick'> & {
  readonly suggestions?: FC<{ query: Nullable<string>; anchorEl?: Nullable<HTMLElement> }>;
};

const SearchControlContainer = (props: SearchControlContainerProps) => {
  const { suggestions: SuggestionsAdapter } = props;

  const matchedPath = useRouteMatch({
    path: searchSections.map(s => s.sectionPath),
  });

  const history = useHistory();
  const initialQuery = new URLSearchParams(history.location.search).get(ESearchUrlParam.Query);

  const inputRef = useRef<HTMLElement>();
  const [query, setQuery] = useState<Nullable<string>>(initialQuery);

  const onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = event => {
    setQuery(event.target.value ?? '');
  };

  const onSearch = () => {
    if (query) {
      if (matchedPath) {
        const sectionSearchRoute = searchSections.find(s => s.sectionPath === matchedPath.path);
        if (!sectionSearchRoute) {
          return;
        }
        history.push(sectionSearchRoute.getSectionSearchRoute({ query }));
      } else {
        history.push(getSearchResultsRoute({ query }));
      }
    }
  };

  return (
    <>
      <SearchInput
        ref={inputRef}
        {...props}
        value={query}
        showIcon
        onChange={onChange}
        onSubmit={onSearch}
      />
      {SuggestionsAdapter && (
        <SuggestionsAdapter
          query={query}
          anchorEl={inputRef.current}
        />
      )}
    </>
  );
};

export default SearchControlContainer;
