import ForbiddenComponent from 'presentation/components/common/forbidden';
import CmsPreviewContainer from 'presentation/features/cms/preview/container';
import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useCmsContextSearchParams } from './hooks/useCmsSearchParams';
import { useGlobalPreviewMode } from 'presentation/screen/cms/hooks/previewMode/useGlobalPreviewMode';
import ScreenLayout from 'presentation/layouts/screen';

const CmsPreviewScreen = () => {
  const { cmsTest } = useCurrentUser().accessMatrix;

  const context = useCmsContextSearchParams();

  useGlobalPreviewMode();

  if (!cmsTest.view) {
    return <ForbiddenComponent />;
  }

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout>
          <CmsPreviewContainer
            guid={guid}
            context={context}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default CmsPreviewScreen;
