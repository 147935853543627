import RequiredGuidInLocationContainer from 'presentation/features/general/locationGuid/container';
import UserOrdersContainer from 'presentation/features/user/details/orders/container';
import { EUserOrdersTab } from 'presentation/features/user/details/orders/utils';
import { EUserOrdersUrlParam, UserOrdersLocationState } from 'presentation/features/user/entry';
import ScreenLayout from 'presentation/layouts/screen';
import { useLocation } from 'react-router';

const UserOrdersScreen = () => {
  const location = useLocation<Nullable<UserOrdersLocationState>>();
  const searchParams = new URLSearchParams(location.search);

  const tab = (searchParams.get(EUserOrdersUrlParam.Tab) as EUserOrdersTab) ?? EUserOrdersTab.Active;
  const orderId = searchParams.get(EUserOrdersUrlParam.OrderId) ?? null;

  return (
    <RequiredGuidInLocationContainer>
      {guid => (
        <ScreenLayout footer>
          <UserOrdersContainer
            guid={guid}
            tab={tab}
            orderId={orderId}
          />
        </ScreenLayout>
      )}
    </RequiredGuidInLocationContainer>
  );
};

export default UserOrdersScreen;
