import styled from '@emotion/styled/macro';
import { css } from '@mui/material';
import { ButtonLink } from '../link';

export const ButtonLinkStyled = styled(ButtonLink)(
  ({ theme }) => css`
    z-index: 1;

    .MuiButton-startIcon {
      margin-left: -${theme.spacing(0.5)};

      margin-right: 0;

      ${theme.breakpoints.down('sm')} {
        margin-right: ${theme.spacing()};

        background-color: ${theme.palette.secondary.A600};
        border-radius: ${(theme.shape.borderRadius as number) / 4}px;
      }
    }
  `
);
