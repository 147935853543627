import logo from 'presentation/media/images/logo.svg';
import { logoAltText } from '../../../utils/constants';

const Logo = () => {
  return (
    <img
      src={logo}
      alt={logoAltText}
    />
  );
};

export default Logo;
