import { ButtonProps } from '@mui/material';
import { FC } from 'react';
import ContentLoader from '../../loader';
import { StyledFileDownloadButton } from './controls';
import useFileDownload from '../../../../hooks/useFileDownload';

type FileDownloadButtonProps = ButtonProps & {
  readonly src: string;
  readonly accept?: string;
  readonly fileName?: string;
  readonly children: JSX.Element;
  readonly onDownload?: (success: boolean) => void;
  readonly buttonComponent?: FC<ButtonProps>;
};

const FileDownloadButton = ({
  src,
  accept,
  fileName,
  children,
  onDownload,
  buttonComponent: ButtonComponent,
  ...buttonProps
}: FileDownloadButtonProps) => {
  const progressSize = buttonProps.size === 'small' ? 20 : 25;

  const Wrapper: FC<ButtonProps> = ButtonComponent ? ButtonComponent : StyledFileDownloadButton;

  const { start, setStart } = useFileDownload({
    onDownload,
    src,
    fileName,
    accept,
  });

  return (
    <Wrapper
      onClick={() => setStart(true)}
      disabled={start}
      {...buttonProps}
    >
      {children}
      {start && <ContentLoader size={progressSize} />}
    </Wrapper>
  );
};

export default FileDownloadButton;
