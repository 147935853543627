import { Text } from '../../components/controls';

const AboutTradingRulesCommonInfo = () => {
  return (
    <>
      <Text>1. Пользуясь Торговой площадкой, Покупатель соглашается с условиями настоящих Правил.</Text>
      <Text>
        2. Торговая площадка предоставляет Покупателю безвозмездную возможность поиска информации о Товарах и услугах,
        ознакомления с предложениями ОАО «РЖД» и других Продавцов, возможность получения (в т.ч. по результатам поиска)
        товарных предложений и информации, а также иные функциональные возможности.
      </Text>
      <Text>
        3. Покупатель соглашается с тем, что размещенные им на Торговой площадке сообщения и материалы могут быть
        использованы, в том числе в иных сервисах и приложениях ОАО «РЖД» , в рекламных или маркетинговых материалах,
        размещаемых на ресурсах ОАО «РЖД» в сети Интернет, а также на иных ресурсах и сайтах в сети Интернет, для
        привлечения внимания других потребителей к Торговой площадке в целом или к товарам и услугам третьих лиц, как с
        указанием автора сообщения или материала (в качестве имени автора при этом будет указываться имя Покупателя,
        которое он указал при регистрации или в настройках своих данных в Личном кабинете), так и без этого, без
        обязанности предоставлять отчеты об использовании таких сообщений и материалов, без необходимости получения
        специального разрешения Покупателя и без выплаты авторского вознаграждения, на территории всего мира без
        ограничения срока, с правом ОАО «РЖД» предоставлять указанные права использования таких сообщений и материалов
        третьим лицам.
      </Text>
    </>
  );
};
export default AboutTradingRulesCommonInfo;
