import { FC, useEffect } from 'react';
import { useGetUserFavoritesCorpOffersQuery } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { EOfferType } from 'domain/model/enums';
import CardList from 'presentation/components/common/cardList';
import CorpOfferListItem from 'presentation/features/offer/corp/components/listItem';
import useOffersFavorites from '../../../../offer/hooks/useOffersFavorites';
import { TabProps } from './types';

export const UserFavoritesCorpOffersContainer: FC<TabProps> = ({ guid, queryGuid }) => {
  const { data, error: corpOffersError } = useGetUserFavoritesCorpOffersQuery({ guid: queryGuid });
  const corpOffers = data?.data;

  const {
    isFetching: isAddToFavoritesFetching,
    canAdd,
    isFavorite,
    addOrRemove: addOrRemoveFavorite,
  } = useOffersFavorites({
    guid,
    payload: {
      offerType: EOfferType.Corp,
    },
    clearAfterUnmount: false, //чтобы не скрывался таб после убирания всех сердечек
  });

  useEffect(() => {
    if (corpOffersError) {
      ErrorHandler.handleHttpError(corpOffersError);
    }
  }, [corpOffersError]);

  if (!corpOffers) {
    return null;
  }

  return (
    <CardList>
      {corpOffers.map(offer => (
        <CorpOfferListItem
          key={offer.id}
          offer={offer}
          favoriteProps={{
            active: canAdd(EOfferType.Corp, offer) && isFavorite(EOfferType.Corp, offer),
            inProgress: isAddToFavoritesFetching(offer.id),
            onClick: () => addOrRemoveFavorite(EOfferType.Corp, offer),
          }}
        />
      ))}
    </CardList>
  );
};
