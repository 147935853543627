import AppSecured from 'app/secured';
import { AppAuthProvider } from 'appProviders';
import HttpClient from 'data/network/http';
import store from 'data/store/store';
import LegalEntry from 'presentation/features/legal/entry';
import { MPThemeProvider, theme } from 'presentation/theme';
import { Route, Switch } from 'react-router-dom';
import rootRouting from 'routing';
import SystemNotifier from 'system/notifier';

const App = () => {
  HttpClient.getInstance().init(store.dispatch);
  SystemNotifier.getInstance().init(store.dispatch);

  return (
    <Switch>
      <Route
        path={rootRouting.legal}
        render={() => (
          <MPThemeProvider theme={theme}>
            <LegalEntry />
          </MPThemeProvider>
        )}
      />
      <AppAuthProvider>
        <AppSecured />
      </AppAuthProvider>
    </Switch>
  );
};

export default App;
