import { Fade } from '@mui/material';
import { EOrderDiscriminator } from 'domain/model/enums';
import { Wrapper } from './controls';
import { OrderFull } from '../../../../../domain/model/order';
import OrderProductOfferDetailsContainer from '../../productDetails/container';

type OrderDetailsProps = {
  readonly order: OrderFull;
};

const OrderDetails = ({ order }: OrderDetailsProps) => {
  return (
    <Fade in>
      <Wrapper>
        {order.discriminator === EOrderDiscriminator.ProductOrder && (
          <OrderProductOfferDetailsContainer order={order} />
        )}
      </Wrapper>
    </Fade>
  );
};

export default OrderDetails;
