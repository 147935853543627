import styled from '@emotion/styled/macro';
import { css } from '@mui/material';

export const OptionsWrapper = styled.div(
  () => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
  `
);
