import { Notice } from 'domain/model';
import { ENoticeStatus } from 'domain/model/enums';
import SentryClient from 'integration/sentry/client';
import Notifier from 'system/notifier';
import { isSecurityBusinessError } from '../../presentation/utils/auth';
import { businessErrorCode, businessErrorCode2 } from './constants';
import { ServerErrorResponse } from './types';

export default class ErrorHandler {
  static handleHttpErrorLegacy(
    error: Error,
    response: any,
    parser: (response: any) => Notice[] = parseHttpErrorResponse
  ) {
    if (response) {
      parser(response).forEach(notice => {
        Notifier.getInstance().addNotice(notice.status, notice.text);
        ErrorHandler.getLogger().captureException(new Error(notice.text));
      });
    } else {
      console.error(error);
      ErrorHandler.getLogger().captureException(error);
    }
  }

  static handleHttpError(response: any, parser: (response: any) => Notice[] = parseHttpErrorResponse) {
    if ((response as any).__CANCEL__) {
      // Axios Cancel
      return;
    }

    parser(response).forEach(notice => {
      Notifier.getInstance().addNotice(notice.status, notice.text);
      ErrorHandler.getLogger().captureException(new Error(notice.text));
    });
  }

  static handleBusinessError(
    response: ServerErrorResponse,
    parser: (response: ServerErrorResponse) => Notice[] = processServerResponseData
  ) {
    parser(response).forEach(notice => {
      Notifier.getInstance().addNotice(notice.status, notice.text);
      ErrorHandler.getLogger().captureException(new Error(notice.text));
    });
  }

  static getLogger = () => SentryClient.getInstance();
}

const parseHttpErrorResponse = (response: any): Notice[] => {
  try {
    switch (response.status) {
      case 401:
        console.error('Unauthorized', response);
        return [{ status: ENoticeStatus.Error, text: 'Отсутствуют полномочия на запрашиваемый ресурс' }];
      case 403:
        if (isSecurityBusinessError(response.data)) {
          return [];
        } else if (isServerResponseData(response.data)) {
          return [
            {
              status: ENoticeStatus.Error,
              text: `У пользователя не хватает прав доступа к запрашиваемому ресурсу: ${
                response.data?.path ?? response.data?.message
              }`,
            },
          ];
        } else {
          return [];
        }
      case 404:
        return [
          {
            status: ENoticeStatus.Error,
            text: response.data?.message || `Данные не найдены: ${response.data?.path}`,
          },
        ];
      case businessErrorCode:
      case businessErrorCode2:
        return processServerResponseData(response.data);
      case 500:
        return [
          {
            status: ENoticeStatus.Error,
            text:
              'Сервер не смог обработать запрос из-за внутренней ошибки' +
              (response.data?.message ? `: ${response.data?.message}` : ''),
          },
        ];
      case 502:
      case 503:
        return [{ status: ENoticeStatus.Error, text: 'Сервер временно недоступен' }];
      default:
        return [{ status: ENoticeStatus.Error, text: `Неизвестная ошибка: ${response.data?.path}` }];
    }
  } catch (e: any) {
    console.error({ ...e, source: response });
    return [{ status: ENoticeStatus.Error, text: 'Неизвестная ошибка' }];
  }
};

const processServerResponseData = (data: ServerErrorResponse): Notice[] => {
  const { message } = data;
  if (!message) return [{ status: ENoticeStatus.Warning, text: 'Не получен ожидаемый ответ от сервера' }];
  return [{ status: ENoticeStatus.Error, text: message }];
};

const isServerResponseData = (data: ServerErrorResponse): boolean => {
  const { message } = data;
  return !!message;
};
