import ErrorHandler from 'data/network/errorHandler';
import { UserFavoriteProcessData } from 'domain/model';
import { EFavoriteProcessAction, EOfferType } from 'domain/model/enums';
import { useWebAnalytics } from 'presentation/features/webAnalytics';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentUser from '../../../hooks/useCurrentUser';
import {
  favoritesProvideChangeProcess,
  favoritesProvideStartSession,
  favoritesProvideStopSession,
} from './store/slice';
import { FavoriteMutationPayloadType, UseFavoriteCommonProps, UseFavoriteMutationProps } from './types';

type UseFavoritesMutationProps<P extends FavoriteMutationPayloadType> = UseFavoriteCommonProps<P> &
  UseFavoriteMutationProps<P>;

type UseFavoritesMutationResult = {
  readonly add: (objectId: UUID, type?: EOfferType) => void;
  readonly remove: (objectId: UUID, type?: EOfferType) => void;
};

/**
 * хук для работы с избранным общего назначения, нужен только для выполнения мутаций
 */
const useFavoritesMutation = <P extends { offerType: EOfferType }>(
  props: UseFavoritesMutationProps<P>
): UseFavoritesMutationResult => {
  const { guid, payload, clearAfterUnmount = true, addMutation, removeMutation } = props;

  const dispatch = useDispatch();
  const { id: userId } = useCurrentUser().user;
  const { webAnalytics } = useWebAnalytics();

  const [addMutationExecute, addMutationResult] = addMutation;
  const [removeMutationExecute, removeMutationResult] = removeMutation;

  const action = useCallback(
    (objectId: UUID, action: EFavoriteProcessAction, type?: EOfferType) => {
      const offerType = type ?? payload.offerType;

      const processData: UserFavoriteProcessData = {
        action,
        id: objectId,
        offerType,
      };

      const mutation = action === EFavoriteProcessAction.Add ? addMutationExecute : removeMutationExecute;

      if (action === EFavoriteProcessAction.Add) {
        webAnalytics.offerAddToFavorite(objectId);
      }

      dispatch(
        favoritesProvideChangeProcess({
          guid,
          process: {
            ...processData,
            isFetching: true,
            isFetched: false,
            isFailed: false,
            isCancelled: false,
          },
        })
      );

      mutation({ id: objectId, userId, ...payload, offerType })
        .unwrap()
        .then(() => {
          dispatch(
            favoritesProvideChangeProcess({
              guid,
              process: {
                ...processData,
                isFetching: false,
                isFetched: true,
                isFailed: false,
                isCancelled: false,
              },
            })
          );
        })
        .catch(() => {
          dispatch(
            favoritesProvideChangeProcess({
              guid,
              process: {
                ...processData,
                isFetching: false,
                isFetched: false,
                isFailed: true,
                isCancelled: false,
              },
            })
          );
        });
    },
    [payload, userId, addMutationExecute, removeMutationExecute, dispatch, guid, webAnalytics]
  );

  const add = useCallback(
    (objectId: UUID, type?: EOfferType) => action(objectId, EFavoriteProcessAction.Add, type),
    [action]
  );

  const remove = useCallback(
    (objectId: UUID, type?: EOfferType) => action(objectId, EFavoriteProcessAction.Remove, type),
    [action]
  );

  useEffect(() => {
    const { originalArgs: args, isLoading, isSuccess, isError, error } = addMutationResult;

    if (args) {
      dispatch(
        favoritesProvideChangeProcess({
          guid,
          process: {
            action: EFavoriteProcessAction.Add,
            id: args.id,
            isFetching: isLoading,
            isFetched: isSuccess,
            isFailed: isError,
            isCancelled: false,
            offerType: args.offerType,
          },
        })
      );
    }

    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [dispatch, guid, addMutationResult]);

  useEffect(() => {
    const { originalArgs: args, isLoading, isSuccess, isError, error } = removeMutationResult;

    if (args) {
      dispatch(
        favoritesProvideChangeProcess({
          guid,
          process: {
            action: EFavoriteProcessAction.Remove,
            id: args.id,
            isFetching: isLoading,
            isFetched: isSuccess,
            isFailed: isError,
            isCancelled: false,
            offerType: args.offerType,
          },
        })
      );
    }

    if (error) {
      ErrorHandler.handleHttpError(error);
    }
  }, [dispatch, guid, removeMutationResult]);

  useEffect(() => {
    dispatch(favoritesProvideStartSession({ guid }));
    return () => {
      if (clearAfterUnmount) {
        dispatch(favoritesProvideStopSession({ guid }));
      }
    };
  }, [dispatch, guid, clearAfterUnmount]);

  return {
    add,
    remove,
  };
};

export default useFavoritesMutation;
