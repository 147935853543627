import { useAuthentication } from '@mp-npm/mp-auth-client';
import { useResetCurrentUserPasswordMutation } from 'data/api/user';
import ErrorHandler from 'data/network/errorHandler';
import { SecurityToken } from 'domain/model/user';
import UserProfileViewAttribute from 'presentation/features/user/components/attributeView';
import UserResetPasswordConfirmDialog from 'presentation/features/user/components/resetPasswordConfirmDialog';
import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { KeyAltIcon } from 'presentation/media/icons';
import { useEffect, useState } from 'react';

const label = 'Пароль для входа';

export const UserProfilePasswordAdapter = () => {
  const { logOut } = useCurrentUser();
  const { onResetPassword: onResetPasswordMP } = useAuthentication<SecurityToken>();

  const { user } = useCurrentUser();
  const [editMode, setEditMode] = useState<boolean>(false);

  const [, { isLoading: resetCurrentUserPasswordFetching, error: resetCurrentUserPasswordError }] =
    useResetCurrentUserPasswordMutation();

  const showEdit = () => setEditMode(true);

  const hideEdit = () => setEditMode(false);

  const onResetPassword = () => {
    onResetPasswordMP(user?.id ?? '').then(r => {
      if (r !== true) {
        return;
      }

      hideEdit();
      logOut();
    });
  };

  useEffect(() => {
    if (resetCurrentUserPasswordError) {
      ErrorHandler.handleHttpError(resetCurrentUserPasswordError);
    }
  }, [resetCurrentUserPasswordError]);

  return (
    <>
      <UserProfileViewAttribute
        label={label}
        value={'••••••••••••••••'}
        icon={<KeyAltIcon color='secondary' />}
        onTryChange={showEdit}
      />
      {editMode && (
        <UserResetPasswordConfirmDialog
          open
          isFetching={resetCurrentUserPasswordFetching ?? false}
          onConfirm={onResetPassword}
          onClose={hideEdit}
        />
      )}
    </>
  );
};
