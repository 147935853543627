import { Location } from 'history';
import LandingWindowScreen from 'presentation/screen/landing';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import rootRouting from '../../../routing';
import useCurrentUser from '../../hooks/useCurrentUser';

const root = rootRouting.root;

const routing = {
  root,
};

export type LandingLocationState = {
  readonly guid?: UUID;
};

type GetLandingRouteProps = {
  readonly guid?: UUID;
};

export const getLandingRoute = (props: GetLandingRouteProps): Location<LandingLocationState> => {
  const { guid } = props;

  const params = new URLSearchParams();

  const newGuid = guid ?? uuidv4();

  return {
    pathname: routing.root,
    search: params.toString(),
    state: {
      guid: newGuid,
    },
    hash: '',
  };
};

/**
 * точка входа для лэндинга, по сути root
 */
const LandingEntry = () => {
  const { defaultRoute } = useCurrentUser();

  return (
    <Switch>
      <Route
        exact
        path={routing.root}
        component={LandingWindowScreen}
      />

      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default LandingEntry;
