import { ETradeOfferPromotionType } from 'domain/model/enums';
import ContentLoader from 'presentation/components/common/loader';
import OfferSlotSidebar from 'presentation/features/offer/components/offerSlotSidebar/container';
import OfferSidebarLayout from 'presentation/features/offer/components/offerSlotSidebar/layout';
import { TradeOfferSidebarContextType } from 'presentation/features/offer/trade/details/sidebar/provider/context';
import { forwardRef, useMemo } from 'react';
import { ESlotNameArea, SlotsGridArea } from '../../../components/offerSlotSidebar/layout/controls';
import { TradeOfferSidebarActivationAdapter } from './adapters/activation';
import ActivityEndDate from './adapters/activityEndDate';
import SidebarDividerAdapter from './adapters/divider';
import HelpAdapter from './adapters/help';
import { TradeOfferSidebarLeftPeriodAdapter } from './adapters/leftPeriod';
import { TradeOfferSidebarInfoMessagesAdapter } from './adapters/messages/infoMessages';
import { TradeOfferSidebarWarnMessagesAdapter } from './adapters/messages/warnMessages';
import TradeOfferPromotionTypeAdapter from './adapters/obtainingMethod';
import OfferCodeAdapter from './adapters/offerCode';
import { TradeOfferSidebarPartnerLinkAdapter } from './adapters/partnerLink';
import { TradeOfferSidebarPromotionAdapter } from './adapters/promotion';
import { TradeOfferSidebarPromotionNewAdapter } from './adapters/promotionNew';
import TradeOfferTitleAdapter from './adapters/title';
import { BottomBar } from './controls';
import useTradeOfferSidebar from './provider/useTradeOfferSidebar';
import TradeOfferSidebarWrapper from './wrapper';
import TradeOfferSidebarWidgetWrapper from './wrapper/widget';

export const TradeOfferSlotSidebarWrapper = forwardRef(({ children }: { children: any }, ref: any) => {
  const { tradeOffer } = useTradeOfferSidebar();

  const isWidget = tradeOffer?.promotionType === ETradeOfferPromotionType.Widget;

  const Wrapper = useMemo(() => (isWidget ? TradeOfferSidebarWidgetWrapper : TradeOfferSidebarWrapper), [isWidget]);

  return <Wrapper ref={ref}>{children}</Wrapper>;
});

const TradeOfferSlotSidebar = () => {
  return (
    <OfferSlotSidebar<TradeOfferSidebarContextType>
      useOfferSidebar={useTradeOfferSidebar}
      wrapper={TradeOfferSlotSidebarWrapper}
    >
      {({ tradeOffer, isFetching }, wrapperRef) => {
        const isWidget = tradeOffer?.promotionType === ETradeOfferPromotionType.Widget;

        if (!tradeOffer) {
          return null;
        }

        if (isFetching) {
          return <ContentLoader />;
        }

        return (
          <OfferSidebarLayout
            slots={[
              <SlotsGridArea
                area={ESlotNameArea.title}
                key={ESlotNameArea.title}
              >
                <TradeOfferTitleAdapter />
              </SlotsGridArea>,

              <SlotsGridArea
                area={ESlotNameArea.promotionType}
                key={ESlotNameArea.promotionType}
              >
                <TradeOfferPromotionTypeAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.leftPeriod}
                key={ESlotNameArea.leftPeriod}
              >
                <TradeOfferSidebarLeftPeriodAdapter key='leftPeriod' />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.activation}
                key={ESlotNameArea.activation}
              >
                <TradeOfferSidebarActivationAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.promotion}
                key={ESlotNameArea.promotion}
              >
                <TradeOfferSidebarPromotionAdapter wrapperRef={wrapperRef} />
              </SlotsGridArea>,
              !isWidget ? (
                <>
                  <SlotsGridArea
                    area={ESlotNameArea.newPromotion}
                    key={ESlotNameArea.newPromotion}
                  >
                    <TradeOfferSidebarPromotionNewAdapter />
                  </SlotsGridArea>
                  <SlotsGridArea
                    area={ESlotNameArea.bottomBar}
                    key={ESlotNameArea.bottomBar}
                  >
                    <BottomBar>
                      <TradeOfferSidebarPartnerLinkAdapter />
                    </BottomBar>
                  </SlotsGridArea>
                </>
              ) : null,
              <SlotsGridArea
                area={ESlotNameArea.warnMessages}
                key={ESlotNameArea.warnMessages}
              >
                <TradeOfferSidebarWarnMessagesAdapter />
              </SlotsGridArea>,
              <SlotsGridArea
                area={ESlotNameArea.infoMessages}
                key={ESlotNameArea.infoMessages}
              >
                <TradeOfferSidebarInfoMessagesAdapter />
              </SlotsGridArea>,
              // <SlotsGridArea
              //   area={ESlotNameArea.subscriptions}
              //   key={ESlotNameArea.subscriptions}
              // >
              //   <TradeOfferSidebarSubscriptionAdapter />
              // </SlotsGridArea>,
            ]}
            skirtSlots={[
              <HelpAdapter key='help' />,
              <SidebarDividerAdapter key='divider' />,
              <OfferCodeAdapter key='code' />,
              <ActivityEndDate key='end' />,
            ]}
          />
        );
      }}
    </OfferSlotSidebar>
  );
};

export default TradeOfferSlotSidebar;
