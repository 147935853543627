import { FileLink } from 'domain/model';
import { FCC, ReactNode } from 'react';
import AppImageLazy from '../../../../components/common/images/lazy';
import {
  CardActions,
  CardColumn,
  CardContent,
  CardDescription,
  CardDetails,
  CardFavoriteButtonWrapper,
  CardHeader,
  CardRow,
  CoverImageViewWrapper,
} from './controls';

export type OfferListItemProps = {
  readonly images: Nullable<FileLink[]>;
  readonly description?: ReactNode;
  readonly header?: ReactNode;
  readonly imageThumb?: boolean;
  readonly direction?: 'column' | 'row-reverse';
  readonly favorite?: ReactNode;
  readonly info?: ReactNode;
};

const OfferListItem: FCC<OfferListItemProps> = props => {
  const { images, header, description, info, favorite, direction = 'column', children } = props;

  const Card = direction === 'column' ? CardColumn : CardRow;

  return (
    <Card elevation={0}>
      <CoverImageViewWrapper>
        <AppImageLazy src={images?.[0].path ?? ''} />
        {favorite && <CardFavoriteButtonWrapper>{favorite}</CardFavoriteButtonWrapper>}
      </CoverImageViewWrapper>
      <CardDetails>
        <CardContent>
          {header && <CardHeader>{header}</CardHeader>}
          {typeof description === 'string' ? (
            <CardDescription variant='body2'>{description}</CardDescription>
          ) : (
            <>{description}</>
          )}
          {children}
        </CardContent>
        {info && <CardActions>{info}</CardActions>}
      </CardDetails>
    </Card>
  );
};

export default OfferListItem;
export { LogoWrapper } from './controls';
