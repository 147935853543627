import styled from '@emotion/styled/macro';
import { Container as MuiContainer, css, SwipeableDrawer } from '@mui/material';

export const Drawer = styled(SwipeableDrawer)(
  ({ theme }) => css`
    z-index: ${theme.zIndex.appBar + 1};

    .MuiPaper-root {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  `
);

export const Container = styled(MuiContainer)(
  ({ theme }) => css`
    height: 100%;
    max-height: 100%;

    padding-bottom: ${theme.spacing(7)};

    display: flex;
    flex-direction: column;
    overflow-y: auto;
  `
);

export const BackButtonWrapper = styled.div(
  ({ theme }) => css`
    padding-top: ${theme.spacing(2)};
    padding-bottom: ${theme.spacing(3)};

    position: sticky;
    top: 0;
    z-index: 1;

    background-color: ${theme.palette.background.default};
  `
);

export const Wrapper = styled.div(
  ({ theme }) => css`
    min-height: 20rem;

    left: 0;
    right: 0;
    position: relative;

    border-radius: ${(theme.shape.borderRadius as number) * 1.6}px;
    background-color: ${theme.palette.background.paper};

    transition: height ${theme.transitions.duration?.standard}ms;
  `
);

export const OverlayScrollbarsComponentInner = styled.div(() => css``);
