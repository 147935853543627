import UserBalance from 'presentation/features/user/components/balance';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import useCurrentUser from '../../../hooks/useCurrentUser';
import useCurrentUserBalance from '../../../hooks/useCurrentUserBalance';
import HeaderMenu from '../components/menu';
import { HeaderUserShort } from '../components/user';
import { useContextHandlers } from '../hooks/useContextHandlers';

const HeaderUserInfoAdapter = () => {
  const location = useLocation();

  const { user } = useCurrentUser();
  const handlers = useContextHandlers();
  const balance = useCurrentUserBalance();

  const [menuAnchorEl, setMenuAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const onOpenMenu = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event?.currentTarget ?? document.body);
  }, []);

  const onCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const onLocationClick = useCallback(() => {
    onCloseMenu();
    handlers.showChangeLocationDialog();
  }, [handlers, onCloseMenu]);

  useEffect(() => {
    onCloseMenu();
  }, [location, onCloseMenu]);

  return useMemo(
    () => (
      <>
        <HeaderUserShort
          user={user}
          balance={<UserBalance value={balance.value} />}
          isFetching={balance.isFetching}
          onClick={onOpenMenu}
        />
        <HeaderMenu
          anchorEl={menuAnchorEl}
          user={user}
          balance={{
            value: balance.value,
            isFetching: balance.isFetching,
            onClick: balance.refreshBalance,
          }}
          open={!!menuAnchorEl}
          onOpen={onOpenMenu}
          onClose={onCloseMenu}
          onLocationClick={onLocationClick}
        />
      </>
    ),
    [
      user,
      balance.value,
      balance.isFetching,
      balance.refreshBalance,
      menuAnchorEl,
      onOpenMenu,
      onCloseMenu,
      onLocationClick,
    ]
  );
};

export default HeaderUserInfoAdapter;
