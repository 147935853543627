import { getCartRootRoute } from 'presentation/features/cart/entry';
import useCurrentUser from 'presentation/hooks/useCurrentUser';
import { useHistory } from 'react-router';
import { UserMenuItemCart } from '../../components/menuItems';

export const UserMenuCartAdapter = () => {
  const history = useHistory();

  const { accessMatrix } = useCurrentUser();

  const isCartAllowed = accessMatrix?.productOffers.view;

  const onClick = () => {
    history.push(getCartRootRoute());
  };

  return isCartAllowed ? <UserMenuItemCart onClick={onClick} /> : null;
};
