import { ReactNode, useMemo } from 'react';
import { EHeaderOptionType } from '../components/options/types';
import FavoritesOptionAdapter from './options/favorites';
import HomepageOptionAdapter from './options/homepage';
import PrivilegeOptionAdapter from './options/privilege';
import SearchOptionAdapter from './options/search';

export type HeaderOptionItem = {
  readonly type: EHeaderOptionType;
  readonly component: ReactNode;
}

const useHeaderOptions = () => {
  const options: HeaderOptionItem[] = useMemo(
    () => [
      {
        type: EHeaderOptionType.Homepage,
        component: <HomepageOptionAdapter key={EHeaderOptionType.Homepage} />,
      },
      {
        type: EHeaderOptionType.Search,
        component: <SearchOptionAdapter key={EHeaderOptionType.Search} />,
      },
      {
        type: EHeaderOptionType.Privilege,
        component: <PrivilegeOptionAdapter key={EHeaderOptionType.Privilege} />,
      },

      {
        type: EHeaderOptionType.Favorites,
        component: <FavoritesOptionAdapter key={EHeaderOptionType.Favorites} />,
      },
    ],
    []
  );

  return options;
};

export default useHeaderOptions;
