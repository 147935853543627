import styled from '@emotion/styled/macro';
import { badgeClasses, css } from '@mui/material';
import { useMemo } from 'react';
import HeaderOptions from '../components/options';
import { EHeaderOptionType } from '../components/options/types';
import useHeaderOptions from './useHeaderOptions';
import HeaderUserInfoAdapter from './userInfo';

const Wrapper = styled.div(
  ({ theme }) => css`
    display: flex;

    .MuiBox-root {
      margin: 0 -${theme.spacing(0.5)};
    }

    .${badgeClasses.root} {
      margin: 0 ${theme.spacing(0.5)};

      &:last-child {
        margin-right: 0;
      }
    }
  `
);

const HeaderOptionsAdapter = () => {
  const options = useHeaderOptions();

  const desktopHeaderOptions = options.filter(o =>
    [EHeaderOptionType.Privilege, EHeaderOptionType.Favorites].includes(o.type)
  );

  desktopHeaderOptions.push({
    type: EHeaderOptionType.User,
    component: <HeaderUserInfoAdapter />,
  });

  return useMemo(
    () => (
      <Wrapper>
        <HeaderOptions options={desktopHeaderOptions} />
      </Wrapper>
    ),
    [desktopHeaderOptions]
  );
};

export default HeaderOptionsAdapter;
