import { OfferActivation } from '../../../../../../domain/model/activation';
import {
  ECorpOfferPromotionType,
  ETradeOfferPromotionType,
  OfferPromotionType,
} from '../../../../../../domain/model/enums';
import ActivationListItem from '../../../../activation/listItem';
import { getOfferPromotionDownloadUrl } from '../../../../offer/utils';
import { createDownloadRequest } from '../../../../../hooks/useFileDownload';
import { useCopyToClipboard } from '../../../../../hooks/useCopyToClipboard';
import useCopied from '../../../../../hooks/useCopied';
import { useState } from 'react';
import useTakeOfferActivations from '../../../../offer/hooks/useTakeOfferActivations';

export type ActivationListItemAdapterProps = {
  readonly activation: OfferActivation;
  readonly onOfferClick: () => void;
  readonly onOpenHelpDialog: (type: OfferPromotionType) => void;
  readonly getActivationCodeTypeName: (type: OfferPromotionType) => string;
  readonly onPartnerLinkClick: () => void;
};

const ActivationListItemAdapter = (props: ActivationListItemAdapterProps) => {
  const { activation, getActivationCodeTypeName, onOpenHelpDialog, onPartnerLinkClick, onOfferClick } = props;

  const [, copy] = useCopyToClipboard();
  const { copied, needCopy, onNeedCopy, onCopied } = useCopied();

  const [fileDownloading, setFileDownloading] = useState(false);

  const { takeOfferActivation } = useTakeOfferActivations({ lastActivation: activation });

  const onTakeActivation = async (activation: OfferActivation) => {
    switch (activation.promotion.type) {
      case ETradeOfferPromotionType.Promocode:
      case ETradeOfferPromotionType.AccessCode:
        await copy(activation.promotion.code);
        onCopied();
        break;
      case ETradeOfferPromotionType.PublicPromocode:
        await copy(activation.promotion.publicPromotionValue);
        onCopied();
        break;
      case ECorpOfferPromotionType.Certificate:
      case ECorpOfferPromotionType.ExternalCertificate:
      case ETradeOfferPromotionType.Voucher: {
        const src = getOfferPromotionDownloadUrl(activation);
        if (!src) {
          return;
        }
        setFileDownloading(true);
        createDownloadRequest({ src }).finally(() => setFileDownloading(false));
        break;
      }
      case ETradeOfferPromotionType.Widget:
      case ETradeOfferPromotionType.ReferralLink:
        break;
    }

    takeOfferActivation(activation);
  };

  return (
    <ActivationListItem
      activation={activation}
      onTakeActivation={() => onTakeActivation(activation)}
      getActivationCodeTypeName={getActivationCodeTypeName}
      onOfferClick={onOfferClick}
      onPartnerLinkClick={onPartnerLinkClick}
      onOpenHelpDialog={onOpenHelpDialog}
      onNeedCopy={onNeedCopy}
      needCopy={needCopy}
      copied={copied}
      fileDownloading={fileDownloading}
    />
  );
};

export default ActivationListItemAdapter;
