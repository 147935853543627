import { useState } from 'react';

const useCopied = () => {
  const [copied, setCopied] = useState<boolean>(false);
  const [needCopy, setNeedCopy] = useState<boolean>(false);

  const onNeedCopy = (value: boolean) => setNeedCopy(value);

  const onCopied = () => {
    setCopied(true);
    setNeedCopy(false);
  };

  return {
    copied,
    needCopy,
    onNeedCopy,
    onCopied,
  };
};

export default useCopied;
