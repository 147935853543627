import { List, ListItem, Text } from '../../components/controls';

const AboutTradingRulesPaymentAndPriceInfo = () => {
  return (
    <>
      <Text>
        1. Право собственности на Заказанные Товары переходит к Покупателю с момента фактической передачи Товара
        Покупателю и оплаты последним полной стоимости Товара, а также услуги доставки Товара. Риск его случайной гибели
        или повреждения Товара переходит к Покупателю с момента фактической передачи Товара Покупателю.
      </Text>
      <Text>2. Цены на Товар определяются Продавцами,</Text>
      <Text>
        3. Цена товара указывается в рублях Российской Федерации и включает в себя налог на добавленную стоимость.
      </Text>
      <Text>
        4. Окончательная Цена Товара определяется последовательным действием на Цену Товара скидок по следующему
        порядку:
      </Text>
      <List>
        <ListItem>Акционная скидка</ListItem>
        <ListItem>Скидка по промокоду</ListItem>
      </List>
    </>
  );
};

export default AboutTradingRulesPaymentAndPriceInfo;
