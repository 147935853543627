import { useAddUserOfferToFavoritesMutation, useRemoveUserOfferFromFavoritesMutation } from 'data/api/user';
import { BookingOffer, BookingOfferShort } from 'domain/model/bookingOffer';
import { CorpOfferShort } from 'domain/model/corpOffer';
import { EFavoriteProcessAction, EOfferType } from 'domain/model/enums';
import { ProductOffer, ProductOfferShort } from 'domain/model/productOffer';
import { TradeOfferShort } from 'domain/model/tradeOffer';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { favoriteProvideProcessesSelector } from '../../favorite/provide/store/selectors';
import { UseFavoriteCommonProps } from '../../favorite/provide/types';
import useFavoritesMutation from '../../favorite/provide/useFavoritesMutation';
import { getUserFavoritesSelector } from '../../user/details/favorites/store/selectors';

type PayloadType = {
  readonly offerType: EOfferType;
  readonly partnerId?: UUID;
};

type UseOffersFavoritesProps = UseFavoriteCommonProps<PayloadType>;

type OfferTypes =
  | TradeOfferShort
  | CorpOfferShort
  | ProductOffer
  | ProductOfferShort
  | BookingOffer
  | BookingOfferShort;
type FavoriteFn<R = boolean> = (type: EOfferType, offer: OfferTypes) => R;

type UseOffersFavoritesResult = {
  readonly isRemoved: FavoriteFn;
  readonly isRemoving: (id: UUID) => boolean;
  readonly isAdding: (id: UUID) => boolean;
  readonly isFetching: (id: UUID) => boolean;
  readonly isRemovedOrAdding: FavoriteFn;
  readonly isFavorite: FavoriteFn;
  readonly canAdd: FavoriteFn;
  readonly add: (id: UUID) => void;
  readonly remove: (id: UUID) => void;
  readonly addOrRemove: FavoriteFn<void>;
};

/**
 * хук для работы с избранным коллекции офферов
 */
const useOffersFavorites = (props: UseOffersFavoritesProps): UseOffersFavoritesResult => {
  const { guid, payload, clearAfterUnmount = true } = props;

  const processes = useSelector(favoriteProvideProcessesSelector(guid));
  const favorites = useSelector(getUserFavoritesSelector());

  const addMutation = useAddUserOfferToFavoritesMutation();
  const removeMutation = useRemoveUserOfferFromFavoritesMutation();

  const isOfferFavorite = useCallback(
    (type: EOfferType, offer: OfferTypes) => {
      const process = processes?.find(item => item.id === offer.id);

      if (process?.isFetched) {
        return process.action === EFavoriteProcessAction.Add;
      } else {
        return favorites?.[type] ? favorites[type].indexOf(offer.id) > -1 : false;
      }
    },
    [favorites, processes]
  );

  const { add: addInternal, remove: removeInternal } = useFavoritesMutation({
    guid,
    payload,
    clearAfterUnmount,
    addMutation,
    removeMutation,
  });

  const add = useCallback((id: UUID, type?: EOfferType) => addInternal(id, type), [addInternal]);

  const remove = useCallback((id: UUID, type?: EOfferType) => removeInternal(id, type), [removeInternal]);

  const getLastProcess = useCallback(
    (id: UUID, action: EFavoriteProcessAction) => {
      return (
        processes
          ?.slice()
          ?.reverse()
          ?.find(process => process.action === action && process.id === id) ?? null
      );
    },
    [processes]
  );

  const isRemoved = useCallback(
    (type: EOfferType, offer: OfferTypes) => {
      return (
        !!getLastProcess(offer.id, EFavoriteProcessAction.Remove)?.isFetched ||
        !!(favorites?.[type] && favorites?.[type].indexOf(offer.id) < 0)
      );
    },
    [favorites, getLastProcess]
  );

  const isRemoving = useCallback(
    (id: UUID) => {
      return getLastProcess(id, EFavoriteProcessAction.Remove)?.isFetching ?? false;
    },
    [getLastProcess]
  );

  const isAdding = useCallback(
    (id: UUID) => {
      return getLastProcess(id, EFavoriteProcessAction.Add)?.isFetching ?? false;
    },
    [getLastProcess]
  );

  const addOrRemove = useCallback(
    (type: EOfferType, offer: OfferTypes) => {
      if (isOfferFavorite(type, offer) && !isRemoved(type, offer)) {
        remove(offer.id, type);
      } else {
        add(offer.id, type);
      }
    },
    [add, remove, isOfferFavorite, isRemoved]
  );

  const isOfferRemoved = useCallback(
    (type: EOfferType, offer: OfferTypes) => {
      return isRemoved(type, offer) || isAdding(offer.id);
    },
    [isAdding, isRemoved]
  );

  const canAdd = useCallback(
    (type: EOfferType, offer: OfferTypes) => {
      return !isRemoved(type, offer) && !isRemoving(offer.id) && !isAdding(offer.id);
    },
    [isAdding, isRemoved, isRemoving]
  );

  const isFetching = useCallback(
    (id: UUID) => {
      return isRemoving(id) || isAdding(id);
    },
    [isRemoving, isAdding]
  );

  return {
    isRemoving,
    isRemoved,
    isAdding,
    isFetching,
    isRemovedOrAdding: isOfferRemoved,
    isFavorite: isOfferFavorite,
    canAdd,
    add,
    remove,
    addOrRemove,
  };
};

export default useOffersFavorites;
