import styled from '@emotion/styled';
import { EParentGridAreas } from '../../../../../layouts/detail/types';

export const FitContainer = styled.div`
  display: flex;
  flex: 1;

  [area=${EParentGridAreas.Inner}] {
    margin: ${p => p.theme.spacing(1, -1.5, 0 - 1.5)};

    ${p => p.theme.breakpoints.up('sm')} {
      margin: ${p => p.theme.spacing(1, -2, 0 - 2)};
    }

    ${p => p.theme.breakpoints.up('md')} {
      margin: 0;
    }
  }
`;
