import ContentLoader from 'presentation/components/common/loader';
import { LoginLayoutType } from 'presentation/features/auth/login/layout/types';
import { SyncLoginContainer } from '@mp-npm/mp-auth-client';
import { AuthAction, EAuthAction, getCheckSsoPath } from 'presentation/utils/auth';
import LoginLayout from './layout';
import { SecurityToken } from 'domain/model/user';

const Layout: LoginLayoutType = LoginLayout;

const LoginContainer = () => {
  return (
    <Layout>
      <SyncLoginContainer<AuthAction, SecurityToken>
        actionsTriggeringAuthentication={[EAuthAction.LoginSuccess]}
        ssoPath={getCheckSsoPath()}
      >
        <ContentLoader />
      </SyncLoginContainer>
    </Layout>
  );
};
export default LoginContainer;
