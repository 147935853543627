import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, StyledEngineProvider, Theme, ThemeProvider } from '@mui/material';
import useFavicon from 'presentation/hooks/useFavicon';
import { FCC } from 'react';

type MPThemeProviderProps = {
  readonly theme: Theme;
};

const cache = createCache({
  key: 'mui',
  prepend: true,
});

export const MPThemeProvider: FCC<MPThemeProviderProps> = ({ theme, children }) => {
  useFavicon(theme.logo);

  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </CacheProvider>
  );
};
