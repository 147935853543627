import { buildAuthIDPBindProfileUrl, observablePattern, observables, useAuthentication } from '@mp-npm/mp-auth-client';
import { SecurityToken } from 'domain/model/user';
import { authCheckIdpLinkPage } from 'presentation/utils/auth';
import { useCallback, useEffect, useMemo } from 'react';
import { AppConfigurator } from 'system/appConfigurator';
import Notifier from 'system/notifier';
import CurrentUserProvider from '../../user/current/settings/provider';
import { getAllowedRoles } from '../../user/utils';

type MainSecurityProps = {
  readonly children: JSX.Element;
};

const MainSecurity = ({ children }: MainSecurityProps) => {
  const { logOut, refreshToken, authService } = useAuthentication<SecurityToken>();
  // TODO (@Protopopov RUaln):mp-auth: вернуть useAuthentication.token после фиксла mp-auth
  const token = authService.tokenParsed;

  useEffect(() => {
    const observer = new observablePattern.Observer(() => {
      setTimeout(logOut, 5000);
      Notifier.getInstance().addError('Необходима авторизация. Сеанс будет завершен.');
    });
    observables.onAuthRefreshErrorObservableSubject.subscribe(observer);

    return () => observables.onAuthRefreshErrorObservableSubject.unsubscribe(observer);
  }, [logOut]);

  useEffect(() => {
    const observer = new observablePattern.Observer(() => {
      Notifier.getInstance().addError(`Не удалось обновить токен безопасности`);
    });
    observables.onCustomUpdateTokenErrorObservableSubject.subscribe(observer);

    return () => observables.onCustomUpdateTokenErrorObservableSubject.unsubscribe(observer);
  }, [logOut]);

  const bindRzhdProfile = useCallback(() => {
    if (!token) {
      return;
    }

    const { realm, idpRzhdPortal, clientId, url: authServiceUrl } = AppConfigurator.getInstance().getApiAuthService();
    const { nonce, session_state: sessionState } = token;

    const url = buildAuthIDPBindProfileUrl({
      authUrl: authServiceUrl,
      nonce: nonce ?? '',
      sessionState: sessionState ?? '',
      idpId: idpRzhdPortal,
      clientId,
      realm,
      authCheckIdpLinkPage,
    });
    console.log('Запрос на привязку профиля стороннего IDP', url);
    const newWindow = window.open(
      url,
      'bindRzhdProfile',
      'width=500,height=600,menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes'
    );

    if (!newWindow) {
      console.warn(
        'В браузере установлен запрет на открытие всплывающих окон, будет выполнена попытка синхронной авторизации'
      );
      window.location.href = url;
    }
  }, [token]);

  const resetPassword = () => {
    // window.location.href = `https://mp-dev.myservices.digital/auth/realms/mp/login-actions/reset-credentials`;
  };

  const roles = useMemo(() => getAllowedRoles(token?.realm_access?.roles ?? []), [token]);
  console.debug('keycloak user', token);
  if (!token) {
    return null;
  }

  return (
    <CurrentUserProvider
      roles={roles}
      logOut={logOut}
      token={token}
      refreshToken={refreshToken}
      resetPassword={resetPassword}
      bindRzhdProfile={bindRzhdProfile}
    >
      {children}
    </CurrentUserProvider>
  );
};

export default MainSecurity;
