import { Typography } from '@mui/material';
import { toRubCurrency } from '../../../../utils/currency';
import { Cost } from './controls';

type OrderCommonOfferCostProps = {
  readonly originalCost: Nullable<number>;
  readonly cost: Nullable<number>;
};

const OrderCommonOfferCost = ({ originalCost, cost }: OrderCommonOfferCostProps) => {
  return (
    <>
      <Typography component='span'>{toRubCurrency(cost || originalCost)}</Typography>
      {cost && originalCost && (
        <Cost
          color='textSecondary'
          component='span'
        >
          {toRubCurrency(originalCost)}
        </Cost>
      )}
    </>
  );
};

export default OrderCommonOfferCost;
