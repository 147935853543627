import styled from '@emotion/styled/macro';
import { PropsWithChildren } from 'react';

export type WrapperProps = {
  readonly columnsCount?: number;
  readonly mdColumnsCount?: number;
  readonly smColumnsCount?: number;
  readonly xsColumnsCount?: number;
};

export const Wrapper = styled(
  ({ columnsCount, mdColumnsCount, smColumnsCount, xsColumnsCount, ...others }: PropsWithChildren<WrapperProps>) => (
    <div {...others} />
  )
)`
  display: grid;
  grid-template-columns: repeat(${p => p.columnsCount}, 1fr);

  ${p => p.theme.breakpoints.down('md')} {
    grid-template-columns: repeat(${p => p.mdColumnsCount}, 1fr);
  }

  ${p => p.theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(${p => p.smColumnsCount}, 1fr);
  }

  ${p => p.theme.breakpoints.down('xs')} {
    grid-template-columns: repeat(${p => p.xsColumnsCount}, 1fr);
  }
`;
